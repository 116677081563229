import React, { Component } from 'react';
import _ from 'lodash';
import $ from 'jquery';

class TankReconciliationReportTable extends Component {
    constructor() {
        super();
        this.state = {
            tableobjectdata: undefined,
            sortKey: "",
            temp: ""
        }
    }
    componentDidMount() {
        this.setState({
            tableobjectdata: this.props.tableobjectdata
        })
        if (this.props.tableobjectdata) {
            setTimeout(() => {
                this.arraySort("nothing to send", this.props.tabletype.headers[0].ColumnName);
                $("#firstCol").removeAttr("class");
                $("#firstCol").attr("class", "caretclass fa fa-caret-down");
            }, 1000)
        }
    }
    arraySort(e, key) {
        //$(".caretclass").removeAttr("class");
        $(".caretclass").attr("class", "caretclass fa fa-caret-right");
        if (key !== "total") {
            if (this.state.sortKey !== key) {
                this.setState({
                    sortKey: key
                })
                if (this.state.tableobjectdata &&
                    this.state.tableobjectdata.beginInventories &&
                    this.state.tableobjectdata.beginInventories.length > 0
                ) {
                    $(e.target).removeAttr("class");
                    $(e.target).attr("class", "caretclass fa fa-caret-down");
                    this.state.tableobjectdata.beginInventories = _.sortBy(this.state.tableobjectdata.beginInventories, function (x) {
                        if (key === "sysID") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        } else if (key === "date") {
                            if (!isNaN(x['inventoryDate']))
                                return parseInt(x['inventoryDate']);

                            return x['inventoryDate'];
                        } else if (key === "volume") {
                            if (!isNaN(x['inventoryVolume']))
                                return parseInt(x['inventoryVolume']);

                            return x['inventoryVolume'];
                        }
                    });
                }
                if (this.state.tableobjectdata &&
                    this.state.tableobjectdata.deliveryRecords &&
                    this.state.tableobjectdata.deliveryRecords.length > 0
                ) {
                    $(e.target).removeAttr("class");
                    $(e.target).attr("class", "caretclass fa fa-caret-down");
                    this.state.tableobjectdata.deliveryRecords = _.sortBy(this.state.tableobjectdata.deliveryRecords, function (x) {
                        if (key === "sysID") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        } else if (key === "date") {
                            if (!isNaN(x['deliveryDate']))
                                return parseInt(x['deliveryDate']);

                            return x['deliveryDate'];
                        } else if (key === "volume") {
                            if (!isNaN(x['deliveryVolume']))
                                return parseInt(x['deliveryVolume']);

                            return x['deliveryVolume'];
                        }
                    });
                }
                if (this.state.tableobjectdata &&
                    this.state.tableobjectdata.dispenseRecords &&
                    this.state.tableobjectdata.dispenseRecords.length > 0 &&
                    key !== "date"
                ) {
                    $(e.target).removeAttr("class");
                    $(e.target).attr("class", "caretclass fa fa-caret-down");
                    this.state.tableobjectdata.dispenseRecords = _.sortBy(this.state.tableobjectdata.dispenseRecords, function (x) {
                        if (key === "sysID") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        } else if (key === "date") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        } else if (key === "volume") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        }
                    });
                    this.setState({
                        temp: "a"
                    })
                }
                if (this.state.tableobjectdata &&
                    this.state.tableobjectdata.endInventories &&
                    this.state.tableobjectdata.endInventories.length > 0
                ) {
                    $(e.target).removeAttr("class");
                    $(e.target).attr("class", "caretclass fa fa-caret-down");
                    this.state.tableobjectdata.endInventories = _.sortBy(this.state.tableobjectdata.endInventories, function (x) {
                        if (key === "sysID") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        } else if (key === "date") {
                            if (!isNaN(x['inventoryDate']))
                                return parseInt(x['inventoryDate']);

                            return x['inventoryDate'];
                        } else if (key === "volume") {
                            if (!isNaN(x['inventoryVolume']))
                                return parseInt(x['inventoryVolume']);

                            return x['inventoryVolume'];
                        }
                    });
                }
            } else {
                this.setState({
                    sortKey: ""
                });
                if (this.state.tableobjectdata &&
                    this.state.tableobjectdata.beginInventories &&
                    this.state.tableobjectdata.beginInventories.length > 0
                ) {
                    $(e.target).removeAttr("class");
                    $(e.target).attr("class", "caretclass fa fa-caret-up");
                    this.state.tableobjectdata.beginInventories = _.sortBy(this.state.tableobjectdata.beginInventories, function (x) {
                        if (key === "sysID") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        } else if (key === "date") {
                            if (!isNaN(x['inventoryDate']))
                                return parseInt(x['inventoryDate']);

                            return x['inventoryDate'];
                        } else if (key === "volume") {
                            if (!isNaN(x['inventoryVolume']))
                                return parseInt(x['inventoryVolume']);

                            return x['inventoryVolume'];
                        }
                    }).reverse();
                }
                if (this.state.tableobjectdata &&
                    this.state.tableobjectdata.deliveryRecords &&
                    this.state.tableobjectdata.deliveryRecords.length > 0
                ) {
                    $(e.target).removeAttr("class");
                    $(e.target).attr("class", "caretclass fa fa-caret-up");
                    this.state.tableobjectdata.deliveryRecords = _.sortBy(this.state.tableobjectdata.deliveryRecords, function (x) {
                        if (key === "sysID") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        } else if (key === "date") {
                            if (!isNaN(x['deliveryDate']))
                                return parseInt(x['deliveryDate']);

                            return x['deliveryDate'];
                        } else if (key === "volume") {
                            if (!isNaN(x['deliveryVolume']))
                                return parseInt(x['deliveryVolume']);

                            return x['deliveryVolume'];
                        }
                    }).reverse();
                }
                if (this.state.tableobjectdata &&
                    this.state.tableobjectdata.dispenseRecords &&
                    this.state.tableobjectdata.dispenseRecords.length > 0 &&
                    key !== "date"
                ) {
                    $(e.target).removeAttr("class");
                    $(e.target).attr("class", "caretclass fa fa-caret-up");
                    this.state.tableobjectdata.dispenseRecords = _.sortBy(this.state.tableobjectdata.dispenseRecords, function (x) {
                        if (key === "sysID") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        } else if (key === "date") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        } else if (key === "volume") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        }
                    }).reverse();
                }
                if (this.state.tableobjectdata &&
                    this.state.tableobjectdata.endInventories &&
                    this.state.tableobjectdata.endInventories.length > 0
                ) {
                    $(e.target).removeAttr("class");
                    $(e.target).attr("class", "caretclass fa fa-caret-up");
                    this.state.tableobjectdata.endInventories = _.sortBy(this.state.tableobjectdata.endInventories, function (x) {
                        if (key === "sysID") {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        } else if (key === "date") {
                            if (!isNaN(x['inventoryDate']))
                                return parseInt(x['inventoryDate']);

                            return x['inventoryDate'];
                        } else if (key === "volume") {
                            if (!isNaN(x['inventoryVolume']))
                                return parseInt(x['inventoryVolume']);

                            return x['inventoryVolume'];
                        }
                    }).reverse();
                }
            }
        }
    }
    render() {
        //const statusText = "No Data";
        const statusText = this.props.statusText;
        const noDataCss = {
            'paddingTop': '20%',
            'textAlign': 'center'
        }
        const displayHeaders = this.props.tabletype.headers.map((header, i) =>
            i === 0 ?
                <th scope="col" className={header.TextAlign}>
                    {header.DisplayText}
                    <span>&nbsp;<i id="firstCol" onClick={(e) => this.arraySort(e, header.ColumnName)} className="caretclass fa fa-caret-right"></i></span>
                </th>
                :
                header.DisplayText === "" ? <th scope="col"></th> :
                    header.DisplayText === "Total" ?
                        <th scope="col" className={header.TextAlign}>
                            {header.DisplayText}
                            <span></span>
                        </th> :
                        <th scope="col" className={header.TextAlign}>
                            {header.DisplayText}
                            <span>&nbsp;<i onClick={(e) => this.arraySort(e, header.ColumnName)} className="caretclass fa fa-caret-right"></i></span>
                        </th>
        );

        const beginInventories = this.state.tableobjectdata === undefined || this.state.tableobjectdata.beginInventories === undefined ? <tr></tr> : <div>{
            this.state.tableobjectdata.beginInventories.length > 0 &&
            this.state.tableobjectdata.beginInventories.map((inventory, i) =>
                <tr className={(i % 2 === 0) ? "table-row-bg" : ""}>
                    <td className="textAlignLeft">Inventory {inventory.sysID}</td>
                    <td></td>
                    <td className="textAlignLeft">{inventory.inventoryDate.split("T")[0].split("-")[1]}/{inventory.inventoryDate.split("T")[0].split("-")[2]}</td>
                    <td className="textAlignLeft">{inventory.inventoryDate.split("T")[1].split(":", 2)[0]}:{inventory.inventoryDate.split("T")[1].split(":", 2)[1]}</td>
                    <td className="textAlignRight">{inventory.inventoryVolume}</td>
                    <td></td>
                </tr>
            )}
            < tr className="table-footer-row table-body-content" >
                <td></td>
                <td></td>
                <td className="textAlignLeft">Starting Inventory</td>
                <td></td>
                <td></td>
                <td className="textAlignRight">{this.state.tableobjectdata.totalBeginInventory}</td>
            </tr >
        </div>;

        const deliveryRecords = this.state.tableobjectdata === undefined || this.state.tableobjectdata.deliveryRecords === undefined ? <tr></tr> : <div>{
            this.state.tableobjectdata.deliveryRecords.length > 0 &&
            this.state.tableobjectdata.deliveryRecords.map((delRecord, i) =>
                <tr className={(i % 2 === 0) ? "table-row-bg" : ""}>
                    <td className="textAlignLeft">Del {delRecord.sysID}</td>
                    <td></td>
                    <td className="textAlignLeft">{delRecord.deliveryDate.split("T")[0].split("-")[1]}/{delRecord.deliveryDate.split("T")[0].split("-")[2]}</td>
                    <td className="textAlignLeft">{delRecord.deliveryDate.split("T")[1].split(":", 2)[0]}:{delRecord.deliveryDate.split("T")[1].split(":", 2)[1]}</td>
                    <td className="textAlignRight">{delRecord.deliveryVolume}</td>
                    <td></td>
                </tr>
            )}
            < tr className="table-footer-row table-body-content" >
                <td></td>
                <td></td>
                <td className="textAlignLeft">+Deliveries</td>
                <td></td>
                <td></td>
                <td className="textAlignRight">{this.state.tableobjectdata.totalFuelDelivered}</td>
            </tr >
        </div>;

        const dispenseRecords = this.state.tableobjectdata === undefined || this.state.tableobjectdata.dispenseRecords === undefined ? <tr></tr> : <div>{
            this.state.tableobjectdata.dispenseRecords.length > 0 &&
            this.state.tableobjectdata.dispenseRecords.map((dispenseRecord, i) =>
                <tr className={(i % 2 === 0) ? "table-row-bg" : ""}>
                    <td className="textAlignLeft">Tank {dispenseRecord.sysID} Fuel Dispensed</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{dispenseRecord.volume}</td>
                    <td></td>
                </tr>
            )}
            < tr className="table-footer-row table-body-content" >
                <td></td>
                <td></td>
                <td className="textAlignLeft">-Dispensed</td>
                <td></td>
                <td></td>
                <td className="textAlignRight">{this.state.tableobjectdata.totalFuelDispensed}</td>
            </tr >
        </div>;

        const endInventories = this.state.tableobjectdata === undefined || this.state.tableobjectdata.endInventories === undefined ? <tr></tr> : <div>{
            this.state.tableobjectdata.endInventories.length > 0 &&
            this.state.tableobjectdata.endInventories.map((inventory, i) =>
                <tr className={(i % 2 === 0) ? "table-row-bg" : ""}>
                    <td className="textAlignLeft">Inventory {inventory.sysID}</td>
                    <td></td>
                    <td className="textAlignLeft">{inventory.inventoryDate.split("T")[0].split("-")[1]}/{inventory.inventoryDate.split("T")[0].split("-")[2]}</td>
                    <td className="textAlignLeft">{inventory.inventoryDate.split("T")[1].split(":", 2)[0]}:{inventory.inventoryDate.split("T")[1].split(":", 2)[1]}</td>
                    <td className="textAlignRight">{inventory.inventoryVolume}</td>
                    <td></td>
                </tr>
            )}
            <tr className="table-footer-row table-body-content" >
                <td></td>
                <td></td>
                <td className="textAlignLeft">-Ending Inventory</td>
                <td></td>
                <td></td>
                <td className="textAlignRight">{this.state.tableobjectdata.totalEndInventory}</td>
            </tr >
        </div>;

        const discrepancy = this.state.tableobjectdata === undefined || this.state.tableobjectdata.discrepancy === undefined ? <tr></tr> :
            < tr className="table-footer-row table-body-content" >
                <td></td>
                <td></td>
                <td className="textAlignLeft">Discrepancy</td>
                <td></td>
                <td></td>
                <td className="textAlignRight">{this.state.tableobjectdata.discrepancy}</td>
            </tr >;

        return (

            <table id="printTankReconcilliationTable" className="table">
                <thead className="body-content-table">
                    <tr className="table-row-top-border">
                        {displayHeaders}
                    </tr>
                </thead>
                <tbody className="table-body-content-inner">
                    {this.state.tableobjectdata !== undefined ? <div>
                        {beginInventories}
                        {deliveryRecords}
                        {dispenseRecords}
                        {endInventories}
                        {discrepancy}
                    </div> : <div style={noDataCss}><h4>{statusText}</h4></div>}
                </tbody>
            </table>
        )
    }
}
export default TankReconciliationReportTable;