import React, { Component } from 'react';
class Canvas extends Component {
    constructor(props) {
        super(props);
        this.canvasref = React.createRef();
    }
    componentDidMount() {
        this.updateCanvas();
    }

    updateCanvas() {
        let dctx = this.canvasref.current.getContext('2d');
        let scanvas = this.props.srccanvas;
        let sctx = scanvas.getContext('2d');
        let imgdata = sctx.getImageData(0, 0, scanvas.width, scanvas.height);
        dctx.putImageData(imgdata, 0, 0);
    }

    render() {
        return (<React.Fragment>
            <div className="row m-0">
                <canvas className="col-md-10" ref={this.canvasref} width={350} height={140}></canvas>
            </div>
            <div className="row m-0">
                <div className="col-md-4">SIGNATURE</div>
            </div>
        </React.Fragment>);
    }
}
export default Canvas;