import React, { Component } from 'react';
import Modal from "react-responsive-modal";
import Canvas from "./Canvas";
import Address from './AddressComponent';
import { addresses } from '../urlandheaderdata';
import _ from 'lodash';
import ReactToPrint from 'react-to-print';
import $ from 'jquery';
import moment from 'moment';

class Table extends Component {
    constructor(props) {
        super(props);
        this.showDialog = this.showDialog.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.arraySort = this.arraySort.bind(this);

        this.state = {
            valueheaders: [],
            modalDisplyData: { items: [], cardDetails: [] },
            open: false,
            sign: undefined,
            tabledata: undefined,
            sortKey: ""
        }
    }

    //method to show modal
    showDialog(data) {
        console.log(data.cardDetails[0].signature);
        this.setState({ modalDisplyData: data, open: true });
        if (data.cardDetails && data.cardDetails.length > 0 && data.cardDetails[0].signature) {
            let canvas = document.base64ToArrayBuffer(data.cardDetails[0].signature);
            this.setState({ sign: canvas });
        }
        else {
            this.setState({
                sign: undefined
            })
        }
    }

    //methos to close modal
    onCloseModal() {
        this.setState({ open: false });
    }

    //method to convert dot notation to []notation
    replace(str, obj) {
        return str.replace(/([^}]+)/g, function (wholematch, firstmatch) {
            var value = firstmatch.split('.').reduce(function (a, b) {
                return a[b];
            }, obj);
            return typeof value !== 'undefined' ? value : wholematch;
        });
    }
    arraySort(e, key) {
        //$(".caretclass").removeAttr("class");
        $(".caretclass").attr("class", "caretclass fa fa-caret-right");
        if (this.state.sortKey !== key) {
            this.setState({
                sortKey: key
            })
            if (this.state.tabledata != null) {
                $(e.target).removeAttr("class");
                $(e.target).attr("class", "caretclass fa fa-caret-down");
                let sortedArry = _.sortBy(this.state.tabledata, function (x) {
                    if (!isNaN(x[key]))
                        return parseInt(x[key]);

                    return x[key];
                });
                this.setState({
                    tabledata: sortedArry
                });
            }
        } else {
            this.setState({
                sortKey: ""
            })
            if (this.state.tabledata != null) {
                $(e.target).removeAttr("class");
                $(e.target).attr("class", "caretclass fa fa-caret-up");
                let sortedArry = _.sortBy(this.state.tabledata, function (x) {
                    if (!isNaN(x[key]))
                        return parseInt(x[key]);

                    return x[key];
                }).reverse();
                this.setState({
                    tabledata: sortedArry
                });
            }
        }
    }
    componentDidMount() {
        this.setState({
            tabledata: this.props.tabledata
        });
        if (this.props.tableobjectdata !== undefined
            && this.props.tabledata !== undefined && this.props.tabledata.length > 0
            && this.props.tableheaders !== undefined && this.props.tablefooters !== undefined && this.props.tableheaders.length > 0) {
            setTimeout(() => {
                this.arraySort("nothing to send", this.props.tableheaders[0].ColumnName);
                $("#firstCol").removeAttr("class");
                $("#firstCol").attr("class", "caretclass fa fa-caret-down");
            }, 1000)
        }
    }
    render() {
        const deptWithout9999Row = this.props.tableheaders && this.props.tableheaders.length > 0 && this.props.tableheaders[0].DisplayText === "Department" &&
            this.props.tabledata && _.find(this.props.tabledata, ["sysId", "9998"]);
        const statusText = this.props.statusText;
        //headers showing in table.
        const displayheaders = this.props.tableheaders.map((header, i) =>
            i === 0 ?
                <th scope="col" className={header.TextAlign}>
                    {header.DisplayText}
                    <span>&nbsp;<i id="firstCol" onClick={(e) => this.arraySort(e, header.ColumnName)} className="caretclass fa fa-caret-right"></i></span>
                </th>
                :
                header.DisplayText === "" ? <th scope="col"></th> :
                    <th scope="col" className={header.TextAlign}>
                        {header.DisplayText}
                        <span>&nbsp;<i onClick={(e) => this.arraySort(e, header.ColumnName)} className="caretclass fa fa-caret-right"></i></span>
                    </th>);

        //actual header names from json data.
        const valueheaders = this.props.tableheaders.map(header => header.ColumnName);

        //data to display in table.
        const datalist = this.state.tabledata === undefined ? undefined :
            this.state.tabledata.length > 0 &&
            this.state.tabledata.map((data, i) =>
                // <tr key={data.storeID+'_'+data.ticketSeqNum}>
                <tr className={(i % 2 != 0) ? "table-row-bg" : ""}>
                    {
                        this.props.tableheaders.map(col =>
                            //for transet report
                            col.ColumnName === "ticketSeqNum" ?
                                <td className={col.TextAlign}>
                                    <a href="javascript:void(0)" onClick={() => this.showDialog(data)}>{data["transetIdNew"] !== null ? data["transetIdNew"] : data[col.ColumnName]}</a>
                                </td>
                                :
                                //for transet report
                                col.ColumnName === "Signature" ?
                                    data.cardDetails && data.cardDetails.length > 0 && data.cardDetails[0].signature !== undefined && data.cardDetails[0] !== undefined && data.cardDetails[0].signature !== null && data.cardDetails[0].signature !== "null" ?
                                        <td className={col.TextAlign}><i className="fa fa-check-circle fa-2x" style={{ color: "green", opacity: 0.7 }}></i></td> : <td><i className="fa fa-times-circle fa-2x" style={{ color: "red", opacity: 0.7 }}></i></td>
                                    :
                                    //for transet report
                                    col.ColumnName === "totalWithTax" ?
                                        <td className={col.TextAlign}>${this.replace(col.ColumnName, data) === 'null' ? "" : this.replace(col.ColumnName, data)}</td>
                                        :
                                        //for transet report
                                        col.ColumnName === "processedDate" ?
                                            <td className={col.TextAlign}>{this.replace(col.ColumnName, data) === 'null' ? "" :
                                                moment.utc(this.replace(col.ColumnName, data)).local().format("MM/DD/YYYY HH:mm")}
                                                {/* this.replace(col.ColumnName, data).split("T")[0].split("-")[1]}/
                                         {this.replace(col.ColumnName, data).split("T")[0].split("-")[2]}/
                                         {this.replace(col.ColumnName, data).split("T")[0].split("-")[0]}
                                                 {" " + this.replace(col.ColumnName, data).split("T")[1].split(":")[0]}:
                                         {this.replace(col.ColumnName, data).split("T")[1].split(":")[1]} */}
                                            </td>
                                            :
                                            //for transet report
                                            col.ColumnName === "mop" ?
                                                <td className={col.TextAlign}>{data.cardDetails && data.cardDetails.length > 0 && data.cardDetails[0].mop && data.cardDetails[0].mop}</td>
                                                :
                                                <td className={col.TextAlign}>
                                                    {/* {data[col.ColumnName]} */}
                                                    {this.replace(col.ColumnName, data) === 'null' ? "" : this.replace(col.ColumnName, data).replace("&amp;", "&")}
                                                </td>
                        )
                    }
                </tr>
            );

        //const displayfooter=this.props.tableobjectdata===undefined||this.props.tablefooters===[]?<tr></tr>:this.props.tablefooters.map(footer=>

        const displayfooter = this.props.tableobjectdata === undefined ||
            this.props.tabledata === undefined || this.props.tabledata.length === 0
            ? <tr></tr> : this.props.tablefooters.map(footer =>
                footer.RowText === "Transet Pagination" ?
                    // this.props.hideTransetNextButton === false &&
                    <tr className="table-footer-row table-body-content">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="textAlignLeft"><button id="paginationBtn" className="btn" onClick={this.props.hideTransetNextButton === false ? this.props.transetNextClick : this.props.transetShiftNextClick}><b>Next</b></button></td>
                    </tr>
                    :
                    footer.RowText === "Department NEG Dept." ?
                        <tr className="table-footer-row row1-gen-dept">
                            <td className="textAlignLeft">NEG Dept.</td>
                            {footer.RowValue.map(col =>
                                col.ColumnName === "" || this.props.tableobjectdata[footer.FooterKey] === undefined ? <td></td> :
                                    //for Network Product Report
                                    col.ColumnName === "NetworkProdTotal" ?
                                        <td className={col.TextAlign}>{this.props.tableobjectdata[footer.FooterKey]}</td>
                                        :
                                        <td className={col.TextAlign}>{this.props.tableobjectdata[footer.FooterKey][col.ColumnName]}</td>
                            )}
                        </tr>
                        :
                        footer.RowText === "Department Other Dept." ?
                            <tr className="table-footer-row row1-gen-dept">
                                <td className="textAlignLeft">Other Dept.</td>
                                {footer.RowValue.map(col =>
                                    col.ColumnName === "" || this.props.tableobjectdata[footer.FooterKey] === undefined ? <td></td> :
                                        //for Network Product Report
                                        col.ColumnName === "NetworkProdTotal" ?
                                            <td className={col.TextAlign}>{this.props.tableobjectdata[footer.FooterKey]}</td>
                                            :
                                            <td className={col.TextAlign}>{this.props.tableobjectdata[footer.FooterKey][col.ColumnName]}</td>
                                )}
                            </tr>
                            :
                            footer.RowText === "Without Fuel Deposit (9999)" && deptWithout9999Row !== undefined ?
                                <tr className="table-footer-row row2-gen-dept">
                                    <td className="textAlignLeft">Without Manual Fuel</td>
                                    <td></td>
                                    <td></td>
                                    <td className="textAlignRight">{(this.props.tableobjectdata[footer.FooterKey]["itemCount"] - deptWithout9999Row.netSalesItemCount).toFixed(3)}</td>
                                    <td className="textAlignRight">{(this.props.tableobjectdata[footer.FooterKey]["percentOfSales"] - deptWithout9999Row.percentOfSales).toFixed(2)}</td>
                                    <td className="textAlignRight">{(this.props.tableobjectdata[footer.FooterKey]["grossSales"] - deptWithout9999Row.grossSales).toFixed(2)}</td>
                                    <td className="textAlignRight">{(this.props.tableobjectdata[footer.FooterKey]["refunds"] - deptWithout9999Row.refundAmount).toFixed(2)}</td>
                                    <td className="textAlignRight">{(this.props.tableobjectdata[footer.FooterKey]["discounts"] - deptWithout9999Row.discountsTotalAmount).toFixed(2)}</td>
                                    <td className="textAlignRight">{(this.props.tableobjectdata[footer.FooterKey]["netSales"] - deptWithout9999Row.netSalesAmount).toFixed(2)}</td>
                                </tr>
                                :
                                footer.RowText === "Deparment Total" ?
                                    <tr className="table-footer-total-count">
                                        <td className="textAlignLeft">Total</td>
                                        {footer.RowValue.map(col =>
                                            col.ColumnName === "" || this.props.tableobjectdata[footer.FooterKey] === undefined ? <td></td> :
                                                //for Network Product Report
                                                col.ColumnName === "NetworkProdTotal" ?
                                                    <td className={col.TextAlign}>{this.props.tableobjectdata[footer.FooterKey]}</td>
                                                    :
                                                    <td className={col.TextAlign}>{this.props.tableobjectdata[footer.FooterKey][col.ColumnName]}</td>
                                        )}
                                    </tr>
                                    :
                                    <tr className="table-footer-row table-body-content">
                                        <td className="textAlignLeft">{footer.RowText}</td>
                                        {footer.RowValue.map(col =>
                                            col.ColumnName === "" || this.props.tableobjectdata[footer.FooterKey] === undefined ? <td></td> :
                                                //for Network Product Report
                                                col.ColumnName === "NetworkProdTotal" ?
                                                    <td className={col.TextAlign}>{this.props.tableobjectdata[footer.FooterKey]}</td>
                                                    :
                                                    <td className={col.TextAlign}>{this.props.tableobjectdata[footer.FooterKey][col.ColumnName]}</td>
                                        )}
                                    </tr>
            )

        //modal description
        const description = this.state.modalDisplyData !== undefined &&
            this.state.modalDisplyData.items !== null &&
            this.state.modalDisplyData.items !== undefined &&
            this.state.modalDisplyData.items.length > 0 &&
            this.state.modalDisplyData.items.map(x =>
                <span>{x.description}<br /></span>
            );
        //modal category
        const category = this.state.modalDisplyData !== undefined &&
            this.state.modalDisplyData.items !== null &&
            this.state.modalDisplyData.items !== undefined &&
            this.state.modalDisplyData.items.length > 0 &&
            this.state.modalDisplyData.items.map(x =>
                <span>{x.category}<br /></span>
            );
        //modal department
        const department = this.state.modalDisplyData !== undefined &&
            this.state.modalDisplyData.items !== null &&
            this.state.modalDisplyData.items !== undefined &&
            this.state.modalDisplyData.items.length > 0 &&
            this.state.modalDisplyData.items.map(x =>
                <span>{x.department}<br /></span>
            );
        //modal subtotal
        let subtotal = 0;
        this.state.modalDisplyData !== undefined &&
            this.state.modalDisplyData.items !== null &&
            this.state.modalDisplyData.items !== undefined &&
            this.state.modalDisplyData.items.length > 0 &&
            this.state.modalDisplyData.items.map(x => subtotal += parseFloat(x.total));
        //modal unitprice
        const unitPrice = this.state.modalDisplyData !== undefined &&
            this.state.modalDisplyData.items !== null &&
            this.state.modalDisplyData.items !== undefined &&
            this.state.modalDisplyData.items.length > 0 &&
            this.state.modalDisplyData.items.map(x =>
                <span>{x.unitPrice}<br /></span>
            );
        //modal total
        const total = this.state.modalDisplyData !== undefined &&
            this.state.modalDisplyData.items !== null &&
            this.state.modalDisplyData.items !== undefined &&
            this.state.modalDisplyData.items.length > 0 &&
            this.state.modalDisplyData.items.map(x =>
                <span>{parseFloat(x.total).toFixed(2)}<br /></span>
            );
        //modal quantity
        const quantity = this.state.modalDisplyData !== undefined &&
            this.state.modalDisplyData.items !== null &&
            this.state.modalDisplyData.items !== undefined &&
            this.state.modalDisplyData.items.length > 0 &&
            this.state.modalDisplyData.items.map(x =>
                <span>{x.quantity}<br /></span>
            );
        //modal cardDetails
        const cardDetails = this.state.modalDisplyData !== undefined &&
            this.state.modalDisplyData.cardDetails !== undefined &&
            this.state.modalDisplyData.cardDetails.length > 0 &&
            this.state.modalDisplyData.cardDetails.map(x => {
                var ccname;
                var cardnumber;
                if (x.ccName !== undefined && x.ccName !== null) {
                    ccname = x.ccName;
                } else {
                    ccname = "Amount";
                }
                if (x.account !== undefined && x.account !== null) {
                    cardnumber = x.account.substring(x.account.length - 4, x.account.length).padStart(12, '*');
                } else {
                    cardnumber = "";
                }
                return <div className="col-md-10 carddata" >
                    <div>{ccname}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${x.amount}</div>
                    <div>Acct/Card #:&nbsp;{cardnumber}</div>
                    <div>MOP:&nbsp;{x.mop}</div>
                    <div>MerchID:&nbsp;{x.merchID}</div>
                    <div>TermID:&nbsp;{x.termID}</div>
                    <div>Transition Date:&nbsp;{moment.utc(this.state.modalDisplyData.processedDate).local().format("MM/DD/YYYY HH:mm")}</div>
                    {/* <span>MOP:&nbsp;<b className='modelb'>{x.mop}</b></span>&nbsp;&nbsp;&nbsp;*/}
                </div>
            }
            );
        const noDataCss = {
            'paddingTop': '20%',
            'textAlign': 'center'
        }

        const modalAddress = this.props.storesList !== undefined &&
            this.props.storesList.length > 0 &&
            this.state.modalDisplyData !== undefined &&
            this.props.storesList.map(store =>
                store.storeID === this.state.modalDisplyData.storeID &&
                <div className="mr-0">
                    <div style={{ textTransform: "uppercase" }}>{store.name}</div>
                    <div>{store.address.address !== undefined && store.address.address},{store.address.city !== undefined && store.address.city}</div>
                    <div>{store.address.state !== undefined && store.address.state},{store.address.zip !== undefined && store.address.zip}</div>
                </div>
            )

        const modalTable = this.state.modalDisplyData !== undefined &&
            this.state.modalDisplyData.items !== null &&
            this.state.modalDisplyData.items !== undefined &&
            this.state.modalDisplyData.items.length > 0 &&
            this.state.modalDisplyData.items.map(x =>
                <div className="row m-0">
                    <div className="col-md-4">{x.description.replace("&amp;", "&")}</div>
                    <div className="col-md-4">{x.quantity}</div>
                    <div className="col-md-4">{parseFloat(x.total).toFixed(2)}</div>
                </div>
            );
        return (
            <div>
                <table id="printTable" className="table">
                    <thead className="body-content-table">
                        <tr className="table-row-top-border">
                            {/* table headers display here */}
                            {displayheaders}
                        </tr>
                    </thead>
                    <tbody className="table-body-content-inner">
                        {/* table data display here */}
                        {datalist !== undefined && datalist.length > 0 ? datalist : <div style={noDataCss}><h4>{statusText}</h4></div>}
                        {/* </tbody>
                    <tbody className="table-body-content"> */}
                        {displayfooter}
                    </tbody>
                </table>

                <Modal open={this.state.open} onClose={this.onCloseModal} center >
                    <ReactToPrint
                        trigger={() => <a href="javascript:void(0)">Print</a>}
                        content={() => this.refs.printDiv}
                    />
                    <div ref="printDiv">
                        {/* modal heading address display here */}
                        {/* {addresses[this.state.modalDisplyData.storeID] ? addresses[this.state.modalDisplyData.storeID] : addresses['default']} */}
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <div className="modalheaddata">
                            <div>WELCOME TO OUR STORE</div>
                            {modalAddress}
                        </div>
                        <div className="row m-0" style={{ paddingBottom: 15 }}>
                            <div className="col-md-4">
                                <b>{this.state.modalDisplyData !== undefined &&
                                    this.state.modalDisplyData.transetIdNew != null ? "#" + this.state.modalDisplyData.transetIdNew : "#" + this.state.modalDisplyData.ticketSeqNum}</b>
                            </div>
                            <div className="col-md-4"></div>
                            <div className="col-md-4"><b>
                                {this.state.modalDisplyData !== undefined &&
                                    "Shift-" + this.state.modalDisplyData.periodSeqNum
                                }</b>
                            </div>
                        </div>
                        {/* <div className="row m-0"> */}
                        <div className="row m-0">
                            <div className="col-md-4"><b>Description</b></div>
                            <div className="col-md-4"><b>Qty</b></div>
                            <div className="col-md-4"><b>Amount</b></div>
                        </div>
                        {modalTable}
                        {/* <div className="col-md">
                                <b>Description</b><br />
                                {description}
                            </div>
                            <div className="col-md">
                                <b>Qty</b><br />
                                {quantity}
                            </div>
                            <div className="col-md">
                                <b>Amount</b><br />
                                {total}
                            </div> */}
                        {/* </div> */}
                        <div className="row m-0">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-4">
                                <span className="totaldata m-0">SubTotal</span>
                            </div>
                            <div className="col-md-4">
                                {/* modal subtotal display here */}
                                <span className="totaldata m-0">{parseFloat(subtotal).toFixed(2)}</span>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-4">
                                <span className="totaldata m-0">Tax</span>
                            </div>
                            <div className="col-md-4">
                                {/* modal tax display here */}
                                <span className="totaldata m-0">{this.state.modalDisplyData.totalTax}</span>
                            </div>
                        </div>
                        <div className="row m-0">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-4">
                                <span className="totaldata m-0"><b>Total</b></span>
                            </div>
                            <div className="col-md-4">
                                {/* modal total+tax display here */}
                                <span className="totaldata m-0">{(subtotal + parseFloat(this.state.modalDisplyData.totalTax)).toFixed(2)}</span>
                            </div>
                        </div>
                        <div className="row m-0 carddetails">
                            {/* modal carddetails display here */}
                            {cardDetails}
                        </div>
                        {/* modal signature display here */}
                        {this.state.sign ? <Canvas srccanvas={this.state.sign} /> : undefined}
                        <div className="modalheaddata">
                            <div>I agree to pay the amount charged     </div>
                            <div>by the merchant for fuel, merchandise     </div>
                            <div> and applicable taxes</div>
                        </div>
                        <div className="modalheaddata" id="modalfootdata">
                            <div>THANK YOU</div>
                            <div>PLEASE COME AGAIN</div>
                            <div>HAVE A NICE DAY</div>
                        </div>
                        <div><hr /></div>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default Table;