import React, { Component } from 'react';
import { jsondata } from '../urlandheaderdata';
import { stores } from '../urlandheaderdata';
import { departments } from '../urlandheaderdata';
import Tooltip_sidemenu from './tooltip_sidemenu';
import $ from 'jquery';

class Sidebarmenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            storeIcon: ""
        }
        this.tooltipHover = this.tooltipHover.bind(this);
        this.syncTimeChange = this.syncTimeChange.bind(this);
        this.syncTimeSpinnerOn = this.syncTimeSpinnerOn.bind(this);
        this.syncTimeSpinnerOff = this.syncTimeSpinnerOff.bind(this);
        this.updateStatusStoreList = this.updateStatusStoreList.bind(this);
        this.storeStatusIconRef = [];
    }
    openAddstore() {
        // alert("Coming Soon...!")
        document.getElementById("add-store").style.width = "100%";
    }
    componentDidMount() {
        var that = this;
        $(".sidebar-menu-tooltip").mouseover(function (e) {
            e.stopPropagation();
        });
        $(document).mouseover(function (e) {
            if (!($(e.target).hasClass("hoverclass"))) {
                $(".sidebar-menu-tooltip").hide();
                that.forceUpdate();
            }
        });
    }
    tooltipHover(event, storeid) {
        $(`.store${storeid}`).css("top", 105 + $(`#sidebardropdown${storeid}`).position().top);
        $(`.store${storeid}`).show();
        event.stopPropagation();
    }
    syncTimeChange(store) {
        this.props.syncTimeChange(store);
    }
    syncTimeSpinnerOn() {
        this.props.syncTimeSpinnerOn();
    }
    syncTimeSpinnerOff() {
        this.props.syncTimeSpinnerOff();
    }
    updateStatusStoreList() {
        this.props.updateStatusStoreList();
    }
    render() {

        if (this.props.storesList != undefined) {
            this.props.storesList.forEach(item => {
                this.storeStatusIconRef[item.storeID] = React.createRef();
            });
        }

        const tooltips = this.props.storesList !== undefined && this.props.storesList.length > 0 && this.props.storesList.map((store) =>
            <Tooltip_sidemenu
                changeStoreIcon={this.changeStoreIcon}
                syncTimeChange={this.syncTimeChange}
                currentStore={store} tooltipIndex={store.storeID}
                syncTimeSpinnerOn={this.syncTimeSpinnerOn}
                syncTimeSpinnerOff={this.syncTimeSpinnerOff}
                storeStatusRefs={this.storeStatusIconRef}
                updateStatusStoreList={this.updateStatusStoreList}
            />
        )
        const menuitems = this.props.storesList !== undefined && this.props.storesList.length > 0
            && this.props.storesList.map((store) =>
                // <li className="sidebar-dropdown" data-toggle="tooltip" data-placement="right" title="Tooltip on right">
                <li className="sidebar-dropdown" id={`sidebardropdown${store.storeID}`} data-toggle="tooltip">
                    <a href="javascript:void(0)" onMouseOver={(cntr) => this.tooltipHover(cntr, store.storeID)} className="parent-sidemenu"
                        data-store-id={store.storeID}
                        data-store-address={`${store.address.address !== null && store.address.address ? store.address.address + "," : ""}
                     ${store.address.city && store.address.city !== null ? store.address.city + "," : ""}
                     ${store.address.state && store.address.state !== null ? store.address.state : ""}
                     ${store.address.zip && store.address.zip !== null ? store.address.zip : ""}`}
                        onClick={() => this.props.renderReportOfStoreClick(store.storeID)}>
                        <i ref={this.storeStatusIconRef[store.storeID]} className="fa fa-circle .commander-status-gray-icon hoverclass" />
                        <span>{store.name}</span>
                    </a>
                    <div className="sidebar-submenu" id={`sidebar-submenu${store.storeID}`}>
                        <ul>
                            {departments.map(
                                report =>
                                    report.dept === this.props.deptKey ?
                                        <li>
                                            {/* <a href="javascript:void(0)"className="left-menu-active-item" onClick={() => this.props.menuItemClick(report)}> <i className={report.icon}></i>{report.dept}</a> */}
                                            <a href="javascript:void(0)" onClick={() => this.props.menuItemClick(report)} className="left-menu-active-item">
                                                <i className={report.icon}></i>{report.dept}
                                            </a>
                                        </li>
                                        :
                                        <li>
                                            {/* <a href="javascript:void(0)"className="left-menu-active-item" onClick={() => this.props.menuItemClick(report)}> <i className={report.icon}></i>{report.dept}</a> */}
                                            <a href="javascript:void(0)" onClick={() => this.props.menuItemClick(report)}>
                                                <i className={report.icon}></i>{report.dept}
                                            </a>
                                        </li>
                            )}

                        </ul>
                    </div>
                </li>
            )
        return (
            <div className="SideNavPanel">
                <a id="show-sidebar" className="btn btn-sm btn-dark" href="javascript:void(0)">
                    <i className="fa fa-arrow-right"></i>
                </a>
                <nav id="sidebar" className="sidebar-wrapper">
                    <div className="sidebar-content">
                        <div className="sidebar-brand">
                            <div id="close-sidebar">
                                <i className="fa fa-bars"></i>
                            </div>
                            <div href="#" className="brand-logo">
                                {/* <span style={{color: "white",fontWeight: "bold", fontSize:"large"}}>{this.props.storeName}</span> */}
                                <h6 style={{ fontSize: "0.92rem" }}>{this.props.storeName}</h6>
                            </div>
                        </div>
                        <div className="sidebar-menu">
                            <div className="Sidebar-menu-title">
                                <h6><img className="store-icon" src="assets/images/obg-store.png" alt="User picture" />
                                    <span>MY STORE</span> <span className="store-count">({this.props.storesList.length})</span></h6>
                            </div>
                            <div className="add-store-button-section">
                                <button className="btn add-store-button" onClick={this.openAddstore}>
                                    <i className="fa fa-plus m-r-10"></i>ADD NEW STORE
                                </button>
                            </div>
                            <ul className="side-menu-list-container">
                                <div className="side-menu-list">
                                    {/* error change */}
                                    {menuitems}
                                </div>
                                {/* Tooltip_sidemenu component renders here */}
                                {tooltips}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}
export default Sidebarmenu;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   