import React, { Component } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import $ from 'jquery';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import UserRegistration from './UserRegistration';
import { prefixUrl, poolData } from '../urlandheaderdata';
import Cookies from 'js-cookie';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import createNotification from '../services/AlertService';

class Login extends Component {
  constructor(props) {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };

    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.ForgotPassword = this.ForgotPassword.bind(this);
    this.ForgotPasswordSendMail = this.ForgotPasswordSendMail.bind(this);
    this.ForgotPasswordUpdateNewPwd = this.ForgotPasswordUpdateNewPwd.bind(this);
    this.ResetPasswordUpdateNewPwd = this.ResetPasswordUpdateNewPwd.bind(this);
    this.state = {
      loadSpin: false,
      loadSpinForUpdatePwd: false,
      email: "",
      password: "",
      errors: {}
    }
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
  }

  componentDidMount() {
    var isUserSignedIn = sessionStorage.getItem('SignedInConfirmation');
    if (isUserSignedIn != null) {
      this.props.history.push('/dashboard');
    }

    $('.rememberMeCheckboxLabel').click(function () {
      $('#rememberme').prop('checked', function () {
        return !this.checked;
      });
    });

    var remember = Cookies.get('rememberuser');
    if (remember == 'true') {
      // autofill the fields
      $('#username').attr("value", Cookies.get('useremail'));
      $('#password').attr("value", Cookies.get('userpassword'));
      $("#rememberme").attr('checked', true);
    }
  }

  handleChange(event) {
    this.setState({
      errors: { ...this.state.errors, [event.target.name]: undefined }
    })
  }

  enableOrDisableControls(booleanvalue) {
    this.emailRef.current.disabled = booleanvalue;
    this.passwordRef.current.disabled = booleanvalue;
  }

  login($event) {
    var loginEmailId = document.getElementById("username").value;
    var password = document.getElementById("password").value;
    if ((loginEmailId === null || loginEmailId === "" || loginEmailId === "null") && (password === null || password === "" || password === "null")) {
      this.setState({
        errors: {
          "email": "Email is required.",
          "password": "Password is required."
        }
      })
    }
    else if (loginEmailId === null || loginEmailId === "" || loginEmailId === "null") {
      this.setState({
        errors: { "email": "Email is required." }
      })
    }
    else if (password === null || password === "" || password === "null") {
      this.setState({
        errors: { "password": "Password is required." }
      })
    }
    else if (!/\S+@\S+\.\S+/.test(loginEmailId)) {
      this.setState({
        errors: { "email": "Email is invalid." }
      })
    }
    else if (loginEmailId != null && password != null) {
      this.setState({
        loadSpin: true,
        errors: {}
      });
      $('#SignUpLink').removeAttr("href");
      $('#SignUpLink').removeAttr("data-toggle");
      $('#forgotPasswordLink').removeAttr("data-target");
      this.enableOrDisableControls(true);

      //this.loginUser(userid, password);
      if (loginEmailId === "gasland.objectsource@gmail.com") {
        createNotification('error', 'Your trail period has been expired, please contact to Mr. Jonathan at (225) 931-6118');
        this.setState({
          loadSpin: false
        });
        this.enableOrDisableControls(false);
        $('#SignUpLink').attr("href", "#registration-form");
        $('#SignUpLink').attr("data-toggle", "tab");
        $('#forgotPasswordLink').attr("data-target", "#myModal");
      } else {
        var authenticationData = {
          Username: loginEmailId,
          Password: password,
        };
        var authenticationDetails = new AuthenticationDetails(authenticationData);
        var userPool = new CognitoUserPool(poolData);
        var userData = {
          Username: loginEmailId,
          Pool: userPool
        };
        var cognitoUser = new CognitoUser(userData);
        var that = this;
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: function (result) {
            $('#SignUpLink').attr("href", "#registration-form");
            $('#SignUpLink').attr("data-toggle", "tab");
            $('#forgotPasswordLink').attr("data-target", "#myModal");

            var checkbox = document.getElementById("rememberme");
            if (checkbox.checked === true) {
              // set cookies
              Cookies.set('useremail', loginEmailId, { expires: 15 });
              Cookies.set('userpassword', password, { expires: 15 });
              Cookies.set('rememberuser', checkbox.checked, { expires: 15 });
            } else {
              // reset cookies
              Cookies.set('useremail', null);
              Cookies.set('userpassword', null);
              Cookies.set('rememberuser', null);
            }

            /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer*/
            var idToken = result.idToken.jwtToken;
            console.log(idToken); // include from axios interceptor

            cognitoUser = userPool.getCurrentUser();
            cognitoUser.getSession((err, session) => {
              if (err) {
                console.log(err);
                return;
              }
              const token = session.getIdToken().getJwtToken();
              axios.defaults.headers.common['Authorization'] = token;
            });
            // cognitoUser.getUserAttributes((res) => {
            //   console.log(res);//to fetch storeName and other attributes
            // });
            // var user = { userID: cognitoUser.username, email: loginEmailId, storeName: 'Object Source Inc.' };
            // localStorage.setItem('userInfo', JSON.stringify(user));
            let url = prefixUrl + `Stores?userID=${cognitoUser.username}`;
            axios.get(url).then(
              res => {
                that.setState({
                  loadSpin: false
                });
                console.log(res.data.length);
                if (res.data.length > 0) {
                  sessionStorage.setItem("SignedInConfirmation", "SignedIn");
                  that.props.history.push('/dashboard');
                }
                else {
                  console.log(res.data.length);
                  sessionStorage.setItem("AddStorePage", "PageLoaded");
                  that.props.history.push('/AddStoreAfterSignup');
                }
              }).catch(error => {
                that.setState({
                  loadSpin: false
                });
                console.log(error);
              });
            that.enableOrDisableControls(false);
          },
          onFailure: function (err) {
            if (err.name === "PasswordResetRequiredException") {
              console.log(err);
              cognitoUser.forgotPassword({
                onSuccess: function (result) {
                  console.log('call result: ' + result);
                  $("#resetPwdEmail").val(loginEmailId);
                },
                onFailure: function (err) {
                  console.log(err);
                  $("#resetPwdEmail").val(loginEmailId);
                  createNotification('error', err.message);
                  //alert(err.message);
                }
              });
              window.$('#myResetPwdModal').modal('show');
              $(".resetPasswordAtloginDiv").show();
              console.log("after show");
            } else {
              console.log(err);
              createNotification('error', err.message);
            }
            //alert(err.message);
            // if (err.message === "User is not confirmed.") {
            //   cognitoUser.resendConfirmationCode(function (err, result) {
            //     if (err) {
            //       alert(err);
            //       return;
            //     }
            //     alert(result);
            //   });
            // }
            that.setState({
              loadSpin: false
            });
            that.enableOrDisableControls(false);
            $('#SignUpLink').attr("href", "#registration-form");
            $('#SignUpLink').attr("data-toggle", "tab");
            $('#forgotPasswordLink').attr("data-target", "#myModal");
          },
        });
      }
    }

    $event.preventDefault();
  }

  ForgotPassword() {
    $(".resetPasswordDiv").show();
    $(".newPasswordDiv").hide();
  }

  ForgotPasswordSendMail() {
    var forgotPwdEmail = $("#forgotPwdEmail").val();
    if (forgotPwdEmail === null || forgotPwdEmail === "" || forgotPwdEmail === "null") {
      this.setState({
        errors: { "forgotpwdEmail": "Email  is required." }
      })
    }
    else if (!/\S+@\S+\.\S+/.test(forgotPwdEmail)) {
      this.setState({
        errors: { "forgotpwdEmail": "Email is invalid." }
      })
    }
    else if (forgotPwdEmail !== null || forgotPwdEmail !== "") {
      var userPool = new CognitoUserPool(poolData);
      var userData = {
        Username: forgotPwdEmail,
        Pool: userPool
      };
      var cognitoUser = new CognitoUser(userData);
      // call forgotPassword on cognitoUser
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          $("#updatePwdEmail").val(forgotPwdEmail);
          $(".resetPasswordDiv").hide();
          $(".newPasswordDiv").show();
          console.log('call result: ' + result);
        },
        onFailure: function (err) {
          console.log(err);
          createNotification('error', err.message);
          //alert(err.message);
        }
      });
    }
  }

  ForgotPasswordUpdateNewPwd() {
    var forgotPwdEmail = $("#updatePwdEmail").val();
    var verifyCode = $("#updatePwdVerifyCode").val();
    var newPassword = $("#updatePwdNewPwd").val();
    var newConfirmPassword = $("#updatePwdConfirmPwd").val();

    if ((verifyCode === null || verifyCode === "" || verifyCode === "null") && (newPassword === null || newPassword === "" || newPassword === "null") && (newConfirmPassword === null || newConfirmPassword === "" || newConfirmPassword === "null")) {
      this.setState({
        errors: {
          "verificationCode": "Verification code  is required.",
          "newPassword": "New Password is required.",
          "confirmNewPassword": "Confirm Password is required."
        }
      })
    }
    else if (verifyCode === null || verifyCode === "" || verifyCode === "null") {
      this.setState({
        errors: { "verificationCode": "Verification code  is required." }
      })
    }
    else if (newPassword === null || newPassword === "" || newPassword === "null") {
      this.setState({
        errors: { "newPassword": "New Password is required." }
      })
    }
    else if (newConfirmPassword === null || newConfirmPassword === "" || newConfirmPassword === "null") {
      this.setState({
        errors: { "confirmNewPassword": "Confirm Password is required." }
      })
    }
    else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()~])[A-Za-z\d@$!%*?&#^()~]{8,}$/.test(newPassword)) {
      this.setState({
        errors: { "newPassword": "Password must have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character." }
      })
    }
    else if (newPassword !== newConfirmPassword) {
      this.setState({
        errors: { "confirmNewPassword": "Confirm Password is Invalid." }
      })
    }
    else if (verifyCode !== null || verifyCode !== "" || newPassword !== null || newPassword !== "" || newConfirmPassword !== null || newConfirmPassword !== "") {
      this.setState({
        loadSpinForUpdatePwd: true,
        errors: {}
      });

      var userPool = new CognitoUserPool(poolData);
      var userData = {
        Username: forgotPwdEmail,
        Pool: userPool
      };
      var that = this;
      var cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmPassword(verifyCode, newPassword, {
        onFailure(err) {
          console.log(err);
          createNotification('error', err.message);
          //alert(err.message);
          that.setState({
            loadSpinForUpdatePwd: false
          });
        },
        onSuccess() {
          var checkbox = document.getElementById("rememberme");
          if (checkbox.checked === true) {
            // set password to cookies when user update a password.
            Cookies.set('userpassword', newPassword, { expires: 15 });
            $('#password').attr("value", Cookies.get('userpassword'));
          }
          createNotification('success', "Password updated successfully!");
          //alert("Password updated successfully!");
          that.setState({
            loadSpinForUpdatePwd: false
          });
        },
      });
    }
  }

  LearnMoreBtnClickRouteToLink() {
    window.open('http://objectsourceinc.com', '_blank')
  }

  ResetPasswordUpdateNewPwd() {
    var forgotPwdEmail = $("#resetPwdEmail").val();
    var verifyCode = $("#resetPwdVerifyCode").val();
    var newPassword = $("#resetPwdNewPwd").val();
    var newConfirmPassword = $("#resetPwdConfirmPwd").val();
    if ((verifyCode === null || verifyCode === "" || verifyCode === "null") && (newPassword === null || newPassword === "" || newPassword === "null") && (newConfirmPassword === null || newConfirmPassword === "" || newConfirmPassword === "null")) {
      this.setState({
        errors: {
          "verificationCode": "Verification code  is required.",
          "newPassword": "New Password is required.",
          "confirmNewPassword": "Confirm Password is required."
        }
      })
    }
    else if (verifyCode === null || verifyCode === "" || verifyCode === "null") {
      this.setState({
        errors: { "verificationCode": "Verification code  is required." }
      })
    }
    else if (newPassword === null || newPassword === "" || newPassword === "null") {
      this.setState({
        errors: { "newPassword": "New Password is required." }
      })
    }
    else if (newConfirmPassword === null || newConfirmPassword === "" || newConfirmPassword === "null") {
      this.setState({
        errors: { "confirmNewPassword": "Confirm Password is required." }
      })
    }
    else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()~])[A-Za-z\d@$!%*?&#^()~]{8,}$/.test(newPassword)) {
      this.setState({
        errors: { "newPassword": "Password must have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character." }
      })
    }
    else if (newPassword !== newConfirmPassword) {
      this.setState({
        errors: { "confirmNewPassword": "Confirm Password is Invalid." }
      })
    }
    else if (verifyCode !== null || verifyCode !== "" || newPassword !== null || newPassword !== "" || newConfirmPassword !== null || newConfirmPassword !== "") {
      this.setState({
        loadSpinForUpdatePwd: true,
        errors: {}
      });

      var userPool = new CognitoUserPool(poolData);
      var userData = {
        Username: forgotPwdEmail,
        Pool: userPool
      };
      var that = this;
      var cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmPassword(verifyCode, newPassword, {
        onFailure(err) {
          console.log(err);
          createNotification('error', err.message);
          //alert(err.message);
          that.setState({
            loadSpinForUpdatePwd: false
          });
        },
        onSuccess() {
          var checkbox = document.getElementById("rememberme");
          if (checkbox.checked === true) {
            // set password to cookies when user update a password.
            Cookies.set('userpassword', newPassword, { expires: 15 });
            $('#password').attr("value", Cookies.get('userpassword'));
          }
          createNotification('success', "Password updated successfully!");
          //alert("Password updated successfully!");
          that.setState({
            loadSpinForUpdatePwd: false
          });
          $(".resetPasswordAtloginDiv").hide();
          window.$('#myResetPwdModal').modal('hide');
        },
      });
    }
  }
  render() {
    const loadSpinnerOrLoginButton = this.state.loadSpin ? <div className="loginLoadSpin"><Loader type="ThreeDots" color="#0069D9" height={50} width={70} /></div> :
      <button onClick={this.login} className="btn btn-primary login-submit-button" role="button">Login</button>;

    const loadSpinnerOrUpdatePasswordBtn = this.state.loadSpinForUpdatePwd ? <div className="loginLoadSpin"><Loader type="ThreeDots" color="#0069D9" height={50} width={70} /></div> :
      <a href="javascript:void(0)" className="btn btn-primary login-submit-button f-w-600" role="button" onClick={this.ForgotPasswordUpdateNewPwd}>Update Password</a>

    const loadSpinnerOrResetPasswordBtn = this.state.loadSpinForUpdatePwd ? <div className="loginLoadSpin"><Loader type="ThreeDots" color="#0069D9" height={50} width={70} /></div> :
      <a href="javascript:void(0)" className="btn btn-primary login-submit-button f-w-600" role="button" onClick={this.ResetPasswordUpdateNewPwd}>Reset Password</a>

    return (
      <div style={{ backgroundColor: "white" }}>
        <div className="Login-body-wrapper">
          <div className="row m-0">
            <div className="col-md-7 no-padding mobile-right-content">
              <div id="login-left-content">
                <div className="row">
                  <div className="col-8 offset-2 login-content">
                    <img src="./assets/images/Logo.png" style={{ width: "300px" }} />
                    <p>Object Source Inc is the fastest growing company in C-Store customizable apps.
                       We are specialized in building C-Store apps, all apps works with major POS systems like Verifone, Gilbarco and Square.
                       Also, our customizable Label Printer solution let our customers print labels right out of their price book or can able to design their own labels.
                    </p>
                    <div className="Login-left-buttons">
                      <button type="button" className="btn btn-bg-blue" onClick={this.LearnMoreBtnClickRouteToLink}>Learn More</button>
                      <button type="button" className="btn btn-outline" hidden>How it Works</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-5">
              <div className="row">
                <div className="col-8 offset-2 ">

                  <ul className="nav nav-tabs login-tabs">
                    <li className="nav-item">
                      <a className="nav-link active login-tab-button" id="LoginLink" data-toggle="tab" href="#login-form">LOGIN</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link register-tab-button" id="SignUpLink" data-toggle="tab" href="#registration-form">SIGN UP</a>
                    </li>

                  </ul>

                  {/* <!-- Tab panes --> */}
                  <div className="tab-content">
                    <div id="login-form" className="login-forn-content tab-pane active">
                      <h2>Hi there! Welcome to Object Source Inc</h2>
                      <form>
                        <div className="form-group login-search">
                          <span className="fa fa-user form-control-feedback"></span>
                          <input type="email" id="username" name="email" ref={this.emailRef} className="form-control bridge-input" placeholder="User Email" onChange={this.handleChange} autoComplete="off" />
                          {this.state.errors.email && (
                            <p style={{ color: "red" }}>{this.state.errors.email}</p>
                          )}
                        </div>
                        <div className="form-group login-search">
                          <span className="fa fa-key form-control-feedback"></span>
                          <input type="password" id="password" name="password" ref={this.passwordRef} className="form-control bridge-input" placeholder="Password" onChange={this.handleChange} autoComplete="off" />
                          {this.state.errors.password && (
                            <p style={{ color: "red" }}>{this.state.errors.password}</p>
                          )}
                        </div>
                        {/* <a href="thnaku-page.html" className="btn btn-primary submit-button" role="button">Login</a> */}
                        {/* <a href="javascript:void(0)" onClick={this.login} className="btn btn-primary login-submit-button" role="button">Login</a> */}
                        {loadSpinnerOrLoginButton}
                        <br /><br />
                        <div className="row">
                          <div className="col-6 dropdown-address has-search ">
                            <input type="checkbox" name="rememberme" id="rememberme" />&nbsp;
                            <label for="remember" className="rememberMeCheckboxLabel"> Remember me</label>
                          </div>
                          <div className="col-6 dropdown-address has-search">
                            <p className="cursor-pointer f-w-600" id="forgotPasswordLink" data-toggle="modal" data-target="#myModal" onClick={this.ForgotPassword}>Forgot Password</p>
                          </div>
                        </div>
                      </form>
                      <div className="Login-form-footer text-center curser-pointer">
                        {/* <p className="cursor-pointer f-w-600" id="forgotPasswordLink" data-toggle="modal" data-target="#myModal" onClick={this.ForgotPassword}>Forgot Password</p> */}
                        <p className="login-footer-terms">Read our Privacy policy</p>
                      </div>
                    </div>
                    <UserRegistration />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="login-page-footer-text">
          <p className="float-left">Object Source INC © 2019.
            </p>
          <p className="float-right">Powered by Object Source INC</p>
        </div>

        <div className="modal" id="myModal">
          <div className="modal-dialog reset-pass-dialog">
            <div className="modal-content reset-password-content">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="resetPasswordClose">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="reset-model-header resetPasswordDiv">
                <h2 className="f-w-600">Reset Password</h2>
                <p className="f-w-600">Please enter your registered email ID and we will send you the Password Reset Link</p>
              </div>

              <div className="modal-body resetPasswordDiv">
                <form>
                  <div className="form-group login-search">
                    <span className="fa fa-envelope form-control-feedback"></span>
                    <input type="text" className="form-control bridge-input" name="forgotpwdEmail" placeholder="Email ID" id="forgotPwdEmail" onChange={this.handleChange} />
                    {this.state.errors.forgotpwdEmail && (
                      <p style={{ color: "red" }}>{this.state.errors.forgotpwdEmail}</p>
                    )}
                  </div>
                  <a href="javascript:void(0)" className="btn btn-primary login-submit-button f-w-600" role="button" onClick={this.ForgotPasswordSendMail}>Send Link</a>
                </form>
              </div>

              <div className="reset-model-header newPasswordDiv">
                <h2 className="f-w-600">Update Password</h2>
                <p className="f-w-600">Verification code sent to mail successfully</p>
              </div>

              <div className="modal-body newPasswordDiv">
                <form>
                  <div className="form-group login-search">
                    <span className="fa fa-envelope form-control-feedback"></span>
                    <input type="text" className="form-control bridge-input" placeholder="Email ID" id="updatePwdEmail" disabled />
                  </div>
                  <div className="form-group login-search">
                    <span className="fa fa-user form-control-feedback"></span>
                    <input type="text" className="form-control bridge-input" name="verificationCode" placeholder="Verification Code" id="updatePwdVerifyCode" onChange={this.handleChange} />
                    {this.state.errors.verificationCode && (
                      <p style={{ color: "red" }}>{this.state.errors.verificationCode}</p>
                    )}
                  </div>
                  <div className="form-group login-search">
                    <span className="fa fa-key form-control-feedback"></span>
                    <input type="password" className="form-control bridge-input" name="newPassword" placeholder="New Password" id="updatePwdNewPwd" onChange={this.handleChange} />
                    {this.state.errors.newPassword && (
                      <p style={{ color: "red" }}>{this.state.errors.newPassword}</p>
                    )}
                  </div>
                  <div className="form-group login-search">
                    <span className="fa fa-key form-control-feedback"></span>
                    <input type="password" className="form-control bridge-input" name="confirmNewPassword" placeholder="Confirm Password" id="updatePwdConfirmPwd" onChange={this.handleChange} />
                    {this.state.errors.confirmNewPassword && (
                      <p style={{ color: "red" }}>{this.state.errors.confirmNewPassword}</p>
                    )}
                  </div>
                  {loadSpinnerOrUpdatePasswordBtn}
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="modal " id="myResetPwdModal" >
          <div className="modal-dialog reset-pass-dialog">
            <div className="modal-content reset-password-content">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="resetPasswordClose">
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="reset-model-header resetPasswordAtloginDiv">
                <h2 className="f-w-600" style={{ textAlign: "center" }}>Reset Password</h2>
                <p className="f-w-600">For security reasons please reset password.please check your email for verification code.</p>
              </div>
              <div className="modal-body resetPasswordAtloginDiv">
                <form>
                  <div className="form-group login-search">
                    <span className="fa fa-envelope form-control-feedback"></span>
                    <input type="text" className="form-control bridge-input" placeholder="Email ID" id="resetPwdEmail" disabled />
                  </div>
                  <div className="form-group login-search">
                    <span className="fa fa-user form-control-feedback"></span>
                    <input type="text" className="form-control bridge-input" name="verificationCode" placeholder="Verification Code" id="resetPwdVerifyCode" onChange={this.handleChange} />
                    {this.state.errors.verificationCode && (
                      <p style={{ color: "red" }}>{this.state.errors.verificationCode}</p>
                    )}
                  </div>
                  <div className="form-group login-search">
                    <span className="fa fa-key form-control-feedback"></span>
                    <input type="password" className="form-control bridge-input" name="newPassword" placeholder="New Password" id="resetPwdNewPwd" onChange={this.handleChange} />
                    {this.state.errors.newPassword && (
                      <p style={{ color: "red" }}>{this.state.errors.newPassword}</p>
                    )}
                  </div>
                  <div className="form-group login-search">
                    <span className="fa fa-key form-control-feedback"></span>
                    <input type="password" className="form-control bridge-input" name="confirmNewPassword" placeholder="Confirm Password" id="resetPwdConfirmPwd" onChange={this.handleChange} />
                    {this.state.errors.confirmNewPassword && (
                      <p style={{ color: "red" }}>{this.state.errors.confirmNewPassword}</p>
                    )}
                  </div>
                  {loadSpinnerOrResetPasswordBtn}
                </form>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div >
    )
  }
}
export default Login;