import React, { Component } from 'react';
import { departments } from '../urlandheaderdata';
import axios from 'axios';
import { prefixUrl } from '../urlandheaderdata';

class TableDetailsHeading extends Component {
  onstartload = true;
  constructor(props) {
    super(props);
    // this.render=this.render.bind(this);
    this.changeperiodseq = this.changeperiodseq.bind(this);
    this.loadfilterdata = this.loadfilterdata.bind(this);
    this.openFilettranset = this.openFilettranset.bind(this);
    this.openUpcFiltertranset = this.openUpcFiltertranset.bind(this);
    this.state = {
      filterdata: {
        period: '',
        periodseq: '',
        ticketseq: '',
        account: '',
        totalWithTax: ''
      },
      deptKey: ""
    }

  }

  changeperiodseq(evt) {
    var perioddataarr = evt.target.value.split('/');
    this.setState({
      filterdata: {
        period: perioddataarr[0],
        periodseq: perioddataarr[1]
      }
    }, () => {
      console.log(this.state);
    });
  }

  loadfilterdata() {
    var filterdataobj = {};
    filterdataobj.period = this.state.filterdata.period;
    filterdataobj.periodSeqNum = this.state.filterdata.periodseq;
    filterdataobj[document.getElementById("filterdropdown2").value] = document.getElementById("filtertextbox").value;
    this.props.clickfilterdata(filterdataobj);
  }

  componentDidMount() {
    //console.log(this.props.deptKey)
    this.setState({
      deptKey: this.props.deptKey
    })
    var onloadFilterDataObj = {};
    this.props.periodseq.map(x => {
      if (this.onstartload) {
        onloadFilterDataObj.period = x.period;
        onloadFilterDataObj.periodSeqNum = x.periodSeqNum;
        this.onstartload = false;
      }
    });
    //this.props.clickfilterdata(onloadFilterDataObj);
  }

  openFilettranset() {
    document.getElementById("Filtertranset").style.width = "100%";
  }
  openUpcFiltertranset() {
    axios.get(prefixUrl + `GetDepartmentsForTransetUPC?storeID=${this.props.storeID}`)
      .then(
        response => {
          if (response.status === 200 && response.data !== undefined && response.data.length > 0) {
            console.log(response.data);
            for (var i = 0; i < response.data.length; i++) {
              var option = document.createElement("option");
              option.value = (Object.values(response.data[i]))[0];
              option.text = (Object.keys(response.data[i]))[0].replace("&amp;", "&");
              document.getElementById("upcDepartment").appendChild(option);
            }
          }
        }
      )
      .catch(
        error => {
          console.log(error);
        }
      )

    document.getElementById("UpcFiltertranset").style.width = "100%";
  }
  render() {

    const dropdowndata = this.props.periodseq !== undefined &&
      this.props.periodseq.length > 0 &&
      this.props.periodseq.map((x, index) => index === 0 ?
        <option selected value={x.period + '/' + x.periodSeqNum} key={x.period + '/' + x.periodSeqNum}>
          {x.period}&nbsp;({x.periodSeqNum})
                                                  </option> :
        <option value={x.period + '/' + x.periodSeqNum} key={x.period + '/' + x.periodSeqNum}>
          {x.period}&nbsp;({x.periodSeqNum})
                                                  </option>
      );

    const icons = departments !== null &&
      departments.map(report =>
        report.dept === this.props.deptKey ?
          <a className="mr-1">
            <li className={`list-inline-item fa ${report.icon.split(" ")[1]} active-tabs`} ></li>
          </a> :
          <a className="mr-1" onClick={() => this.props.iconClickFun(report)}>
            <li className={`list-inline-item fa ${report.icon.split(" ")[1]}`} ></li>
          </a>
      )
    return (
      <div className="row m-0">
        <div className="col-md-6">
          <div className="store-report-title">
            <h5 className="float-left">{this.props.deptKey === "Department Report" && <li className="list-inline-item fa fa-building" ></li>}
              {this.props.deptKey === "Network Product Report" && <li className="list-inline-item fa fa-product-hunt" aria-hidden="true"></li>}
              {this.props.deptKey === "Network card Report" && <li className="list-inline-item fa fa-microchip"></li>}
              {this.props.deptKey === "Tank Report" && <li className="list-inline-item fa fa-cube"></li>}
              {this.props.deptKey === "Tier/PR Report" && <li className="list-inline-item fa fa-database"></li>}
              {this.props.deptKey === "POP Discount Report" && <li className="list-inline-item fa fa-percent"></li>}
              {this.props.deptKey === "FP Dispenser Report" && <li className="list-inline-item fa fa-rocket"></li>}
              {this.props.deptKey === "Loyalty Report" && <li className="list-inline-item fa fa-gift"></li>}
              {this.props.deptKey === "Tank Reconciliation" && <li className="list-inline-item fa fa-recycle"></li>}
              {this.props.deptKey === "Transet Report" && <li className="list-inline-item fa fa-file-text"></li>}
              {this.props.deptKey === "UPC Report" && <li className="list-inline-item fa fa-file-text"></li>}
              {/* <span><b>Department Report</b></span><span><button className="btn download-pdf-button"> */}
              <span><b>{this.props.deptKey}</b></span>
              {this.props.deptKey !== "Transet Report" && this.props.deptKey !== "UPC Report" &&
                <span><button className="btn download-pdf-button" onClick={() => this.props.printTable(this.props.deptKey)}>
                  PDF <i className="fa fa-download"></i></button>
                </span>
              }
            </h5>
            {this.props.deptKey === "Transet Report" &&
              <div className="Filter-button-section">
                <button className="btn filter-button" onClick={this.openFilettranset}>
                  <i className="fa fa-filter"></i> FILTER </button>
              </div>
            }
            {this.props.deptKey === "UPC Report" &&
              <div className="Filter-button-section">
                <button className="btn filter-button" onClick={this.openUpcFiltertranset}>
                  <i className="fa fa-filter"></i> FILTER </button>
              </div>
            }
          </div>

        </div>
        <div className="col-md-2">
          {this.props.dateListCmpPeriodSeqNo && this.props.dateListCmpPeriodSeqNo !== "" &&
            <b className="shiftDiv">Shift- {this.props.dateListCmpPeriodSeqNo}</b>
          }
        </div>
        <div className="col-md-4">
          <ul className="list-inline table-tab-icons float-right">
            {icons}
            {/* <a className="mr-1">
              <li className="list-inline-item fa fa-building active-tabs" ></li>
            </a>
            <a className="mr-1">
              <li className="list-inline-item fa fa-product-hunt" aria-hidden="true"></li>
            </a>
            <a className="mr-1">
              <li className="list-inline-item fa fa-microchip"></li>
            </a>
            <a className="mr-1">
              <li className="list-inline-item fa fa-cube"></li>
            </a>
            <a className="mr-1">
              <li className="list-inline-item fa fa-database"></li>
            </a>
            <a className="mr-1">
              <li className="list-inline-item fa fa-percent"></li>
            </a>
            <a className="mr-1">
              <li className="list-inline-item fa fa-rocket"></li>
            </a>
            <a className="mr-1">
              <li className="list-inline-item fa fa-gift"></li>
            </a>
            <a className="mr-1">
              <li className="list-inline-item fa fa-recycle"></li>
            </a>
            <a className="mr-1">
              <li className="list-inline-item fa fa-file-text"></li>
            </a> */}
          </ul>

        </div>
      </div>
    )
  }
}
export default TableDetailsHeading;