import React, { Component } from 'react';
import axios from 'axios';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import Table from './table';
import DateList from './datelist';
import HeaderNavSection from './headernavsection';
import TableDetailsHeading from './tabledetailsheading';
import { stores } from '../urlandheaderdata';
import { departments, prefixUrl, poolData } from '../urlandheaderdata';
import Loader from 'react-loader-spinner';
import { Code } from 'react-content-loader';
import Sidebarmenu from './sidebarmenu';
import { findDOMNode } from 'react-dom';
import $ from 'jquery';
import TierReportTable from './tier_report_table';
import PopReportTable from './PoPReportTable';
import FpReportTable from './FpReportTable';
import Table2 from './table2';
import TankReconciliationReportTable from './TankReconciliationReportTable';
import LoyalityReportTable from './LoyalityReportTable';
import moment from 'moment';
import FilterTransetModal from './filterTransetModal';
import Tooltip_sidemenu from './tooltip_sidemenu';
import { truncate } from 'fs';
import _ from 'lodash';
import AddStoreComponent from './AddStoreComponent';
import StoreIssuesComponent from './StoreIssuesComponent';
import SettingsModel from './settingsModelComponent';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import createNotification from '../services/AlertService';

import IdleTimer from 'react-idle-timer';
import UpcFilterTransetModel from './UpcFilterTransetModel';
import UpcReportTable from './upcReportTable';


class Dashboard extends Component {
  tabledata = null;
  datalist = null;
  datesNo = 0;
  constructor(props) {
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.go(1);
    };

    super(props);
    this.idleTimer = null;
    this.renderMenuItemClickData = this.renderMenuItemClickData.bind(this);
    this.renderFilterData = this.renderFilterData.bind(this);
    this.getStoresByUser = this.getStoresByUser.bind(this);
    this.renderReportOfStoreClick = this.renderReportOfStoreClick.bind(this);
    this.onCalenderDateChange = this.onCalenderDateChange.bind(this);
    this.TransetPaginationNextRecords = this.TransetPaginationNextRecords.bind(this);
    this.clearTransetFilterFields = this.clearTransetFilterFields.bind(this);
    this.syncTimeChange = this.syncTimeChange.bind(this);
    this.syncTimeSpinnerOn = this.syncTimeSpinnerOn.bind(this);
    this.syncTimeSpinnerOff = this.syncTimeSpinnerOff.bind(this);
    this.LoadDateListData = this.LoadDateListData.bind(this);
    this.DateListMoveLeft = this.DateListMoveLeft.bind(this);
    this.DateShiftClick = this.DateShiftClick.bind(this);
    this.addnewStoreToMenu = this.addnewStoreToMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.updateStatusStoreList = this.updateStatusStoreList.bind(this);
    this.printTable = this.printTable.bind(this);
    this.onDateBarDateSelect = this.onDateBarDateSelect.bind(this);
    this.DateListMoveRight = this.DateListMoveRight.bind(this);
    this.SetNoOfDatesBasedOnScreen = this.SetNoOfDatesBasedOnScreen.bind(this);
    this.DateListOreintationChange = this.DateListOreintationChange.bind(this);
    this.logOut = this.logOut.bind(this);
    this.TransetShiftPaginationNextRecords = this.TransetShiftPaginationNextRecords.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.renderUpcFilterData = this.renderUpcFilterData.bind(this);
    //this.clearUpcTransetFilterFields = this.clearUpcTransetFilterFields.bind(this);
    this.state = {
      tabledata: [],
      tableheaders: [],
      tablefooters: [],
      tableobjectdata: [],
      periodseq: [],
      storesList: [],
      loadspin: false,
      storeID: null,
      tabletype: null,
      datakey: null,
      tableToDisplay: "table1",
      userID: "",
      selectedDate: "",
      storeAddress: "",
      dateListCmpPeriod: moment().format('YYYY-MM-DD'),
      dateListCmpPeriodSeqNo: "",
      currentStoreID: "",
      currenntTableHeadingIcon: "",
      transetPaginationToken: undefined,
      transetFilterObject: undefined,
      statusText: "No Data",
      currentstore: {},
      dateListObjectData: [],
      fromDate: "",
      toDate: "",
      reportName: "",
      transetNextRecordsClickDate: "",
      hideTransetNextButton: false,
      reportNameForDateBar: "",
      storeName: "",
      datebarLoader: false,
      transetShiftPaginationToken: undefined,
      transetUpcFilterObject: undefined,
    }
    this.user = {}
  }

  //method to load loadperiodseq() at the start of application
  componentDidMount() {
    var isUserSignedIn = sessionStorage.getItem('SignedInConfirmation');
    if (isUserSignedIn == null) {
      this.props.history.push('/');
    }
    this.forceUpdate();
    var userPool = new CognitoUserPool(poolData);
    var currentUser = userPool.getCurrentUser();
    if (currentUser !== null) {
      currentUser.getSession((err, session) => {
        if (err) {
          console.log(err);
          return;
        }

        const token = session.getIdToken().getJwtToken();
        axios.defaults.headers.common['Authorization'] = token;
        //console.log(token);
        this.SetNoOfDatesBasedOnScreen();
        // var user = JSON.parse(localStorage.getItem('userInfo'));
        // this.getStoresByUser(user.userID, user.storeName);
      });
      var that = this;
      currentUser.getUserAttributes(function (err, result) {
        if (err) {
          console.log(err);
          return;
        }
        if (result) {
          that.user.userID = currentUser.username;
          that.user.storeName = result.find(x => x.Name == "given_name").getValue();
          that.getStoresByUser(that.user.userID, that.user.storeName);
        }
      });
    }
    var resizeTimer;
    window.addEventListener("resize", function () {
      this.clearTimeout(resizeTimer);
      resizeTimer = setTimeout(function () {
        that.SetNoOfDatesBasedOnScreen();
        that.DateListOreintationChange();
      }, 250);
    }, false);

    window.onDateChange = function (date) {
      var formattedDate = moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');
      that.onCalenderDateChange(formattedDate);
    }
    if (window.reloadRequired) {
      window.location.reload();
      window.reloadRequired = false;
    }

    $("#close-sidebar").click(function () {
      $(".page-wrapper").removeClass("toggled");
      $("#headerBussinessName").prop("hidden", false);

      setTimeout(function () {
        that.SetNoOfDatesBasedOnScreen();
        that.DateListOreintationChange();
      }, 250);
    });

    $("#show-sidebar").click(function () {
      $(".page-wrapper").addClass("toggled");
      $("#headerBussinessName").prop("hidden", true);

      setTimeout(function () {
        that.SetNoOfDatesBasedOnScreen();
        that.DateListOreintationChange();
      }, 250);
    });

    $(".datelistcarousal,.gj-datepicker,.input-group-append,.btn-outline-secondary,.gj-icon").click(function (event) {
      event.stopPropagation();
    });

    $("#root").click(function (event) {
      window.$datePicker.close()
    });
  }

  SetNoOfDatesBasedOnScreen() {
    let dateBarWidth = $("#viewContainer").width();
    let datebarwidthWithoutArrows = dateBarWidth - ($("#leftArrow").width() * 2) - 20;
    let noOfDateCountToSet = (datebarwidthWithoutArrows / 99);
    //alert((parseInt(noOfDateCountToSet) * 97) +"--"+datebarwidthWithoutArrows)
    // alert(datebarwidthWithoutArrows);
    // alert(parseInt(noOfDateCountToSet) * 99);
    if ((parseInt(noOfDateCountToSet) * 99) > datebarwidthWithoutArrows) {
      this.datesNo = parseInt(noOfDateCountToSet) - 2;
    } else {
      if (99 - (datebarwidthWithoutArrows - (parseInt(noOfDateCountToSet) * 99)) < 15) {
        this.datesNo = parseInt(noOfDateCountToSet);
      } else {
        this.datesNo = parseInt(noOfDateCountToSet) - 1;
      }
    }
    //alert(parseInt( noOfDateCountToSet));
  }

  //this method is to render data in table when department report is clicked
  renderMenuItemClickData(tabletype) {
    document.getElementById("loadoverlay").style.width = "100%";
    this.setState({
      tableheaders: tabletype.headers,
      tablefooters: tabletype.footers,
      loadspin: true,
      tabletype: tabletype,
      datakey: tabletype.key,
      tableToDisplay: tabletype.tableToDisplay,
      currenntTableHeadingIcon: tabletype.dept,
      statusText: "No Data",
      hideTransetNextButton: false
    });
    if (tabletype.dept !== "Transet Report" && tabletype.dept !== "UPC Report") {
      axios.get(tabletype.url + `${this.state.userID}&storeID=${this.state.storeID}&date=${this.state.selectedDate}`).then(res => {
        if (res.status === 200) {
          //commented date bar functionality
          let fromdate = moment(moment(this.state.toDate).subtract(this.datesNo, 'day')).format("YYYY-MM-DD");
          //let fromdate = moment(moment(this.state.toDate).subtract(9, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens 
          let todate = moment(this.state.toDate).format("YYYY-MM-DD");

          this.setState({
            fromDate: fromdate,
            toDate: todate,
            reportName: tabletype.reportName
          })
          this.LoadDateListData(this.state.userID, this.state.storeID, tabletype.reportName, fromdate, todate, null, (x) => { });
          if (res.data !== undefined && res.data.data[0] !== undefined && res.data.data.length > 0) {
            this.setState({
              tableobjectdata: res.data.data[0],
              tabledata: res.data.data[0][tabletype.key],
              dateListCmpPeriod: this.state.dateListCmpPeriod,
              dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
              loadspin: false
            });
            document.getElementById("loadoverlay").style.width = "0%";
          }
          else {
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
            })
            document.getElementById("loadoverlay").style.width = "0%";
          }
          //this.loadData();
        } else {
          this.setState({
            tabledata: undefined,
            tableobjectdata: undefined,
            loadspin: false,
            dateListCmpPeriodSeqNo: "",
          });
          document.getElementById("loadoverlay").style.width = "0%";
        }

      }).catch((error) => {
        console.log(error);
        this.setState({
          tabledata: undefined,
          tableobjectdata: undefined,
          loadspin: false,
          dateListCmpPeriodSeqNo: "",
          statusText: "Error retrieving data, please try later"
        });
        document.getElementById("loadoverlay").style.width = "0%";
      });
    } else if (tabletype.dept === "Transet Report") {
      this.setState({
        transetFilterObject: undefined,
        transetNextRecordsClickDate: this.state.selectedDate
      })
      var postData = {};
      postData.userID = this.state.userID;
      postData.storeID = this.state.storeID;
      postData.period = this.state.selectedDate;
      axios.post(tabletype.url, postData).then(
        res => {
          if (res.status === 200) {
            let fromdate = moment(moment(this.state.toDate).subtract(this.datesNo, 'day')).format("YYYY-MM-DD");
            //let fromdate = moment(moment(this.state.toDate).subtract(9, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
            let todate = moment(this.state.toDate).format("YYYY-MM-DD");

            this.setState({
              fromDate: fromdate,
              toDate: todate,
              reportName: this.state.tabletype.reportName
            })
            this.LoadDateListData(this.state.userID, this.state.storeID, this.state.tabletype.reportName, fromdate, todate, this.state.selectedDate, (transetMaxShift) => {
              if (res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0 && transetMaxShift != "") {
                var dateShiftClickCallObj = {};
                dateShiftClickCallObj.period = this.state.selectedDate;
                dateShiftClickCallObj.periodSeqNum = transetMaxShift;
                var dateShiftClickCallArr = [];
                dateShiftClickCallArr.push(dateShiftClickCallObj);

                this.DateShiftClick(dateShiftClickCallArr);
                //console.log(this.replace(tabletype.key,res.data.data[0]))
                // this.setState({
                //   tableobjectdata: res.data.data[0],
                //   tabledata: res.data.data,
                //   dateListCmpPeriod: this.state.dateListCmpPeriod,
                //   //commented because in transet report data is comming in combination of shifts
                //   // dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
                //   dateListCmpPeriodSeqNo: "",
                //   loadspin: false,
                //   transetPaginationToken: res.data.paginationtoken
                // });
                // document.getElementById("loadoverlay").style.width = "0%";
              }
              else {
                this.setState({
                  tabledata: undefined,
                  tableobjectdata: undefined,
                  loadspin: false,
                  dateListCmpPeriodSeqNo: "",
                })
                document.getElementById("loadoverlay").style.width = "0%";
              }
            });

            //this.loadData();
          } else {
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
            });
            document.getElementById("loadoverlay").style.width = "0%";
          }
        }
      ).catch((error) => {
        console.log(error);
        this.setState({
          tabledata: undefined,
          tableobjectdata: undefined,
          loadspin: false,
          dateListCmpPeriodSeqNo: "",
          statusText: "Error retrieving data, please try later"
        });
        document.getElementById("loadoverlay").style.width = "0%";
      });
    } else if (tabletype.dept === "UPC Report") {
      var postData = {};
      postData.userID = this.state.userID;
      postData.storeID = this.state.storeID;
      postData.period = this.state.selectedDate;
      axios.post(tabletype.url, postData).then(
        res => {
          if (res.status === 200) {
            let fromdate = moment(moment(this.state.toDate).subtract(this.datesNo, 'day')).format("YYYY-MM-DD");
            //let fromdate = moment(moment(this.state.toDate).subtract(9, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
            let todate = moment(this.state.toDate).format("YYYY-MM-DD");

            this.setState({
              fromDate: fromdate,
              toDate: todate,
              reportName: this.state.tabletype.reportName
            })
            this.LoadDateListData(this.state.userID, this.state.storeID, this.state.tabletype.reportName, fromdate, todate, this.state.selectedDate, (transetMaxShift) => {
              if (res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0 && transetMaxShift != "") {
                var dateShiftClickCallObj = {};
                dateShiftClickCallObj.period = this.state.selectedDate;
                dateShiftClickCallObj.periodSeqNum = transetMaxShift;
                var dateShiftClickCallArr = [];
                dateShiftClickCallArr.push(dateShiftClickCallObj);
                this.DateShiftClick(dateShiftClickCallArr);
                //console.log(this.replace(tabletype.key,res.data.data[0]))
                // this.setState({
                //   tableobjectdata: res.data.data[0],
                //   tabledata: res.data.data,
                //   dateListCmpPeriod: this.state.dateListCmpPeriod,
                //   //commented because in transet report data is comming in combination of shifts
                //   // dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
                //   dateListCmpPeriodSeqNo: "",
                //   loadspin: false,
                //   transetPaginationToken: res.data.paginationtoken
                // });
                // document.getElementById("loadoverlay").style.width = "0%";
              }
              else {
                this.setState({
                  tabledata: undefined,
                  tableobjectdata: undefined,
                  loadspin: false,
                  dateListCmpPeriodSeqNo: "",
                })
                document.getElementById("loadoverlay").style.width = "0%";
              }
            });

            //this.loadData();
          } else {
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
            });
            document.getElementById("loadoverlay").style.width = "0%";
          }
        }
      ).catch((error) => {
        console.log(error);
        this.setState({
          tabledata: undefined,
          tableobjectdata: undefined,
          loadspin: false,
          dateListCmpPeriodSeqNo: "",
          statusText: "Error retrieving data, please try later"
        });
        document.getElementById("loadoverlay").style.width = "0%";
      });
    }

  }

  //method is to load period and periodseq data in filter search dropdown
  loadperiodseq() {
    let regioneast1 = 'https://xqp95aptb6.execute-api.us-east-1.amazonaws.com/Prod/v1/Transet/GetTransetPeriodSeqs';
    let regioneast2 = 'https://kjbvi7528f.execute-api.us-east-2.amazonaws.com/Prod/v1/transet/GetTransetPeriodSeqs';
    axios.get(regioneast2).then(
      res => {
        if (res.data !== undefined) {
          this.setState({
            periodseq: res.data
          })
        }
      });
  }

  replace(str, obj) {
    return str.replace(/([^}]+)/g, function (wholematch, firstmatch) {
      var value = firstmatch.split('.').reduce(function (a, b) {
        return a[b];
      }, obj);
      return typeof value !== 'undefined' ? value : wholematch;
    });
  }

  //method to render the last report data on each store click.
  renderReportOfStoreClick(currentStoreID) {
    this.openMenu(currentStoreID);
    if (this.state.currentStoreID != currentStoreID) {
      document.getElementById("loadoverlay").style.width = "100%";
      this.setState({
        currentStoreID: currentStoreID
      })
      if (this.state.tabletype != null) {
        this.setState({
          tableheaders: this.state.tabletype.headers,
          tablefooters: this.state.tabletype.footers,
          loadspin: true,
          tableToDisplay: this.state.tabletype.tableToDisplay,
          statusText: "No Data",
          hideTransetNextButton: false
        });
        if (this.state.tabletype.dept != "Transet Report" && this.state.tabletype.dept !== "UPC Report") {
          axios.get(this.state.tabletype.url + `${this.state.userID}&storeID=${currentStoreID}&date=${this.state.selectedDate}`).then(res => {
            if (res.status === 200) {
              //commented date bar functionality
              let fromdate = moment(moment(this.state.toDate).subtract(this.datesNo, 'day')).format("YYYY-MM-DD");
              // let fromdate = moment(moment(this.state.toDate).subtract(9, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
              let todate = moment(this.state.toDate).format("YYYY-MM-DD");

              this.setState({
                fromDate: fromdate,
                toDate: todate,
                reportName: this.state.tabletype.reportName
              })
              this.LoadDateListData(this.state.userID, currentStoreID, this.state.tabletype.reportName, fromdate, todate, null, (x) => { });
              if (res.data !== undefined && res.data.data[0] !== undefined && res.data.data.length > 0) {
                this.setState({
                  tableobjectdata: res.data.data[0],
                  tabledata: res.data.data[0][this.state.tabletype.key],
                  dateListCmpPeriod: this.state.dateListCmpPeriod,
                  dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
                  loadspin: false
                });
                document.getElementById("loadoverlay").style.width = "0%";
              }
              else {
                this.setState({
                  tabledata: undefined,
                  tableobjectdata: undefined,
                  loadspin: false,
                  dateListCmpPeriodSeqNo: "",
                })
                document.getElementById("loadoverlay").style.width = "0%";
              }
              //this.loadData();
            } else {
              this.setState({
                tabledata: undefined,
                tableobjectdata: undefined,
                loadspin: false,
                dateListCmpPeriodSeqNo: "",
              });
              document.getElementById("loadoverlay").style.width = "0%";
            }
          }).catch((error) => {
            console.log(error);
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
              statusText: "Error retrieving data, please try later"
            });
            document.getElementById("loadoverlay").style.width = "0%";
          });
        } else if (this.state.tabletype.dept === "Transet Report") {
          this.setState({
            transetFilterObject: undefined,
            transetNextRecordsClickDate: this.state.selectedDate
          })
          var postData = {};
          postData.userID = this.state.userID;
          postData.storeID = currentStoreID;
          postData.period = this.state.selectedDate;
          axios.post(this.state.tabletype.url, postData).then(res => {
            if (res.status === 200) {
              let fromdate = moment(moment(this.state.toDate).subtract(this.datesNo, 'day')).format("YYYY-MM-DD");
              // let fromdate = moment(moment(this.state.toDate).subtract(9, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
              let todate = moment(this.state.toDate).format("YYYY-MM-DD");

              this.setState({
                fromDate: fromdate,
                toDate: todate,
                reportName: this.state.tabletype.reportName
              })
              this.LoadDateListData(this.state.userID, currentStoreID, this.state.tabletype.reportName, fromdate, todate, this.state.selectedDate, (transetMaxShift) => {
                if (res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0 && transetMaxShift != "") {
                  var dateShiftClickCallObj = {};
                  dateShiftClickCallObj.period = this.state.selectedDate;
                  dateShiftClickCallObj.periodSeqNum = transetMaxShift;
                  var dateShiftClickCallArr = [];
                  dateShiftClickCallArr.push(dateShiftClickCallObj);

                  this.DateShiftClick(dateShiftClickCallArr);
                  // this.setState({
                  //   tableobjectdata: res.data.data[0],
                  //   tabledata: res.data.data,
                  //   dateListCmpPeriod: this.state.dateListCmpPeriod,
                  //   //commented because in transet report data is comming in combination of shifts
                  //   // dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
                  //   dateListCmpPeriodSeqNo: "",
                  //   loadspin: false,
                  //   transetPaginationToken: res.data.paginationtoken
                  // });
                  // document.getElementById("loadoverlay").style.width = "0%";
                }
                else {
                  this.setState({
                    tabledata: undefined,
                    tableobjectdata: undefined,
                    loadspin: false,
                    dateListCmpPeriodSeqNo: "",
                  })
                  document.getElementById("loadoverlay").style.width = "0%";
                }
              });
              //this.loadData();
            } else {
              this.setState({
                tabledata: undefined,
                tableobjectdata: undefined,
                loadspin: false,
                dateListCmpPeriodSeqNo: "",
              })
              document.getElementById("loadoverlay").style.width = "0%";
            }
          }).catch((error) => {
            console.log(error);
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
              statusText: "Error retrieving data, please try later"
            })
            document.getElementById("loadoverlay").style.width = "0%";
          });
        } else if (this.state.tabletype.dept === "UPC Report") {
          var postData = {};
          postData.userID = this.state.userID;
          postData.storeID = currentStoreID;
          postData.period = this.state.selectedDate;
          axios.post(this.state.tabletype.url, postData).then(
            res => {
              if (res.status === 200) {
                let fromdate = moment(moment(this.state.toDate).subtract(this.datesNo, 'day')).format("YYYY-MM-DD");
                //let fromdate = moment(moment(this.state.toDate).subtract(9, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
                let todate = moment(this.state.toDate).format("YYYY-MM-DD");

                this.setState({
                  fromDate: fromdate,
                  toDate: todate,
                  reportName: this.state.tabletype.reportName
                })
                this.LoadDateListData(this.state.userID, this.state.storeID, this.state.tabletype.reportName, fromdate, todate, this.state.selectedDate, (transetMaxShift) => {
                  if (res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0 && transetMaxShift != "") {
                    var dateShiftClickCallObj = {};
                    dateShiftClickCallObj.period = this.state.selectedDate;
                    dateShiftClickCallObj.periodSeqNum = transetMaxShift;
                    var dateShiftClickCallArr = [];
                    dateShiftClickCallArr.push(dateShiftClickCallObj);
                    this.DateShiftClick(dateShiftClickCallArr);
                    //console.log(this.replace(tabletype.key,res.data.data[0]))
                    // this.setState({
                    //   tableobjectdata: res.data.data[0],
                    //   tabledata: res.data.data,
                    //   dateListCmpPeriod: this.state.dateListCmpPeriod,
                    //   //commented because in transet report data is comming in combination of shifts
                    //   // dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
                    //   dateListCmpPeriodSeqNo: "",
                    //   loadspin: false,
                    //   transetPaginationToken: res.data.paginationtoken
                    // });
                    // document.getElementById("loadoverlay").style.width = "0%";
                  }
                  else {
                    this.setState({
                      tabledata: undefined,
                      tableobjectdata: undefined,
                      loadspin: false,
                      dateListCmpPeriodSeqNo: "",
                    })
                    document.getElementById("loadoverlay").style.width = "0%";
                  }
                });

                //this.loadData();
              } else {
                this.setState({
                  tabledata: undefined,
                  tableobjectdata: undefined,
                  loadspin: false,
                  dateListCmpPeriodSeqNo: "",
                });
                document.getElementById("loadoverlay").style.width = "0%";
              }
            }
          ).catch((error) => {
            console.log(error);
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
              statusText: "Error retrieving data, please try later"
            });
            document.getElementById("loadoverlay").style.width = "0%";
          });
        }
      }
    }
  }

  //method to render the data on selected date in calender
  onCalenderDateChange(dateobj) {
    if (this.state.tabletype != null) {
      if (this.state.selectedDate !== dateobj) {
        this.setState({
          dateListCmpPeriod: dateobj
        })
        document.getElementById("loadoverlay").style.width = "100%";
        this.setState({
          tableheaders: this.state.tabletype.headers,
          tablefooters: this.state.tabletype.footers,
          loadspin: true,
          tableToDisplay: this.state.tabletype.tableToDisplay,
          selectedDate: dateobj,
          statusText: "No Data",
          hideTransetNextButton: false
        });
        if (this.state.tabletype.dept != "Transet Report" && this.state.tabletype.dept !== "UPC Report") {
          axios.get(this.state.tabletype.url + `${this.state.userID}&storeID=${this.state.storeID}&date=${dateobj}`).then(res => {
            if (res.status === 200) {
              //commented date bar functionality
              let fromdate = moment(moment(dateobj).subtract(this.datesNo, 'day')).format("YYYY-MM-DD");
              //let fromdate = moment(moment(dateobj).subtract(9, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
              let todate = moment(dateobj).format("YYYY-MM-DD");

              this.setState({
                fromDate: fromdate,
                toDate: todate,
                reportName: this.state.tabletype.reportName
              })
              this.LoadDateListData(this.state.userID, this.state.storeID, this.state.tabletype.reportName, fromdate, todate, null, (x) => { });
              if (res.data !== undefined && res.data.data[0] !== undefined && res.data.data.length > 0) {
                this.setState({
                  tableobjectdata: res.data.data[0],
                  tabledata: res.data.data[0][this.state.tabletype.key],
                  dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
                  loadspin: false

                });
                document.getElementById("loadoverlay").style.width = "0%";
              }
              else {
                this.setState({
                  tabledata: undefined,
                  tableobjectdata: undefined,
                  loadspin: false,
                  dateListCmpPeriodSeqNo: "",
                })
                document.getElementById("loadoverlay").style.width = "0%";
              }
              //this.loadData();
            } else {
              this.setState({
                tabledata: undefined,
                tableobjectdata: undefined,
                loadspin: false,
                dateListCmpPeriodSeqNo: "",
              })
              document.getElementById("loadoverlay").style.width = "0%";
            }
          }).catch((error) => {
            console.log(error);
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
              statusText: "Error retrieving data, please try later"
            })
            document.getElementById("loadoverlay").style.width = "0%";
          });
        } else if (this.state.tabletype.dept === "Transet Report") {
          this.setState({
            transetFilterObject: undefined,
            transetNextRecordsClickDate: this.state.selectedDate
          })
          var postData = {};
          postData.userID = this.state.userID;
          postData.storeID = this.state.storeID;
          postData.period = dateobj;
          axios.post(this.state.tabletype.url, postData).then(res => {
            if (res.status === 200) {
              let fromdate = moment(moment(dateobj).subtract(this.datesNo, 'day')).format("YYYY-MM-DD");
              // let fromdate = moment(moment(dateobj).subtract(9, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
              let todate = moment(dateobj).format("YYYY-MM-DD");

              this.setState({
                fromDate: fromdate,
                toDate: todate,
                reportName: this.state.tabletype.reportName
              })
              this.LoadDateListData(this.state.userID, this.state.storeID, this.state.tabletype.reportName, fromdate, todate, todate, (transetMaxShift) => {
                if (res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0 && transetMaxShift != "") {
                  var dateShiftClickCallObj = {};
                  dateShiftClickCallObj.period = todate;
                  dateShiftClickCallObj.periodSeqNum = transetMaxShift;
                  var dateShiftClickCallArr = [];
                  dateShiftClickCallArr.push(dateShiftClickCallObj);

                  this.DateShiftClick(dateShiftClickCallArr);
                  // this.setState({
                  //   tableobjectdata: res.data.data[0],
                  //   tabledata: res.data.data,
                  //   //commented because in transet report data is comming in combination of shifts
                  //   // dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
                  //   dateListCmpPeriodSeqNo: "",
                  //   loadspin: false,
                  //   transetPaginationToken: res.data.paginationtoken
                  // });
                  // document.getElementById("loadoverlay").style.width = "0%";
                }
                else {
                  this.setState({
                    tabledata: undefined,
                    tableobjectdata: undefined,
                    loadspin: false,
                    dateListCmpPeriodSeqNo: "",
                  })
                  document.getElementById("loadoverlay").style.width = "0%";
                }
              });
              //this.loadData();
            } else {
              this.setState({
                tabledata: undefined,
                tableobjectdata: undefined,
                loadspin: false,
                dateListCmpPeriodSeqNo: "",
              })
              document.getElementById("loadoverlay").style.width = "0%";
            }
          }).catch((error) => {
            console.log(error);
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
              statusText: "Error retrieving data, please try later"
            })
            document.getElementById("loadoverlay").style.width = "0%";
          });
        } else if (this.state.tabletype.dept === "UPC Report") {
          var postData = {};
          postData.userID = this.state.userID;
          postData.storeID = this.state.storeID;
          postData.period = dateobj;
          axios.post(this.state.tabletype.url, postData).then(
            res => {
              if (res.status === 200) {
                let fromdate = moment(moment(dateobj).subtract(this.datesNo, 'day')).format("YYYY-MM-DD");
                //let fromdate = moment(moment(this.state.toDate).subtract(9, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
                let todate = moment(dateobj).format("YYYY-MM-DD");

                this.setState({
                  fromDate: fromdate,
                  toDate: todate,
                  reportName: this.state.tabletype.reportName
                })
                this.LoadDateListData(this.state.userID, this.state.storeID, this.state.tabletype.reportName, fromdate, todate, todate, (transetMaxShift) => {
                  if (res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0 && transetMaxShift != "") {
                    var dateShiftClickCallObj = {};
                    dateShiftClickCallObj.period = todate;
                    dateShiftClickCallObj.periodSeqNum = transetMaxShift;
                    var dateShiftClickCallArr = [];
                    dateShiftClickCallArr.push(dateShiftClickCallObj);
                    this.DateShiftClick(dateShiftClickCallArr);
                    //console.log(this.replace(tabletype.key,res.data.data[0]))
                    // this.setState({
                    //   tableobjectdata: res.data.data[0],
                    //   tabledata: res.data.data,
                    //   dateListCmpPeriod: this.state.dateListCmpPeriod,
                    //   //commented because in transet report data is comming in combination of shifts
                    //   // dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
                    //   dateListCmpPeriodSeqNo: "",
                    //   loadspin: false,
                    //   transetPaginationToken: res.data.paginationtoken
                    // });
                    // document.getElementById("loadoverlay").style.width = "0%";
                  }
                  else {
                    this.setState({
                      tabledata: undefined,
                      tableobjectdata: undefined,
                      loadspin: false,
                      dateListCmpPeriodSeqNo: "",
                    })
                    document.getElementById("loadoverlay").style.width = "0%";
                  }
                });

                //this.loadData();
              } else {
                this.setState({
                  tabledata: undefined,
                  tableobjectdata: undefined,
                  loadspin: false,
                  dateListCmpPeriodSeqNo: "",
                });
                document.getElementById("loadoverlay").style.width = "0%";
              }
            }
          ).catch((error) => {
            console.log(error);
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
              statusText: "Error retrieving data, please try later"
            });
            document.getElementById("loadoverlay").style.width = "0%";
          });
        }
      }
    }
  }

  onDateBarDateSelect(dateobj) {
    if (this.state.tabletype != null) {
      //if (this.state.selectedDate !== dateobj) {
      this.setState({
        dateListCmpPeriod: dateobj
      })
      var formtDate = moment(dateobj).format("MM/DD/YYYY")
      // var formtDate=moment(moment(dateobj).add(1, 'day')).format("MM/DD/YYYY")
      $(window.$datePicker).val(formtDate);
      document.getElementById("loadoverlay").style.width = "100%";
      this.setState({
        tableheaders: this.state.tabletype.headers,
        tablefooters: this.state.tabletype.footers,
        loadspin: true,
        tableToDisplay: this.state.tabletype.tableToDisplay,
        selectedDate: dateobj,
        statusText: "No Data",
        hideTransetNextButton: false
      });
      if (this.state.tabletype.dept != "Transet Report" && this.state.tabletype.dept !== "UPC Report") {
        axios.get(this.state.tabletype.url + `${this.state.userID}&storeID=${this.state.storeID}&date=${dateobj}`).then(res => {
          if (res.status === 200) {
            //commented date bar functionality
            // let fromdate = moment(moment(dateobj).subtract(14, 'day')).format("YYYY-MM-DD");
            // let todate = moment(dateobj).format("YYYY-MM-DD");
            this.setState({
              //fromDate: fromdate,
              //toDate: todate,
              reportName: this.state.tabletype.reportName
            })
            //this.LoadDateListData(this.state.userID, this.state.storeID, this.state.tabletype.reportName, fromdate, todate);
            if (res.data !== undefined && res.data.data[0] !== undefined && res.data.data.length > 0) {
              this.setState({
                tableobjectdata: res.data.data[0],
                tabledata: res.data.data[0][this.state.tabletype.key],
                dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
                loadspin: false

              });
              document.getElementById("loadoverlay").style.width = "0%";
            }
            else {
              this.setState({
                tabledata: undefined,
                tableobjectdata: undefined,
                loadspin: false,
                dateListCmpPeriodSeqNo: "",
              })
              document.getElementById("loadoverlay").style.width = "0%";
            }
            //this.loadData();
          } else {
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
            })
            document.getElementById("loadoverlay").style.width = "0%";
          }
        }).catch((error) => {
          console.log(error);
          this.setState({
            tabledata: undefined,
            tableobjectdata: undefined,
            loadspin: false,
            dateListCmpPeriodSeqNo: "",
            statusText: "Error retrieving data, please try later"
          })
          document.getElementById("loadoverlay").style.width = "0%";
        });
      } else if (this.state.tabletype.dept === "Transet Report") {
        this.setState({
          transetFilterObject: undefined,
          transetNextRecordsClickDate: this.state.selectedDate
        })
        var postData = {};
        postData.userID = this.state.userID;
        postData.storeID = this.state.storeID;
        postData.period = dateobj;
        axios.post(this.state.tabletype.url, postData).then(res => {
          if (res.status === 200) {
            // let fromdate = moment(moment(dateobj).subtract(14, 'day')).format("YYYY-MM-DD");
            // let todate=moment(dateobj).format("YYYY-MM-DD");
            this.setState({
              // fromDate: fromdate,
              // toDate: todate,
              reportName: this.state.tabletype.reportName
            })
            //this.LoadDateListData(this.state.userID, this.state.storeID, this.state.tabletype.reportName, fromdate, todate);
            var transetMaxShift = "";
            if (dateobj && dateobj != "") {
              var transetDataOfSelectDate = this.state.dateListObjectData.filter(obj => {
                return obj.date === dateobj
              });
              if (transetDataOfSelectDate[0].items) {
                let newTransetDataObj = _.groupBy(transetDataOfSelectDate[0].items, 'periodSeqNum');
                newTransetDataObj = Object.values(newTransetDataObj);
                if (newTransetDataObj && newTransetDataObj.length > 0) {
                  newTransetDataObj = _.sortBy(newTransetDataObj, function (x) {
                    return parseInt(x["periodSeqNum"])
                  }).reverse();
                  transetMaxShift = newTransetDataObj[0][0][0].periodSeqNum;
                }
              }

            }
            if (res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0 && transetMaxShift != "") {
              var dateShiftClickCallObj = {};
              dateShiftClickCallObj.period = dateobj;
              dateShiftClickCallObj.periodSeqNum = transetMaxShift;
              var dateShiftClickCallArr = [];
              dateShiftClickCallArr.push(dateShiftClickCallObj);

              this.DateShiftClick(dateShiftClickCallArr);
              // this.setState({
              //   tableobjectdata: res.data.data[0],
              //   tabledata: res.data.data,
              //   //commented because in transet report data is comming in combination of shifts
              //   // dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
              //   dateListCmpPeriodSeqNo: "",
              //   loadspin: false,
              //   transetPaginationToken: res.data.paginationtoken
              // });
              // document.getElementById("loadoverlay").style.width = "0%";
            }
            else {
              this.setState({
                tabledata: undefined,
                tableobjectdata: undefined,
                loadspin: false,
                dateListCmpPeriodSeqNo: "",
              })
              document.getElementById("loadoverlay").style.width = "0%";
            }
            //this.loadData();
          } else {
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
            })
            document.getElementById("loadoverlay").style.width = "0%";
          }
        }).catch((error) => {
          console.log(error);
          this.setState({
            tabledata: undefined,
            tableobjectdata: undefined,
            loadspin: false,
            dateListCmpPeriodSeqNo: "",
            statusText: "Error retrieving data, please try later"
          })
          document.getElementById("loadoverlay").style.width = "0%";
        });
      } else if (this.state.tabletype.dept === "UPC Report") {
        var postData = {};
        postData.userID = this.state.userID;
        postData.storeID = this.state.storeID;
        postData.period = dateobj;
        axios.post(this.state.tabletype.url, postData).then(res => {
          if (res.status === 200) {
            // let fromdate = moment(moment(dateobj).subtract(14, 'day')).format("YYYY-MM-DD");
            // let todate=moment(dateobj).format("YYYY-MM-DD");
            this.setState({
              // fromDate: fromdate,
              // toDate: todate,
              reportName: this.state.tabletype.reportName
            })
            //this.LoadDateListData(this.state.userID, this.state.storeID, this.state.tabletype.reportName, fromdate, todate);
            var transetMaxShift = "";
            if (dateobj && dateobj != "") {
              var transetDataOfSelectDate = this.state.dateListObjectData.filter(obj => {
                return obj.date === dateobj
              });
              if (transetDataOfSelectDate[0].items) {
                let newTransetDataObj = _.groupBy(transetDataOfSelectDate[0].items, 'periodSeqNum');
                newTransetDataObj = Object.values(newTransetDataObj);
                if (newTransetDataObj && newTransetDataObj.length > 0) {
                  newTransetDataObj = _.sortBy(newTransetDataObj, function (x) {
                    return parseInt(x["periodSeqNum"])
                  }).reverse();
                  transetMaxShift = newTransetDataObj[0][0][0].periodSeqNum;
                }
              }

            }
            if (res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0 && transetMaxShift != "") {
              var dateShiftClickCallObj = {};
              dateShiftClickCallObj.period = dateobj;
              dateShiftClickCallObj.periodSeqNum = transetMaxShift;
              var dateShiftClickCallArr = [];
              dateShiftClickCallArr.push(dateShiftClickCallObj);

              this.DateShiftClick(dateShiftClickCallArr);
              // this.setState({
              //   tableobjectdata: res.data.data[0],
              //   tabledata: res.data.data,
              //   //commented because in transet report data is comming in combination of shifts
              //   // dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
              //   dateListCmpPeriodSeqNo: "",
              //   loadspin: false,
              //   transetPaginationToken: res.data.paginationtoken
              // });
              // document.getElementById("loadoverlay").style.width = "0%";
            }
            else {
              this.setState({
                tabledata: undefined,
                tableobjectdata: undefined,
                loadspin: false,
                dateListCmpPeriodSeqNo: "",
              })
              document.getElementById("loadoverlay").style.width = "0%";
            }
            //this.loadData();
          } else {
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
            })
            document.getElementById("loadoverlay").style.width = "0%";
          }
        }).catch((error) => {
          console.log(error);
          this.setState({
            tabledata: undefined,
            tableobjectdata: undefined,
            loadspin: false,
            dateListCmpPeriodSeqNo: "",
            statusText: "Error retrieving data, please try later"
          })
          document.getElementById("loadoverlay").style.width = "0%";
        });
      }
      //}
    }
  }

  openMenu(storeId) {
    console.log(storeId);
    let atag = $("#sidebardropdown" + storeId).children()[0];
    let prevstoreid = this.state.storeID;
    this.setState({
      storeID: atag.dataset.storeId,
      storeAddress: atag.dataset.storeAddress,
    });

    if ($("#sidebardropdown" + prevstoreid).hasClass("active")) {
      $("#sidebar-submenu" + prevstoreid).slideUp(200);
      $("#sidebardropdown" + prevstoreid).removeClass("active");
      if (prevstoreid != storeId) {
        $("#sidebar-submenu" + storeId).slideDown(200);
        $("#sidebardropdown" + storeId).addClass("active");
      }
    }
    else {
      $("#sidebar-submenu" + storeId).slideDown(200);
      $("#sidebardropdown" + storeId).addClass("active");
    }
  }

  //gets the stores to display in side menu and it renders only at start of aaplication.
  getStoresByUser(usrId, storeName) {
    document.getElementById("loadoverlay").style.width = "100%";
    this.setState({
      loadspin: true
    });
    let url = prefixUrl + `Stores?userID=${usrId}`;
    /*url for new server www.mycstorereports.com*/
    //let url=`https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/Stores?userID=${usrId}`;
    axios.get(url).then(
      res => {
        if (res.data && res.data.length) {
          this.setState({
            storeID: res.data[0].storeID
          })
        }
        this.setState({
          storesList: res.data,
          userID: usrId,
          storeName: storeName,
          loadspin: false
        });
        document.getElementById("loadoverlay").style.width = "0%";
        if (res.data && res.data.length > 0) {
          this.LoadInitialDepartment(res.data[0]);
          let fromdate = moment(moment().subtract(this.datesNo, 'day')).format("YYYY-MM-DD");
          //let fromdate = moment(moment().subtract(9, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
          let todate = moment().format("YYYY-MM-DD");
          this.setState({
            fromDate: fromdate,
            toDate: todate,
            reportName: "Department"
          });
          this.LoadDateListData(usrId, res.data[0].storeID, "Department", fromdate, todate, null, (x) => { });
        }
      }).catch((error) => {
        console.log(error);
        document.getElementById("loadoverlay").style.width = "0%";
        this.setState({
          loadspin: false,
          statusText: "Error retrieving data, please try later"
        })
      });
  }

  LoadInitialDepartment(store) {
    document.getElementById("loadoverlay").style.width = "100%";
    //console.log(moment(moment().subtract(1,'day')).format('YYYY-MM-DD'));
    this.setState({
      tableheaders: departments[0].headers,
      tablefooters: departments[0].footers,
      loadspin: true,
      tableToDisplay: departments[0].tableToDisplay,
      selectedDate: moment(moment().subtract(1, 'day')).format('YYYY-MM-DD'),
      tabletype: departments[0],
      storeAddress: `${store.address.address !== null && store.address.address ? store.address.address + "," : ""} ${store.address.city && store.address.city !== null ? store.address.city + "," : ""} ${store.address.state && store.address.state !== null ? store.address.state : ""} ${store.address.zip && store.address.zip !== null ? store.address.zip : ""}`,
      statusText: "No data",
    });
    this.openMenu(store.storeID);
    axios.get(departments[0].url + `${this.state.userID}&storeID=${store.storeID}&date=${moment(moment().subtract(1, 'day')).format('YYYY-MM-DD')}`).then(result => {
      //  axios.get(departments[0].url + `${this.state.userID}&storeID=${store.storeID}`).then(result => {
      if (result.status === 200) {
        if (result.data !== undefined && result.data.data[0] !== undefined && result.data.data.length > 0) {
          this.setState({
            tableobjectdata: result.data.data[0],
            tabledata: result.data.data[0][departments[0].key],
            dateListCmpPeriodSeqNo: result.data.data[0].periodSeqNum,
            loadspin: false,
          });
          document.getElementById("loadoverlay").style.width = "0%";
        }
        else {
          this.setState({
            tabledata: undefined,
            tableobjectdata: undefined,
            loadspin: false,
            dateListCmpPeriodSeqNo: "",
          })
          document.getElementById("loadoverlay").style.width = "0%";
        }
      } else {
        this.setState({
          tabledata: undefined,
          tableobjectdata: undefined,
          loadspin: false,
          dateListCmpPeriodSeqNo: "",
        });
        document.getElementById("loadoverlay").style.width = "0%";
      }

    }).catch((error) => {
      console.log(error);
      this.setState({
        tabledata: undefined,
        tableobjectdata: undefined,
        loadspin: false,
        dateListCmpPeriodSeqNo: "",
        statusText: "Error retrieving data, please try later"
      })
      document.getElementById("loadoverlay").style.width = "0%";
    });
  }

  //method to load data into table when filter search is done
  renderFilterData(filterdataobj) {
    let regioneast1 = prefixUrl + `Transet/GetTranset`;
    /*url for new server www.mycstorereports.com*/
    //let regioneast1 ='https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/Transet/GetTranset';
    let regioneast2 = 'https://kjbvi7528f.execute-api.us-east-2.amazonaws.com/Prod/v1/transet/GetTranset';

    document.getElementById("loadoverlay").style.width = "100%";
    this.setState({
      tableobjectdata: undefined,
      tabledata: [],
      loadspin: true,
      //selectedDate: filterdataobj.period,
      dateListCmpPeriod: filterdataobj.period,
      transetFilterObject: filterdataobj,
      statusText: "No Data",
      transetNextRecordsClickDate: filterdataobj.period
    });

    filterdataobj.userID = this.state.userID;
    filterdataobj.storeID = this.state.storeID;
    console.log("filter Data");
    console.log(filterdataobj);
    axios.post(regioneast1, filterdataobj)
      .then(
        x => {
          if (x.status === 200) {
            if (x.data !== undefined && x.data.data[0] !== undefined && x.data.data.length > 0) {
              this.setState({
                // tableheaders:departments[0].headers,
                tableobjectdata: x.data.data[0],
                tabledata: x.data.data,
                loadspin: false,
                hideTransetNextButton: false,
                transetPaginationToken: x.data.paginationtoken,
                dateListCmpPeriodSeqNo: "",
                //dateListCmpPeriodSeqNo: x.data.data[0].periodSeqNum,
              });
              document.getElementById("loadoverlay").style.width = "0%";
            } else {
              this.setState({
                // tableheaders:departments[0].headers,
                tableobjectdata: undefined,
                tabledata: undefined,
                loadspin: false,
                //transetPaginationToken: x.data.paginationtoken,
                dateListCmpPeriodSeqNo: "",
              });
              document.getElementById("loadoverlay").style.width = "0%";
            }
          } else {
            this.setState({
              // tableheaders:departments[0].headers,
              tableobjectdata: undefined,
              tabledata: undefined,
              loadspin: false,
              //transetPaginationToken: x.data.paginationtoken,
              dateListCmpPeriodSeqNo: "",
            });
            document.getElementById("loadoverlay").style.width = "0%";
          }

        }
      ).catch((error) => {
        this.setState({
          tableobjectdata: undefined,
          tabledata: undefined,
          loadspin: false,
          dateListCmpPeriodSeqNo: "",
          statusText: "Error retrieving data, please try later"
        });
        document.getElementById("loadoverlay").style.width = "0%";
      });
  }

  TransetPaginationNextRecords() {
    if (this.state.transetPaginationToken !== "{}") {
      this.setState({
        loadspin: true,
        statusText: "No Data"
      });
      document.getElementById("loadoverlay").style.width = "100%";
      var postData = {};
      postData.userID = this.state.userID;
      postData.storeID = this.state.storeID;
      postData.period = this.state.transetNextRecordsClickDate;
      if (this.state.transetFilterObject) {
        postData.amount = this.state.transetFilterObject.amount;
        postData.cardtype = this.state.transetFilterObject.cardtype;
        postData.ticketseq = this.state.transetFilterObject.ticketseq;
        //postData.periodseq = this.state.transetFilterObject.periodseq;
        postData.signature = this.state.transetFilterObject.signature;
      }
      postData.paginationtoken = this.state.transetPaginationToken;
      axios.post(this.state.tabletype.url, postData).then(res => {
        if (res.status === 200) {
          if (res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0) {
            this.setState({
              tableobjectdata: res.data.data[0],
              tabledata: res.data.data,
              //dateListCmpPeriodSeqNo: res.data.data[0].periodSeqNum,
              dateListCmpPeriodSeqNo: "",
              loadspin: false,
              transetPaginationToken: res.data.paginationtoken
            });
            // console.log(res.data.paginationtoken)
            // if (res.data.paginationtoken ==="{}") {
            //   document.getElementById("paginationBtn").disabled = true;
            //   //$("#paginationBtn").attr("disabled", "true");
            // }
            // else {
            //   document.getElementById("paginationBtn").disabled = true;
            //   //$("#paginationBtn").attr("disabled", "false");
            // }
            document.getElementById("loadoverlay").style.width = "0%";
          }
          else {
            this.setState({
              tabledata: undefined,
              tableobjectdata: undefined,
              loadspin: false,
              dateListCmpPeriodSeqNo: "",
            })
            document.getElementById("loadoverlay").style.width = "0%";
          }
          //this.loadData();
        } else {
          this.setState({
            tabledata: undefined,
            tableobjectdata: undefined,
            loadspin: false,
            dateListCmpPeriodSeqNo: "",
          })
          document.getElementById("loadoverlay").style.width = "0%";
        }
      }).catch((error) => {
        console.log(error);
        this.setState({
          tabledata: undefined,
          tableobjectdata: undefined,
          loadspin: false,
          dateListCmpPeriodSeqNo: "",
          statusText: "Error retrieving data, please try later"
        })
        document.getElementById("loadoverlay").style.width = "0%";
      });
    }
  }

  clearTransetFilterFields() {
    $("#filterAmount").val("");
    $("#filterDate").val("");
    $("#filterCard").val("");
    $("#filterSeqNo").val("");
    // this.setState({
    //   dateListCmpPeriodSeqNo:""
    // })
  }

  LoadDateListData(userid, storeid, reportName, FromDate, ToDate, transetSelectedDate, callback) {
    var transetMaxShift = "";
    document.getElementById("loadoverlayForDateBar").style.width = "100%";
    // this.setState({
    //   loadspin:true
    // });
    this.setState({
      datebarLoader: true,
    });
    let url = prefixUrl + `DatesAndShifts`;
    var postData = {};
    postData.userID = userid;
    postData.storeID = storeid;
    postData.fromdate = FromDate;
    postData.tablename = reportName;
    postData.todate = ToDate;

    // let url = prefixUrl + `HorizontalDatesDisplay?userID=${userid}&storeID=${storeid}&tablename=${reportName}&fromdate=${FromDate}&todate=${ToDate}`;
    /*url for new server www.mycstorereports.com*/
    //let url=`https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/HorizontalDatesDisplay?userID=${userid}&storeID=${storeid}&tablename=${reportName}&fromdate=${FromDate}&todate=${ToDate}`;
    axios.post(url, postData).then(
      res => {
        if (res.data && res.data.length > 0) {
          if (reportName === "TransetReport") {
            let transetdata = res.data;
            (transetdata).forEach(obj => {
              let tempArr = _.groupBy(obj.items, 'periodSeqNum');
              tempArr = Object.values(tempArr);
              if (tempArr && tempArr.length > 1) {
                tempArr = _.sortBy(tempArr, function (x) {
                  return parseInt(x["periodSeqNum"])
                }).reverse();
              }
              obj.items = tempArr;
              obj.count = obj.items.length;
            });
            if (transetSelectedDate) {
              var transetDataOfSelectDate = transetdata.filter(obj => {
                return obj.date === transetSelectedDate
              });
              if (transetDataOfSelectDate[0].items) {
                let newTransetDataObj = _.groupBy(transetDataOfSelectDate[0].items, 'periodSeqNum');
                newTransetDataObj = Object.values(newTransetDataObj);
                if (newTransetDataObj && newTransetDataObj.length > 0) {
                  newTransetDataObj = _.sortBy(newTransetDataObj, function (x) {
                    return parseInt(x["periodSeqNum"])
                  }).reverse();
                  transetMaxShift = newTransetDataObj[0][0][0].periodSeqNum;
                }
              }

            }

            // let tempArr = _.groupBy(transetdata[0].items, 'periodSeqNum');
            // console.log(tempArr);
            // tempArr = Object.values(tempArr);
            // console.log(tempArr);
            // transetdata[0].items = tempArr;
            this.setState({
              dateListObjectData: transetdata,
              reportNameForDateBar: "TransetReport",
              //loadspin:false,
              datebarLoader: false
            });
            callback(transetMaxShift);

          }
          else {
            this.setState({
              dateListObjectData: res.data,
              reportNameForDateBar: "",
              //loadspin:false
              datebarLoader: false
            });
          }

          document.getElementById("loadoverlayForDateBar").style.width = "0%";
        } else {
          // this.setState({
          //   loadspin:false
          // });
          document.getElementById("loadoverlayForDateBar").style.width = "0%";
          this.setState({
            datebarLoader: false
          })
        }
      }
    ).catch((error) => {
      console.log(error);
      // this.setState({
      //   loadspin:false
      // });
      document.getElementById("loadoverlayForDateBar").style.width = "0%";
      this.setState({
        datebarLoader: false
      })
    });

  }

  DateListMoveLeft() {

    let fromdate = moment(moment(this.state.toDate).subtract(this.datesNo + 1, 'day')).format("YYYY-MM-DD");
    //let fromdate = moment(moment(this.state.toDate).subtract(10, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
    let todate = moment(moment(this.state.toDate).subtract(1, 'day')).format("YYYY-MM-DD");

    document.getElementById("loadoverlay").style.width = "100%";
    this.setState({
      //loadspin: true,
      fromDate: fromdate,
      toDate: todate
    });

    let url = prefixUrl + `HorizontalDatesDisplay?userID=${this.state.userID}&storeID=${this.state.storeID}&tablename=${this.state.reportName}&fromdate=${fromdate}&todate=${todate}`;
    /*url for new server www.mycstorereports.com*/
    //let url=`https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/HorizontalDatesDisplay?userID=${this.state.userID}&storeID=${this.state.storeID}&tablename=${this.state.reportName}&fromdate=${fromdate}&todate=${todate}`;
    axios.get(url).then(
      res => {
        if (res.data && res.data.length > 0) {
          //console.log(res.data);
          let datearr = this.state.dateListObjectData;
          let newarr = res.data;
          if (this.state.reportName === "TransetReport") {
            let transetdata = res.data;
            (newarr).forEach(obj => {
              let tempArr = _.groupBy(obj.items, 'periodSeqNum');
              tempArr = Object.values(tempArr);
              if (tempArr && tempArr.length > 1) {
                tempArr = _.sortBy(tempArr, function (x) {
                  return parseInt(x["periodSeqNum"])
                }).reverse();
              }
              obj.items = tempArr;
              obj.count = obj.items.length;
            });
          }
          //datearr.push(...((res.data).reverse()));
          //commented below line is for apending new data to old data logic.
          //newarr.push(...(datearr));
          //console.log(datearr);
          // datearr.shift();
          // datearr.push(newarr);
          datearr.pop();
          datearr.splice(0, 0, newarr[0]);
          this.setState({
            dateListObjectData: datearr,
            //loadspin: false
          });
          //console.log(datearr);
          //console.log(this.state.selectedDate);
          if (datearr.filter(e => e.date === this.state.selectedDate).length === 0) {
            this.onDateBarDateSelect(datearr[datearr.length - 1].date);
          } else {
            document.getElementById("loadoverlay").style.width = "0%";
          }
        } else {
          this.setState({
            //loadspin: false
          });
          document.getElementById("loadoverlay").style.width = "0%";
        }
      }
    ).catch((error) => {
      console.log(error);
      this.setState({
        //loadspin: false
      });
      document.getElementById("loadoverlay").style.width = "0%";
    });
  }

  DateListMoveRight() {
    if (this.state.toDate < moment().format("YYYY-MM-DD")) {
      let fromdate = moment(moment(this.state.toDate).subtract(this.datesNo - 1, 'day')).format("YYYY-MM-DD");
      // let fromdate = moment(moment(this.state.toDate).subtract(8, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
      let todate = moment(moment(this.state.toDate).add(1, 'day')).format("YYYY-MM-DD");

      document.getElementById("loadoverlay").style.width = "100%";
      this.setState({
        //loadspin: true,
        fromDate: fromdate,
        toDate: todate
      });

      let url = prefixUrl + `HorizontalDatesDisplay?userID=${this.state.userID}&storeID=${this.state.storeID}&tablename=${this.state.reportName}&fromdate=${todate}&todate=${todate}`;
      /*url for new server www.mycstorereports.com*/
      //let url=`https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/HorizontalDatesDisplay?userID=${this.state.userID}&storeID=${this.state.storeID}&tablename=${this.state.reportName}&fromdate=${todate}&todate=${todate}`;
      axios.get(url).then(
        res => {
          if (res.data && res.data.length > 0) {
            let datearr = this.state.dateListObjectData;
            let newarr = res.data;
            if (this.state.reportName === "TransetReport") {
              let transetdata = res.data;
              (newarr).forEach(obj => {
                let tempArr = _.groupBy(obj.items, 'periodSeqNum');
                tempArr = Object.values(tempArr);
                if (tempArr && tempArr.length > 1) {
                  tempArr = _.sortBy(tempArr, function (x) {
                    return parseInt(x["periodSeqNum"])
                  }).reverse();
                }
                obj.items = tempArr;
                obj.count = obj.items.length;
              });
            }
            //datearr.push(...((res.data).reverse()));
            //newarr.push(...(datearr));
            //console.log(datearr);
            datearr.shift();
            datearr.push(newarr[0]);
            this.setState({
              dateListObjectData: datearr,
              //loadspin: false
            });
            // console.log(datearr);
            // console.log(this.state.selectedDate);
            if (datearr.filter(e => e.date === this.state.selectedDate).length === 0) {
              this.onDateBarDateSelect(datearr[0].date);
            } else {
              document.getElementById("loadoverlay").style.width = "0%";
            }
          } else {
            this.setState({
              //loadspin: false
            });
            document.getElementById("loadoverlay").style.width = "0%";
          }
        }
      ).catch((error) => {
        console.log(error);
        this.setState({
          //loadspin: false
        });
        document.getElementById("loadoverlay").style.width = "0%";
      });
    }
  }

  DateListOreintationChange() {
    let fromdate = moment(moment(this.state.toDate).subtract(this.datesNo, 'day')).format("YYYY-MM-DD");
    //let fromdate = moment(moment(this.state.toDate).subtract(10, 'day')).format("YYYY-MM-DD");//commented this line as instead of nine no setted this.datesNo for responsive screens
    let todate = moment(this.state.toDate).format("YYYY-MM-DD");
    document.getElementById("loadoverlay").style.width = "100%";
    this.setState({
      //loadspin: true,
      fromDate: fromdate,
      toDate: todate
    });

    let url = prefixUrl + `HorizontalDatesDisplay?userID=${this.state.userID}&storeID=${this.state.storeID}&tablename=${this.state.reportName}&fromdate=${fromdate}&todate=${todate}`;
    /*url for new server www.mycstorereports.com*/
    //let url=`https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/HorizontalDatesDisplay?userID=${this.state.userID}&storeID=${this.state.storeID}&tablename=${this.state.reportName}&fromdate=${fromdate}&todate=${todate}`;
    axios.get(url).then(
      res => {
        if (res.data && res.data.length > 0) {
          //console.log(res.data);
          let newarr = res.data;
          if (this.state.reportName === "TransetReport") {
            (newarr).forEach(obj => {
              let tempArr = _.groupBy(obj.items, 'periodSeqNum');
              tempArr = Object.values(tempArr);
              obj.items = tempArr;
              obj.count = obj.items.length;
            });
          }

          this.setState({
            dateListObjectData: newarr,
            //loadspin: false
          });
          if (newarr.filter(e => e.date === this.state.selectedDate).length === 0) {
            this.onDateBarDateSelect(newarr[0].date);
          } else {
            document.getElementById("loadoverlay").style.width = "0%";
          }
        } else {
          this.setState({
            //loadspin: false
          });
          document.getElementById("loadoverlay").style.width = "0%";
        }
      }
    ).catch((error) => {
      console.log(error);
      this.setState({
        //loadspin: false
      });
      document.getElementById("loadoverlay").style.width = "0%";
    });
  }

  DateShiftClick(dateShiftObj) {
    if (this.state.tabletype.dept === "UPC Report") {
      var url = prefixUrl + `GetUpcdata`;
      var postData = {};
      postData.storeID = this.state.storeID;
      if (this.state.tabletype.reportName === "TransetReport") {
        postData.shift = dateShiftObj[0].periodSeqNum;
      }
      var formtDate = moment(dateShiftObj[0].period).format("MM/DD/YYYY");
      // var formtDate=moment(moment(dateobj).add(1, 'day')).format("MM/DD/YYYY")
      $(window.$datePicker).val(formtDate);
      axios.post(url, postData).then(
        res => {
          if (res && res.data !== undefined && res.data.length > 0) {
            this.setState({
              tableobjectdata: res.data,
              tabledata: res.data,
              dateListCmpPeriodSeqNo: dateShiftObj[0].periodSeqNum,
              selectedDate: dateShiftObj[0].period
            });
            this.syncTimeSpinnerOff();
          }
          else {
            this.setState({
              tableobjectdata: undefined,
              tabledata: undefined,
              dateListCmpPeriodSeqNo: dateShiftObj[0].periodSeqNum
            });
            this.syncTimeSpinnerOff();
          }
        }
      ).catch((error) => {
        console.log(error);
        this.setState({
          tableobjectdata: undefined,
          tabledata: undefined,
          statusText: "Error retrieving data, please try later",
          dateListCmpPeriodSeqNo: dateShiftObj[0].periodSeqNum
        });
        this.syncTimeSpinnerOff();
        document.getElementById("loadoverlay").style.width = "0%";
      });
    }
    else {
      var url = prefixUrl + `ShiftData`;
      var postData = {};
      postData.userID = this.state.userID;
      postData.storeID = this.state.storeID;
      postData.tablename = this.state.tabletype.reportName;
      if (this.state.tabletype.reportName === "TransetReport") {
        postData.period = dateShiftObj[0].period;
        postData.shift = dateShiftObj[0].periodSeqNum;
      } else {
        postData.period = dateShiftObj.period;
        postData.shift = dateShiftObj.periodSeqNum;
      }
      var formtDate = moment(postData.period).format("MM/DD/YYYY");
      // var formtDate=moment(moment(dateobj).add(1, 'day')).format("MM/DD/YYYY")
      $(window.$datePicker).val(formtDate);
      axios.post(url, postData).then(
        res => {
          if (this.state.tabletype.reportName === "TransetReport") {

            if (res && res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0) {
              this.setState({
                tableobjectdata: res.data.data,
                tabledata: res.data.data,
                hideTransetNextButton: true,
                dateListCmpPeriodSeqNo: (res.data.data)[0].periodSeqNum,
                selectedDate: (res.data.data)[0].period,
                transetShiftPaginationToken: res.data.paginationtoken
              });
              this.syncTimeSpinnerOff();
            }
            else {
              this.setState({
                tableobjectdata: undefined,
                tabledata: undefined,
                dateListCmpPeriodSeqNo: ""
              });
              this.syncTimeSpinnerOff();
            }
          }
          else {
            if (res && res.data[0] !== undefined && res.data.length > 0) {
              this.setState({
                tableobjectdata: res.data[0],
                tabledata: res.data[0][this.state.tabletype.key],
                dateListCmpPeriodSeqNo: res.data[0].periodSeqNum,
                selectedDate: res.data[0].period
              });
              this.syncTimeSpinnerOff();
            } else {
              this.setState({
                tableobjectdata: undefined,
                tabledata: undefined,
                dateListCmpPeriodSeqNo: ""
              });
              this.syncTimeSpinnerOff();
            }
          }

        }
      );
    }
    // console.log(dateShiftObj[this.state.tabletype.key])
    // if (this.state.tabletype.reportName === "TransetReport") {
    //   let tempArr = [];
    //   //tempArr.push(dateShiftObj);
    //   this.setState({
    //     tableobjectdata: dateShiftObj,
    //     tabledata: dateShiftObj,
    //     hideTransetNextButton: true,
    //     dateListCmpPeriodSeqNo: dateShiftObj[0].periodSeqNum,
    //     selectedDate: dateShiftObj[0].period
    //   });
    // } else {
    //   this.setState({
    //     tableobjectdata: dateShiftObj,
    //     tabledata: dateShiftObj[this.state.tabletype.key],
    //     dateListCmpPeriodSeqNo: dateShiftObj.periodSeqNum,
    //     selectedDate: dateShiftObj.period
    //   });
    // }
    //this.syncTimeSpinnerOff();
  }

  TransetShiftPaginationNextRecords() {
    if (this.state.transetShiftPaginationToken !== "{}") {
      this.syncTimeSpinnerOn();
      var url = prefixUrl + `ShiftData`;
      var postData = {};
      postData.userID = this.state.userID;
      postData.storeID = this.state.storeID;
      postData.tablename = this.state.tabletype.reportName;
      postData.period = this.state.selectedDate;
      postData.shift = this.state.dateListCmpPeriodSeqNo;
      postData.paginationtoken = this.state.transetShiftPaginationToken;
      axios.post(url, postData).then(
        res => {
          if (res && res.data !== undefined && res.data.data !== undefined && res.data.data.length > 0) {
            this.setState({
              tableobjectdata: res.data.data,
              tabledata: res.data.data,
              hideTransetNextButton: true,
              dateListCmpPeriodSeqNo: (res.data.data)[0].periodSeqNum,
              selectedDate: (res.data.data)[0].period,
              transetShiftPaginationToken: res.data.paginationtoken
            });
            this.syncTimeSpinnerOff();
          }
          else {
            this.setState({
              tableobjectdata: undefined,
              tabledata: undefined,
              dateListCmpPeriodSeqNo: ""
            });
            this.syncTimeSpinnerOff();
          }
        });
    }
  }

  logOut() {
    var userPool = new CognitoUserPool(poolData);
    let cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          createNotification('error', err);
          //alert(err);
          return;
        }
        //console.log('session validity: ' + session.isValid());
      });
      cognitoUser.signOut();
      sessionStorage.clear();
      this.props.history.push('/');
    }
  }

  openStoreissue() {
    document.getElementById("myIssue").style.width = "100%";
  }
  closeStoreissue() {
    document.getElementById("myIssue").style.width = "0%";
  }

  openSetting() {
    document.getElementById("mySetting").style.width = "100%";
  }
  closeSetting() {
    document.getElementById("mySetting").style.width = "0%";
  }

  syncTimeChange(store) {
    var index = this.state.storesList.findIndex(s => s.storeID === store.storeID);
    this.state.storesList.splice(index, 1, store)
    this.setState({
      storesList: [...this.state.storesList]
    })
  }
  syncTimeSpinnerOn() {
    document.getElementById("loadoverlay").style.width = "100%";
    this.setState({
      loadspin: true
    })
  }
  syncTimeSpinnerOff() {
    document.getElementById("loadoverlay").style.width = "0%";
    this.setState({
      loadspin: false
    })
  }
  addnewStoreToMenu(newstore) {
    this.setState({
      storesList: [...this.state.storesList, newstore]
    })
  }

  updateStatusStoreList() {
    this.setState({
      storesList: this.state.storesList
    })
  }

  printTable(reportName) {
    var content;
    var htmlToPrint;
    if (reportName === "Department Report") {
      content = document.getElementById("printTable");
      htmlToPrint = '' +
        '<style type="text/css">' +
        'table td:nth-child(1),table th:nth-child(1) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(2),table th:nth-child(2) {' +
        'text-align: left;' +
        '}' +
        'table td:nth-child(3),table th:nth-child(3) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(4),table th:nth-child(4) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(5),table th:nth-child(5) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(6),table th:nth-child(6) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(7),table th:nth-child(7) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(8),table th:nth-child(8) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(9),table th:nth-child(9) {' +
        'text-align: right;' +
        '}' +
        // '.table-footer-row td:nth-child(1) {'+
        //   'text-align: left;'+
        // '}'+
        'table td {' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table th{' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table tr{' +
        'border: 1px solid #000;' +
        '}' +
        'table{' +
        'border-collapse: collapse;' +
        'width: 100%;' +
        'max-width: 100%;' +
        'margin-bottom: 1rem;' +
        'background-color: transparent;' +
        '}' +
        '@page { size: auto;  margin: 0mm; }' +
        '</style>';
    } else if (reportName === "Network Product Report") {
      content = document.getElementById("printTable");
      htmlToPrint = '' +
        '<style type="text/css">' +
        'table td:nth-child(1),table th:nth-child(1) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(3),table th:nth-child(3) {' +
        'text-align: left;' +
        '}' +
        'table td:nth-child(7),table th:nth-child(7) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(8),table th:nth-child(8) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(9),table th:nth-child(9) {' +
        'text-align: right;' +
        '}' +
        'table td {' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table th{' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table tr{' +
        'border: 1px solid #000;' +
        '}' +
        'table{' +
        'border-collapse: collapse;' +
        'width: 100%;' +
        'max-width: 100%;' +
        'margin-bottom: 1rem;' +
        'background-color: transparent;' +
        '}' +
        '@page { size: auto;  margin: 0mm; }' +
        '</style>';
    } else if (reportName === "Network card Report") {
      content = document.getElementById("printTable");
      htmlToPrint = '' +
        '<style type="text/css">' +
        'table td:nth-child(1),table th:nth-child(1) {' +
        'text-align: left;' +
        '}' +
        'table td:nth-child(4),table th:nth-child(4) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(5),table th:nth-child(5) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(6),table th:nth-child(6) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(7),table th:nth-child(7) {' +
        'text-align: right;' +
        '}' +
        'table td {' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table th{' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table tr{' +
        'border: 1px solid #000;' +
        '}' +
        'table{' +
        'border-collapse: collapse;' +
        'width: 100%;' +
        'max-width: 100%;' +
        'margin-bottom: 1rem;' +
        'background-color: transparent;' +
        '}' +
        '@page { size: auto;  margin: 0mm; }' +
        '</style>';
    } else if (reportName === "Tank Report") {
      content = document.getElementById("printTable");
      htmlToPrint = '' +
        '<style type="text/css">' +
        'table td:nth-child(1),table th:nth-child(1) {' +
        'text-align: left;' +
        '}' +
        'table td:nth-child(5),table th:nth-child(5) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(6),table th:nth-child(6) {' +
        'text-align: right;' +
        '}' +
        'table td {' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table th{' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table tr{' +
        'border: 1px solid #000;' +
        '}' +
        'table{' +
        'border-collapse: collapse;' +
        'width: 100%;' +
        'max-width: 100%;' +
        'margin-bottom: 1rem;' +
        'background-color: transparent;' +
        '}' +
        '@page { size: auto;  margin: 0mm; }' +
        '</style>';
    } else if (reportName === "Tier/PR Report") {
      content = document.getElementById("printTierPRTable");
      htmlToPrint = '' +
        '<style type="text/css">' +
        'table td:nth-child(1),table th:nth-child(1) {' +
        'text-align: left;' +
        '}' +
        'table td:nth-child(5),table th:nth-child(5) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(6),table th:nth-child(6) {' +
        'text-align: right;' +
        '}' +
        'table td {' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table th{' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table tr{' +
        'border: 1px solid #000;' +
        '}' +
        'table{' +
        'border-collapse: collapse;' +
        'width: 100%;' +
        'max-width: 100%;' +
        'margin-bottom: 1rem;' +
        'background-color: transparent;' +
        '}' +
        '@page { size: auto;  margin: 0mm; }' +
        '</style>';
    } else if (reportName === "POP Discount Report") {
      content = document.getElementById("printPOPTable");
      htmlToPrint = '' +
        '<style type="text/css">' +
        'table td:nth-child(1),table th:nth-child(1) {' +
        'text-align: left;' +
        '}' +
        'table td:nth-child(5),table th:nth-child(5) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(6),table th:nth-child(6) {' +
        'text-align: right;' +
        '}' +
        'table td {' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table th{' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table tr{' +
        'border: 1px solid #000;' +
        '}' +
        'table{' +
        'border-collapse: collapse;' +
        'width: 100%;' +
        'max-width: 100%;' +
        'margin-bottom: 1rem;' +
        'background-color: transparent;' +
        '}' +
        '@page { size: auto;  margin: 0mm; }' +
        '</style>';
    } else if (reportName === "FP Dispenser Report") {
      content = document.getElementById("printFPTable");
      htmlToPrint = '' +
        '<style type="text/css">' +
        'table td:nth-child(1),table th:nth-child(1) {' +
        'text-align: left;' +
        '}' +
        'table td:nth-child(5),table th:nth-child(5) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(6),table th:nth-child(6) {' +
        'text-align: right;' +
        '}' +
        'table td {' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table th{' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table tr{' +
        'border: 1px solid #000;' +
        '}' +
        'table{' +
        'border-collapse: collapse;' +
        'width: 100%;' +
        'max-width: 100%;' +
        'margin-bottom: 1rem;' +
        'background-color: transparent;' +
        '}' +
        '@page { size: auto;  margin: 0mm; }' +
        '</style>';
    } else if (reportName === "Loyalty Report") {
      content = document.getElementById("printLoyalityTable");
      htmlToPrint = '' +
        '<style type="text/css">' +
        'table td:nth-child(1),table th:nth-child(1) {' +
        'text-align: left;' +
        '}' +
        'table td:nth-child(4),table th:nth-child(4) {' +
        'text-align: right;' +
        '}' +
        'table td {' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table th{' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table tr{' +
        'border: 1px solid #000;' +
        '}' +
        'table{' +
        'border-collapse: collapse;' +
        'width: 100%;' +
        'max-width: 100%;' +
        'margin-bottom: 1rem;' +
        'background-color: transparent;' +
        '}' +
        '@page { size: auto;  margin: 0mm; }' +
        '</style>';
      //var buttonslist=content.getElementsByTagName("button");
    } else if (reportName === "Tank Reconciliation") {
      content = document.getElementById("printTankReconcilliationTable");
      htmlToPrint = '' +
        '<style type="text/css">' +
        'table td:nth-child(1),table th:nth-child(1) {' +
        'text-align: left;' +
        '}' +
        'table td:nth-child(3),table th:nth-child(3) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(4),table th:nth-child(4) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(5),table th:nth-child(5) {' +
        'text-align: right;' +
        '}' +
        'table td:nth-child(6),table th:nth-child(6) {' +
        'text-align: right;' +
        '}' +
        'table td {' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table th{' +
        //'text-align: left;'+
        'padding-right:15px' +
        '}' +
        'table tr{' +
        'border: 1px solid #000;' +
        '}' +
        'table{' +
        'border-collapse: collapse;' +
        'width: 100%;' +
        'max-width: 100%;' +
        'margin-bottom: 1rem;' +
        'background-color: transparent;' +
        '}' +
        '@page { size: auto;  margin: 0mm; }' +
        '</style>';
    }

    var printdate = this.state.selectedDate ? this.state.selectedDate : this.state.dateListCmpPeriod;
    var pageheader = '<div style=text-align:center>' +
      '<span style=float:left>' + printdate + '</span>' +
      '<span>' + reportName + '</span>' +
      '<span style=float:right>Shift-' + this.state.dateListCmpPeriodSeqNo + '</span>' +
      '</div>'
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write('<br/>');
    pri.document.write(pageheader);
    pri.document.write('<br/>');
    pri.document.write(htmlToPrint);
    pri.document.write(content.outerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  onIdle(e) {
    //console.log(this.idleTimer.getRemainingTime())
    if (this.idleTimer.getRemainingTime() == 0) {
      alert("Your session is expired");
      sessionStorage.clear();
      this.props.history.push('/');
    }
  }

  renderUpcFilterData(UpcfilterObject) {
    var url = prefixUrl + `GetUpcdata`;
    document.getElementById("loadoverlay").style.width = "100%";
    this.setState({
      tableobjectdata: undefined,
      tabledata: [],
      loadspin: true,
      //selectedDate: filterdataobj.period,
      dateListCmpPeriod: "",
      transetUpcFilterObject: UpcfilterObject,
      statusText: "No Data",
    });

    UpcfilterObject.storeID = this.state.storeID;
    console.log("upcfilter Data");
    console.log(UpcfilterObject);
    axios.post(url, UpcfilterObject)
      .then(
        x => {
          if (x.status === 200) {
            if (x.data !== undefined && x.data.length > 0) {
              this.setState({
                // tableheaders:departments[0].headers,
                tableobjectdata: x.data,
                tabledata: x.data,
                loadspin: false,
                dateListCmpPeriodSeqNo: "",
                selectedDate: ""
                //dateListCmpPeriodSeqNo: x.data.data[0].periodSeqNum,
              });
              document.getElementById("loadoverlay").style.width = "0%";
            } else {
              this.setState({
                // tableheaders:departments[0].headers,
                tableobjectdata: undefined,
                tabledata: undefined,
                loadspin: false,
                //transetPaginationToken: x.data.paginationtoken,
                dateListCmpPeriodSeqNo: "",
                selectedDate: ""
              });
              document.getElementById("loadoverlay").style.width = "0%";
            }
          } else {
            this.setState({
              // tableheaders:departments[0].headers,
              tableobjectdata: undefined,
              tabledata: undefined,
              loadspin: false,
              //transetPaginationToken: x.data.paginationtoken,
              dateListCmpPeriodSeqNo: "",
              selectedDate: ""
            });
            document.getElementById("loadoverlay").style.width = "0%";
          }

        }
      ).catch((error) => {
        this.setState({
          tableobjectdata: undefined,
          tabledata: undefined,
          loadspin: false,
          dateListCmpPeriodSeqNo: "",
          selectedDate: "",
          statusText: "Error retrieving data, please try later"
        });
        document.getElementById("loadoverlay").style.width = "0%";
      });
  }

  // clearUpcTransetFilterFields() {
  //   $("#upcDepartment").val("");
  //   $("#upc").val("");
  //   $("#upcfromDate").val("");
  //   $("#upctoDate").val("");
  // }

  render() {
    // const loadTableOrSpin=this.state.loadspin?<div id="loader"><Code/></div>:<Table tabledata={this.state.tabledata} tableheaders={this.state.tableheaders}/>;
    const loadTable1OrSpin = this.state.loadspin ? <div id="loader"><Loader type="Oval" color="#D3D3D3" height={50} width={50} /></div> : <Table tabledata={this.state.tabledata} tableheaders={this.state.tableheaders} datakey={this.state.datakey} tablefooters={this.state.tablefooters}
      tableobjectdata={this.state.tableobjectdata} storesList={this.state.storesList} transetNextClick={this.TransetPaginationNextRecords} statusText={this.state.statusText} hideTransetNextButton={this.state.hideTransetNextButton} transetShiftNextClick={this.TransetShiftPaginationNextRecords} />;
    const loadTierReportTableOrSpin = this.state.loadspin ? <div id="loader"><Loader type="Oval" color="#D3D3D3" height={50} width={50} /></div> : <TierReportTable tabletype={this.state.tabletype} tableobjectdata={this.state.tableobjectdata} statusText={this.state.statusText} />;
    const loadPopReportTableOrSpin = this.state.loadspin ? <div id="loader"><Loader type="Oval" color="#D3D3D3" height={50} width={50} /></div> : <PopReportTable tabletype={this.state.tabletype} tableobjectdata={this.state.tableobjectdata} statusText={this.state.statusText} />;
    const loadFpReportTableOrSpin = this.state.loadspin ? <div id="loader"><Loader type="Oval" color="#D3D3D3" height={50} width={50} /></div> : <FpReportTable tabletype={this.state.tabletype} tableobjectdata={this.state.tableobjectdata} statusText={this.state.statusText} />;
    const loadTable2OrSpin = this.state.loadspin ? <div id="loader"><Loader type="Oval" color="#D3D3D3" height={50} width={50} /></div> : <Table2 tabletype={this.state.tabletype} tableobjectdata={this.state.tableobjectdata} statusText={this.state.statusText} />;
    const loadTankRecReportTableOrSpin = this.state.loadspin ? <div id="loader"><Loader type="Oval" color="#D3D3D3" height={50} width={50} /></div> : <TankReconciliationReportTable tabletype={this.state.tabletype} tableobjectdata={this.state.tableobjectdata} statusText={this.state.statusText} />;
    const loadLoyalityReportTableOrSpin = this.state.loadspin ? <div id="loader"><Loader type="Oval" color="#D3D3D3" height={50} width={50} /></div> : <LoyalityReportTable tabletype={this.state.tabletype} tableobjectdata={this.state.tableobjectdata} statusText={this.state.statusText} />;
    const loadUpcReportTableOrSpin = this.state.loadspin ? <div id="loader"><Loader type="Oval" color="#D3D3D3" height={50} width={50} /></div> : <UpcReportTable tabletype={this.state.tabletype} tableobjectdata={this.state.tableobjectdata} statusText={this.state.statusText} />;

    let tableToDisplay = "";
    if (this.state.tableToDisplay === "table1") {
      tableToDisplay = loadTable1OrSpin
    }
    else if (this.state.tableToDisplay === "TierReportTable") {
      tableToDisplay = loadTierReportTableOrSpin
    }
    else if (this.state.tableToDisplay === "PopReportTable") {
      tableToDisplay = loadPopReportTableOrSpin
    }
    else if (this.state.tableToDisplay === "FpReportTable") {
      tableToDisplay = loadFpReportTableOrSpin
    }
    else if (this.state.tableToDisplay === "table2") {
      tableToDisplay = loadTable2OrSpin
    }
    else if (this.state.tableToDisplay === "TankReconciliationReportTable") {
      tableToDisplay = loadTankRecReportTableOrSpin
    }
    else if (this.state.tableToDisplay === "LoyalityReportTable") {
      tableToDisplay = loadLoyalityReportTableOrSpin
    }
    else if (this.state.tableToDisplay === "upcReportTable") {
      tableToDisplay = loadUpcReportTableOrSpin
    }

    return (
      <div >
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onIdle={this.onIdle}
          timeout={1000 * 60 * 60} />
        <div className="page-wrapper chiller-theme toggled">
          {/* sidebar falls here   */}
          {/* <SidebarMenu menuItemClick={this.renderMenuItemClickData}/> */}
          <Sidebarmenu menuItemClick={this.renderMenuItemClickData} storesList={this.state.storesList} renderReportOfStoreClick={this.renderReportOfStoreClick} deptKey={this.state.tabletype == null ? "" : this.state.tabletype.dept} syncTimeChange={this.syncTimeChange} syncTimeSpinnerOn={this.syncTimeSpinnerOn} syncTimeSpinnerOff={this.syncTimeSpinnerOff}
            updateStatusStoreList={this.updateStatusStoreList} storeName={this.state.storeName} />
          <main className="page-content">
            {/* header nav section comes here */}
            <HeaderNavSection storesList={this.state.storesList} storeID={this.state.storeID} userID={this.state.userID} storeAddress={this.state.storeAddress} syncTimeChange={this.syncTimeChange} syncTimeSpinnerOn={this.syncTimeSpinnerOn} syncTimeSpinnerOff={this.syncTimeSpinnerOff} storeName={this.state.storeName} logOut={this.logOut} />
            <div className="container-fluid page-content-inner">
              {/* datelist falls here */}
              <DateList period={this.state.dateListCmpPeriod} periodSeqNum={this.state.dateListCmpPeriodSeqNo} clearTransetFilterFields={this.clearTransetFilterFields} dateListObjectData={this.state.dateListObjectData} DateListMoveLeft={this.DateListMoveLeft} DateShiftClick={this.DateShiftClick} syncTimeSpinnerOn={this.syncTimeSpinnerOn} reportNameForDateBar={this.state.reportNameForDateBar} datebarLoader={this.state.datebarLoader} onDateBarDateSelect={this.onDateBarDateSelect} datebarSelectDate={this.state.selectedDate} DateListMoveRight={this.DateListMoveRight} disableCheckDate={this.state.toDate} />
              <div className="Table-detials-heading">
                {/* table details fall here */}
                {/* <TableDetailsHeading periodseq={this.state.periodseq} clickfilterdata={(x) => this.renderFilterData(x)} deptKey={this.state.tabletype == null ? "" : this.state.tabletype.dept} iconClickFun={this.renderMenuItemClickData}/> */}
                <TableDetailsHeading periodseq={this.state.periodseq} deptKey={this.state.tabletype == null ? "" : this.state.tabletype.dept} iconClickFun={this.renderMenuItemClickData} printTable={this.printTable} dateListCmpPeriodSeqNo={this.state.dateListCmpPeriodSeqNo} storeID={this.state.storeID} />
                <div className="row m-0">
                  <div className="col-md-12">
                    <div className="Table-card">
                      <div className="table-content">
                        <div className="table-responsive">
                          {/* teble falls here */}
                          {/* <Table tabledata={this.state.tabledata} tableheaders={this.state.tableheaders}/> */}
                          {tableToDisplay}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          {/* <!-- page-content" --> */}
          <div className="login-page-footer-text d-none d-sm-none d-md-block">
            <p className="float-left">Object Source INC © 2019.</p>
            <p className="float-right">Powered by Object Source INC</p>
          </div>
        </div>
        {/* <div id="myIssue" className="overlay" style={{ top: 50 }}>
          <a href="javascript:void(0)" className="closebtn store-issue-button" onClick={this.closeStoreissue}><i className="fa fa-times"
            aria-hidden="true"></i></a>
          <div className="overlay-content">
            <iframe src="issue-dialog-model.html"></iframe>
          </div>
        </div> */}

        {/* <div id="mySetting" className="overlay" style={{ top: 50 }}>
          <a href="javascript:void(0)" className="closebtn" onClick={this.closeSetting}><i className="fa fa-times"
            aria-hidden="true"></i></a>
          <div className="overlay-content">
            <iframe className="setting-model-iframe" src="setting-model.html"></iframe>
          </div>
        </div> */}
        <AddStoreComponent addnewStoreToMenu={this.addnewStoreToMenu} userID={this.state.userID} syncTimeSpinnerOn={this.syncTimeSpinnerOn} syncTimeSpinnerOff={this.syncTimeSpinnerOff} />
        <FilterTransetModal clickFilterData={(filterObject) => this.renderFilterData(filterObject)} />
        <StoreIssuesComponent storesList={this.state.storesList} />
        <SettingsModel syncTimeSpinnerOn={this.syncTimeSpinnerOn} syncTimeSpinnerOff={this.syncTimeSpinnerOff} />
        <div id="loadoverlay" className="loadspinOverlay"></div>
        <div id="loadoverlayForDateBar" className="loadspinOverlay"></div>
        <iframe id="ifmcontentstoprint" style={{ height: "0px", width: "0px", position: "absolute" }}></iframe>
        <NotificationContainer />
        <UpcFilterTransetModel storeID={this.state.currentStoreID} clickUpcFilterData={(UpcfilterObject) => this.renderUpcFilterData(UpcfilterObject)} />
      </div>
    );
  }
}
export default Dashboard;