import React, { Component } from 'react';
import _ from 'lodash';
import $ from 'jquery';

class FpReportTable extends Component {
    constructor() {
        super();
        this.state = {
            tableobjectdata: undefined,
            sortKey: "",
            temp: ""
        }
    }
    componentDidMount() {
        this.setState({
            tableobjectdata: this.props.tableobjectdata
        });
        if (this.props.tableobjectdata) {
            setTimeout(() => {
                this.arraySort("nothing to send", this.props.tabletype.headers[0].ColumnName);
                $("#firstCol").removeAttr("class");
                $("#firstCol").attr("class", "caretclass fa fa-caret-down");
            }, 1000)
        }
    }
    arraySort(e, key) {
        //$(".caretclass").removeAttr("class");
        $(".caretclass").attr("class", "caretclass fa fa-caret-right");
        if (this.state.sortKey !== key) {
            this.setState({
                sortKey: key
            })
            if (this.state.tableobjectdata &&
                this.state.tableobjectdata.fuelPositions &&
                this.state.tableobjectdata.fuelPositions.length > 0
            ) {
                $(e.target).removeAttr("class");
                $(e.target).attr("class", "caretclass fa fa-caret-down");
                this.state.tableobjectdata.fuelPositions.map(fuelPosition => {
                    if (fuelPosition.products && fuelPosition.products.length > 0) {
                        fuelPosition.products = _.sortBy(fuelPosition.products, function (x) {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        });
                        this.setState({
                            temp: "a"
                        })
                    }
                })
            }
            if (this.state.tableobjectdata &&
                this.state.tableobjectdata.totals &&
                this.state.tableobjectdata.totals.length > 0
            ) {
                $(e.target).removeAttr("class");
                $(e.target).attr("class", "caretclass fa fa-caret-down");
                this.state.tableobjectdata.totals.map(total => {
                    if (total.products && total.products.length > 0) {
                        total.products = _.sortBy(total.products, function (x) {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        });

                    }
                })
            }
        } else {
            this.setState({
                sortKey: ""
            });
            if (this.state.tableobjectdata &&
                this.state.tableobjectdata.fuelPositions &&
                this.state.tableobjectdata.fuelPositions.length > 0
            ) {
                $(e.target).removeAttr("class");
                $(e.target).attr("class", "caretclass fa fa-caret-up");
                this.state.tableobjectdata.fuelPositions.map(fuelPosition => {
                    if (fuelPosition.products && fuelPosition.products.length > 0) {
                        fuelPosition.products = _.sortBy(fuelPosition.products, function (x) {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        }).reverse();
                    }
                });
            }
            if (this.state.tableobjectdata &&
                this.state.tableobjectdata.totals &&
                this.state.tableobjectdata.totals.length > 0
            ) {
                $(e.target).removeAttr("class");
                $(e.target).attr("class", "caretclass fa fa-caret-up");
                this.state.tableobjectdata.totals.map(total => {
                    if (total.products && total.products.length > 0) {
                        total.products = _.sortBy(total.products, function (x) {
                            if (!isNaN(x[key]))
                                return parseInt(x[key]);

                            return x[key];
                        }).reverse();
                    }
                })
            }
        }
    }
    render() {
        const statusText = this.props.statusText;
        const noDataCss = {
            'paddingTop': '20%',
            'textAlign': 'center'
        }
        const displayHeaders = this.props.tabletype.headers !== undefined && this.props.tabletype.headers.length > 0 && this.props.tabletype.headers.map((header, i) =>
            i === 0 ?
                <th scope="col" className={header.TextAlign}>
                    {header.DisplayText}
                    <span>&nbsp;<i id="firstCol" onClick={(e) => this.arraySort(e, header.ColumnName)} className="caretclass fa fa-caret-right"></i></span>
                </th>
                :
                header.DisplayText === "" ? <th scope="col"></th> :
                    <th scope="col" className={header.TextAlign}>
                        {header.DisplayText}
                        <span>&nbsp;<i onClick={(e) => this.arraySort(e, header.ColumnName)} className="caretclass fa fa-caret-right"></i></span>
                    </th>
        );

        const tabledata = this.state.tableobjectdata === undefined ? undefined :
            this.state.tableobjectdata.fuelPositions &&
            this.state.tableobjectdata.fuelPositions.length > 0 &&
            this.state.tableobjectdata.fuelPositions &&
            this.state.tableobjectdata.fuelPositions.length > 0 &&
            this.state.tableobjectdata.fuelPositions.map(fuelPosition =>
                <div>
                    <tr>
                        <td className="textAlignLeft"><b>FUELING POINT #{fuelPosition.position}</b></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    {
                        fuelPosition.products && fuelPosition.products.length > 0 &&
                        fuelPosition.products.map((product, i) =>
                            <tr className={(i % 2 === 0) ? "table-row-bg" : ""}>
                                <td className="textAlignLeft">{product.name}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="textAlignRight">{product.volume}</td>
                                <td className="textAlignRight">{product.amount}</td>
                            </tr>
                        )
                    }
                    <tr className="table-footer-row table-body-content">
                        <td className="textAlignLeft">Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{fuelPosition.total.volume}</td>
                        <td className="textAlignRight">{fuelPosition.total.amount}</td>
                    </tr>
                </div>
            );
        const totalTableData = this.state.tableobjectdata === undefined ? <tr></tr> :
            <div>
                <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td className="textAlignLeft"><b>PRODUCT TOTALS</b></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {
                    this.state.tableobjectdata.totals &&
                    this.state.tableobjectdata.totals.products &&
                    this.state.tableobjectdata.totals.products.length > 0 &&
                    this.state.tableobjectdata.totals.products.map((product, i) =>
                        <tr className={(i % 2 === 0) ? "table-row-bg" : ""}>
                            <td className="textAlignLeft">{product.name}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="textAlignRight">{product.volume}</td>
                            <td className="textAlignRight">{product.amount}</td>
                        </tr>
                    )
                }
                <tr className="table-footer-row table-body-content">
                    <td className="textAlignLeft">Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.total.volume}</td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.total.amount}</td>
                </tr>
            </div>;
        return (

            <table id="printFPTable" className="table">
                <thead className="body-content-table">
                    <tr className="table-row-top-border">
                        {displayHeaders}
                    </tr>
                </thead>
                <tbody className="table-body-content-inner">
                    {tabledata !== undefined && tabledata.length > 0 ?
                        <div>
                            {tabledata}
                            {totalTableData}
                        </div>
                        : <div style={noDataCss}><h4>{statusText}</h4></div>}
                </tbody>
            </table>
        )
    }
}
export default FpReportTable;