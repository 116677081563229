import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';

class FilterTransetModal extends Component {
    constructor() {
        super();
        this.loadFilterData = this.loadFilterData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.closeFilterTranset = this.closeFilterTranset.bind(this);
        this.state = {
            amount: "",
            periodSeq: "",
            signature: "No",
            errors: {}
        }
    }
    clearFilterData() {
        document.getElementById("filterAmount").value = "";
        document.getElementById("filterDate").value = "";
        document.getElementById("filterCard").value = "";
        document.getElementById("filterSeqNo").value = "";
        document.getElementById("filterSignature").value = "";
    }
    clearDate() {
        //var formattedDate = moment(new Date(), 'MM/DD/YYYY').format('YYYY-MM-DD');
        //window.filterDatedatepicker.datepicker('setDate',formattedDate);
    }
    loadFilterData() {
        var amounterr = /^-?\d*\.?\d*$/.test(document.getElementById("filterAmount").value);
        var periodSeqerr = /^-?\d*\.?\d*$/.test(document.getElementById("filterSeqNo").value);
        console.log(document.getElementById("filterDate").value)

        if (amounterr === false && periodSeqerr === false) {
            this.setState({
                errors: { "amount": "Amount is invalid.", "periodSeq": "Ticket Number is invalid." }
            })
        } else if (amounterr === false && periodSeqerr !== false) {
            this.setState({
                errors: { "amount": "Amount is invalid." }
            })
        } else if (amounterr !== false && periodSeqerr === false) {
            this.setState({
                // errors: { "periodSeq": "Period Sequence Number is invalid." }
                errors: { "periodSeq": "Ticket Sequence Number is invalid." }
            })
        } else {
            if (document.getElementById("filterDate").value === "") {
                this.setState({
                    errors: { "date": "Date is required." }
                })
            } else {
                this.setState({
                    errors: { "date": undefined }
                })
                var filterObject = {};
                filterObject.amount = document.getElementById("filterAmount").value;
                filterObject.period = document.getElementById("filterDate").value;
                filterObject.cardtype = document.getElementById("filterCard").value;
                // filterObject.periodseq = document.getElementById("filterSeqNo").value;
                filterObject.ticketseq = document.getElementById("filterSeqNo").value;
                filterObject.signature = document.getElementById("filterSignature").value;
                this.props.clickFilterData(filterObject);
                document.getElementById("Filtertranset").style.width = "0%";
            }
        }
    }

    closeFilterTranset() {
        this.setState({
            errors: {}
        })
        document.getElementById("Filtertranset").style.width = "0%";
    }

    handleChange(event) {
        //if(event.target.name==="amount"){

        if (!/^-?\d*\.?\d*$/.test(event.target.value)) {
            if (event.target.name === "amount") {
                this.setState({
                    errors: { ...this.state.errors, [event.target.name]: 'Amount is invalid.' }
                })
            } else if (event.target.name === "periodSeq") {
                this.setState({
                    errors: { ...this.state.errors, [event.target.name]: 'Ticket Number is invalid.' }
                })
            }

        } else {
            this.setState({
                errors: { ...this.state.errors, [event.target.name]: undefined }
            })
        }
        //}
    }

    clearFilterDate() {
        $("#filterDate").val("");
    }

    componentDidMount() {
        window.filterDateChange = function (date) {

        }
    }
    render() {
        return (
            <div id="Filtertranset" className="overlay">
                <a href="javascript:void(0)" className="closebtn" onClick={this.closeFilterTranset} style={{ paddingTop: "0px" }}><i className="fa fa-times"
                    aria-hidden="true"></i></a>
                <div className="overlay-content-transet">
                    <div className="setting-model-iframe">
                        <div className="add-store-body-content">
                            <div className="issue-model-heading" style={{ paddingTop: "12px", marginBottom: '12px' }}>
                                <h4><span><img src="./assets/images/obg-store=blak.png" /></span>Filter Transet Report</h4>
                            </div>

                            <div className="add-store-form-content">
                                <div className="row">
                                    <div className="col-md-12">
                                        <form>
                                            {/* <div className="form-group dropdown-address has-search">
                                                <label htmlFor="City">Trans Type Criteria</label>
                                                <select className="form-control dropdown-list-address">
                                                    <option>Show all</option>
                                                    <option>Option 2</option>
                                                    <option>Option 3</option>
                                                </select>
                                            </div>
                                            <div className="form-group dropdown-address has-search">
                                                <label htmlFor="City">Extended Criteria</label>
                                                <select className="form-control dropdown-list-address">
                                                    <option>Show all</option>
                                                    <option>Option 2</option>
                                                    <option>Option 3</option>
                                                </select>
                                            </div> */}
                                            <div className="form-group has-search">
                                                <label htmlFor="user ID">Amount</label>
                                                <input type="text" id="filterAmount" className="form-control bridge-input" placeholder="$0.00" name="amount" onChange={this.handleChange} />
                                                {this.state.errors.amount && (
                                                    <p style={{ color: "red" }}>{this.state.errors.amount}</p>
                                                )}
                                            </div>
                                            <div className="form-group has-search filter-date-clear">
                                                <label htmlFor="Password">Date</label>
                                                <input type="text" id="filterDate" className="form-control bridge-input" placeholder="yyyy-mm-dd" autoComplete="off" name="date" readOnly />
                                                <i className="fa fa-times filter-date-clear-icon" onClick={this.clearFilterDate} aria-hidden="true"></i>
                                                {this.state.errors.date && (
                                                    <p style={{ color: "red" }}>{this.state.errors.date}</p>
                                                )}
                                            </div>

                                            <div className="form-group dropdown-address has-search">
                                                <label htmlFor="City">Card Type</label>
                                                <select className="form-control dropdown-list-address stateDropdown" id="filterCard">
                                                    <option value="">Select Card</option>
                                                    <option value="DEBIT">DEBIT</option>
                                                    <option value="CREDIT">CREDIT</option>
                                                </select>
                                            </div>
                                            <div className="form-group has-search">
                                                <label htmlFor="user ID">Ticket Number</label>
                                                <input type="text" id="filterSeqNo" className="form-control bridge-input" placeholder="Enter Number" name="periodSeq" onChange={this.handleChange} />
                                                {this.state.errors.periodSeq && (
                                                    <p style={{ color: "red" }}>{this.state.errors.periodSeq}</p>
                                                )}
                                            </div>
                                            <div className="form-group dropdown-address has-search">
                                                <label htmlFor="City">Signature</label>
                                                <select className="form-control dropdown-list-address stateDropdown" id="filterSignature">
                                                    <option value="">Select</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                            <button className="btn btn-primary submit-button" type="button" role="button" style={{ marginTop: "18px" }} onClick={this.clearFilterData}>Clear</button>&nbsp;
                                            <button className="btn btn-primary submit-button" type="button" role="button" style={{ marginTop: "18px", marginRight: "5px" }} onClick={this.loadFilterData}>Filter</button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FilterTransetModal;