import React, { Component } from 'react';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import axios from 'axios';
import { prefixUrl, poolData } from '../urlandheaderdata';
import Loader from 'react-loader-spinner';
import IdleTimer from 'react-idle-timer';
class AddStoreAfterSignup extends Component {
    constructor(props) {

        super(props);
        this.idleTimer = null;
        this.newstore = {
            name: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            emailID: "",
            phoneNumber: "",
            userID: "",
        }
        this.state = {
            userLastname: "",
            loadSpin: false,
            errors: {}
        }
        this.saveStore = this.saveStore.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.buildPostData = this.buildPostData.bind(this);
        this.nameRef = React.createRef();
        this.addressRef = React.createRef();
        this.cityRef = React.createRef();
        this.stateRef = React.createRef();
        this.zipRef = React.createRef();
        this.emailIDRef = React.createRef();
        this.phoneNumberRef = React.createRef();
        this.onIdle=this.onIdle.bind(this);
    }

    componentDidMount() {
        var isUserSignedIn = sessionStorage.getItem('AddStorePage');
        if (isUserSignedIn == null) {
            this.props.history.push('/');
        }
        var userPool = new CognitoUserPool(poolData);
        var currentUser = userPool.getCurrentUser();
        if (currentUser !== null) {
            currentUser.getSession(function (err, session) {
                if (err) {
                    console.log(err);
                    return;
                }
                const token = session.getIdToken().getJwtToken();
                axios.defaults.headers.common['Authorization'] = token;
            });
            var that = this;
            currentUser.getUserAttributes(function (err, result) {
                if (err) {
                    console.log(err);
                    return;
                }
                if (result) {
                    that.setState({
                        userLastname: result.find(x => x.Name == "family_name").getValue()
                    });
                    that.newstore.userID = currentUser.username;
                }
            });
        }
    }

    clearStoreData() {
        this.nameRef.current.value = "";
        this.addressRef.current.value = "";
        this.cityRef.current.value = "";
        this.stateRef.current.value = "select";
        this.zipRef.current.value = "";
        this.emailIDRef.current.value = "";
        this.phoneNumberRef.current.value = "";
        this.newstore.name = "";
        this.newstore.address = "";
        this.newstore.city = "";
        this.newstore.state = "";
        this.newstore.zip = "";
        this.newstore.emailID = "";
        this.newstore.phoneNumber = "";
        this.newstore.userID = ""
    }

    changeHandler($event) {
        this.setState({
            errors: { ...this.state.errors, [$event.target.name]: undefined }
        })
        this.newstore[$event.target.name] = $event.target.value
    }

    buildPostData() {
        let postData = {};
        postData.userID = this.newstore.userID;
        this.address = {
            address: this.newstore.address,
            city: this.newstore.city,
            state: this.newstore.state,
            zip: this.newstore.zip
        }
        postData.address = this.address;
        postData.name = this.newstore.name;
        postData.EmailID = this.newstore.emailID;
        postData.PhoneNumber = this.newstore.phoneNumber;
        return postData;
    }

    saveStore($event) {
        console.log(this.newstore);
        console.log(this.buildPostData());
        if ((this.newstore.name === null || this.newstore.name === "" || this.newstore.name === "null") &&
            (this.newstore.address === null || this.newstore.address === "" || this.newstore.address === "null") &&
            (this.newstore.city === null || this.newstore.city === "" || this.newstore.city === "null") &&
            (this.newstore.state === null || this.newstore.state === "" || this.newstore.state === "null") &&
            (this.newstore.zip === null || this.newstore.zip === "" || this.newstore.zip === "null")
        ) {
            this.setState({
                errors: {
                    "name": "Name is required.",
                    "address": "Address is required.",
                    "city": "City is required.",
                    "state": "State is required.",
                    "zip": "Zip is required."
                }
            })
        }
        else if (this.newstore.name === null || this.newstore.name === "" || this.newstore.name === "null") {
            this.setState({
                errors: { "name": "Name is required." }
            })
        }
        else if (this.newstore.address === null || this.newstore.address === "" || this.newstore.address === "null") {
            this.setState({
                errors: { "address": "Address is required." }
            })
        }
        else if (this.newstore.city === null || this.newstore.city === "" || this.newstore.city === "null") {
            this.setState({
                errors: { "city": "City is required." }
            })
        }
        else if (this.newstore.state === null || this.newstore.state === "" || this.newstore.state === "null" || this.newstore.state === "select") {
            this.setState({
                errors: { "state": "State is required." }
            })
        }
        else if (this.newstore.zip === null || this.newstore.zip === "" || this.newstore.zip === "null") {
            this.setState({
                errors: { "zip": "Zip is required." }
            })
        }
        else if (this.newstore.name !== null && this.newstore.address !== null && this.newstore.city !== null && this.newstore.state !== null && this.newstore.zip != null) {
            console.log(this.newstore);
            this.setState({
                loadSpin: true,
                errors: {}
            })
            let url = prefixUrl + `AddNewStore`;
            /*url for new server www.mycstorereports.com
            let url=`https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/AddNewStore`;*/
            axios.post(url, this.buildPostData())
                .then(
                    Response => {
                        if (Response.status === 200 && Response.data !== undefined) {
                            this.clearStoreData();
                            console.log(Response.data);
                            this.setState({
                                loadSpin: false,
                            })
                            sessionStorage.setItem("SignedInConfirmation", "SignedIn");
                            sessionStorage.removeItem("AddStorePage");
                            this.props.history.push('/dashboard');
                        }
                    }
                )
                .catch(
                    error => {
                        console.log(error);
                        if (error.response.status === 404 && error.response.data.storename) {
                            this.setState({
                                loadSpin: false,
                                errors: { "name": "Given Store Name is already existed!" }
                            })
                        }
                        else {
                            this.clearStoreData();
                            this.setState({
                                loadSpin: false,
                            })
                            console.log(error);
                        }
                    }
                )
        }
        $event.preventDefault();
    }

    onIdle(e){
        //console.log(this.idleTimer.getRemainingTime())
        if(this.idleTimer.getRemainingTime()==0){
          alert("Your session is expired");
          sessionStorage.clear();
          this.props.history.push('/');
        }
    }
    render() {
        const loadSpinnerOrSubmitButton = this.state.loadSpin ? <div className="loginLoadSpin"><Loader type="ThreeDots" color="#0069D9" height={50} width={70} /></div> :
            <button className="btn btn-primary submit-button" role="button" onClick={this.saveStore}>Submit</button>;

        let states =
            [
                "AK",
                "AL",
                "AR",
                "AS",
                "AZ",
                "CA",
                "CO",
                "CT",
                "DC",
                "DE",
                "FL",
                "GA",
                "GU",
                "HI",
                "IA",
                "ID",
                "IL",
                "IN",
                "KS",
                "KY",
                "LA",
                "MA",
                "MD",
                "ME",
                "MI",
                "MN",
                "MO",
                "MS",
                "MT",
                "NC",
                "ND",
                "NE",
                "NH",
                "NJ",
                "NM",
                "NV",
                "NY",
                "OH",
                "OK",
                "OR",
                "PA",
                "PR",
                "RI",
                "SC",
                "SD",
                "TN",
                "TX",
                "UT",
                "VA",
                "VI",
                "VT",
                "WA",
                "WI",
                "WV",
                "WY"
            ]

        console.log(this.userLastname);
        return (
            <div className="Login-body-wrapper">
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onIdle={this.onIdle}
                    timeout={1000*60*60} />
                <div className="bg-image">
                    <img src="./assets/images/thanku-bg.png" />
                </div>
                <div className="container-fluid">
                    <div className="header-section">
                        <img src="./assets/images/logo-wt.png" />
                    </div>
                    <p className="top-message-text">Hi {this.state.userLastname}, Thanks for signing-up<br /> Let's set up your stores</p>
                    <div className="col-md-8 col-xs-12 offset-md-2">
                        <div className="store-add-card">
                            <div className="login-forn-content-addstore">
                                <div className="row">
                                    <div className="col-md-6">
                                        <form>
                                            <div className="form-group has-search">
                                                <label for="user ID">Store name</label>
                                                <input type="text" className="form-control bridge-input" placeholder="Type here" id="storeName" name="name" ref={this.nameRef} onChange={this.changeHandler} autoComplete="off" />
                                                {this.state.errors.name && (
                                                    <p style={{ color: "red" }}>{this.state.errors.name}</p>
                                                )}
                                            </div>
                                            <div className="form-group has-search">
                                                <label for="Password">Address</label>
                                                <input type="text" className="form-control bridge-input" placeholder="Type here" id="storeAddress" name="address" ref={this.addressRef} onChange={this.changeHandler} autoComplete="off" />
                                                {this.state.errors.address && (
                                                    <p style={{ color: "red" }}>{this.state.errors.address}</p>
                                                )}
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6 dropdown-address has-search">
                                                    <label for="City">City</label>
                                                    <input type="text" className="form-control bridge-input" placeholder="Type here" id="storeCity" name="city" ref={this.cityRef} onChange={this.changeHandler} autoComplete="off" />
                                                    {this.state.errors.city && (
                                                        <p style={{ color: "red" }}>{this.state.errors.city}</p>
                                                    )}
                                                </div>
                                                <div className="col-md-6 dropdown-address has-search">
                                                    <label for="State">State</label>
                                                    <select className="form-control dropdown-list-address stateDropdown" id="storeState" name="state" ref={this.stateRef} onChange={this.changeHandler}>
                                                        <option value="select">select</option>
                                                        {states.map(state => <option>{state}</option>)}
                                                    </select>
                                                    {this.state.errors.state && (
                                                        <p style={{ color: "red" }}>{this.state.errors.state}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-6">
                                        <form>
                                            <div className="form-group has-search">
                                                <label for="user ID">Email ID (Optional)</label>
                                                <input type="text" className="form-control bridge-input" placeholder="Type here" id="storeEmailID" name="emailID" ref={this.emailIDRef} onChange={this.changeHandler} autoComplete="off" />
                                            </div>
                                            <div className="form-group has-search">
                                                <label for="Password">Phone number (Optional)</label>
                                                <input type="text" className="form-control bridge-input" placeholder="Type here" id="storePhoneNumber" name="phoneNumber" ref={this.phoneNumberRef} onChange={this.changeHandler} autoComplete="off" />
                                            </div>
                                            <div className="row">
                                                <div className="col-6 dropdown-address has-search">
                                                    <label for="Zip">Zip</label>
                                                    <input type="text" className="form-control dropdown-list-address" placeholder="Type here" id="storeZip" name="zip" ref={this.zipRef} onChange={this.changeHandler} autoComplete="off" />
                                                    {this.state.errors.zip && (
                                                        <p style={{ color: "red" }}>{this.state.errors.zip}</p>
                                                    )}
                                                </div>
                                                <div className="col-6 dropdown-address has-search">
                                                    {loadSpinnerOrSubmitButton}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="add-store-card-button">
                                <h6 hidden>+ ADD MORE STORES</h6>
                            </div>
                        </div>

                    </div>

                    <div className="login-page-footer-text">
                        <p className="float-left">Object Source INC © 2019.</p>
                        <p className="float-right">Powered by Object Source INC</p>
                    </div>
                </div>
            </div>
        );
    }
}
export default AddStoreAfterSignup;