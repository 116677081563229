import React from 'react';
import './App.css';
import { HashRouter, Route, Switch, BrowserRouter as Router,Redirect } from 'react-router-dom';
import Login from './components/login';
import Dashboard from './components/dashboard';
import AddStoreAfterSignup from './components/AddStoreAfterSignup';
import Handle404Error from './components/Handle404Error';

function App() {
  return (
    <HashRouter basename="/">
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path='/dashboard' exact component={Dashboard} />
        <Route path='/AddStoreAfterSignup' exact component={AddStoreAfterSignup} />
        <Route component={Handle404Error} />
      </Switch>
    </HashRouter>
  );
}

export default App;
