import React, { Component } from 'react';
import moment from 'moment';
import axios from 'axios';
import { prefixUrl } from '../urlandheaderdata';
class SetSyncTime extends Component {
    constructor(props) {
        super(props);
        this.hoursTextRef = React.createRef();
        this.minutesTextRef = React.createRef();
        this.ampmTextRef = React.createRef();
        this.hoursUpBtnRef = React.createRef();
        this.hoursDownBtnRef = React.createRef();
        this.minutesUpBtnRef = React.createRef();
        this.minutesDownBtnRef = React.createRef();
        this.amUpBtnRef = React.createRef();
        this.pmDownBtnRef = React.createRef();
        this.upDownChange = this.upDownChange.bind(this);
        this.setSyncTime = this.setSyncTime.bind(this);
        this.saveSyncTime = this.saveSyncTime.bind(this);
    }
    upDownChange(buttonctrl, textctrl, alternatebuttonctrl) {
        var currentVal = parseInt(textctrl.value);
        var min = parseInt(textctrl.min);
        var max = parseInt(textctrl.max);
        if (!isNaN(currentVal)) {
            if (buttonctrl.dataset.type === 'minus') {
                if (currentVal > min) {
                    var temp = currentVal - 1;
                    if (temp < 10 && temp > -1) {
                        textctrl.value = "0" + temp;
                    } else {
                        textctrl.value = temp;
                    }
                }
                if (currentVal === min) {
                    buttonctrl.disabled = true;
                    alternatebuttonctrl.disabled = false;
                }

            } else if (buttonctrl.dataset.type === 'plus') {
                if (currentVal < max) {
                    var temp = currentVal + 1;
                    if (temp < 10 && temp > -1) {
                        textctrl.value = "0" + temp;
                    }
                    else {
                        textctrl.value = temp;
                    }
                }
                if (currentVal === max) {
                    buttonctrl.disabled = true;
                    alternatebuttonctrl.disabled = false;
                }

            }
        } else {
            textctrl.val(0);
        }
    }

    setSyncTime($event) {
        if ($event.target.parentNode.name === "HoursUpBtn") {
            this.upDownChange($event.target.parentNode, this.hoursTextRef.current, this.hoursDownBtnRef.current)

        } else if ($event.target.parentNode.name === "HoursDownBtn") {
            this.upDownChange($event.target.parentNode, this.hoursTextRef.current, this.hoursUpBtnRef.current)
        } else if ($event.target.parentNode.name === "MinutesUpBtn") {
            this.upDownChange($event.target.parentNode, this.minutesTextRef.current, this.minutesDownBtnRef.current)
        } else if ($event.target.parentNode.name === "MinutesDownBtn") {
            this.upDownChange($event.target.parentNode, this.minutesTextRef.current, this.minutesUpBtnRef.current)
        } else if ($event.target.parentNode.name === "AmUpBtn") {
            this.ampmTextRef.current.value = "AM";
        } else if ($event.target.parentNode.name === "PmDownBtn") {
            this.ampmTextRef.current.value = "PM";
        }
    }
    saveSyncTime() {
        this.props.syncTimeSpinnerOn();
        var currentdate = moment().format('YYYY/MM/DD');
        var Time = parseInt(this.hoursTextRef.current.value) + ":" + parseInt(this.minutesTextRef.current.value) + ":00" + " " + this.ampmTextRef.current.value;
        var syncTime = moment(currentdate + " " + Time).utc().format("YYYY/MM/DD HH:mm");
        let url = prefixUrl + `SetSyncTime`;
        /*url for new server www.mycstorereports.com*/
        //let url=`https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/SetSyncTime`;
        var postdata = {};
        postdata.userID = this.props.userID;
        postdata.storeID = this.props.storeID;
        postdata.sheduleTimeHours = moment(syncTime).hour().toString();
        postdata.sheduleTimeMinutes = moment(syncTime).minutes().toString();
        this.props.hideSyncTimeComponent(syncTime);
        axios.post(url, postdata)
            .then(
                Response => {
                    if (Response.status === 200 && Response.data !== undefined) {
                        this.props.syncTimeSpinnerOff();
                        this.props.syncTimeChange(Response.data);
                        //this.props.hideSyncTimeComponent();
                    }
                }
            )
            .catch(
                error => {
                    this.props.syncTimeSpinnerOff();
                    console.log(error);
                    //this.props.hideSyncTimeComponent();
                }
            )
    }
    render() {
        let hours=null;
        let minutes=null;
        let amOrpm=null;
        if(!this.props.isSyncTimeUpdate)
        {
            hours=this.hoursTextRef.current.value;
            minutes=this.minutesTextRef.current.value;
            amOrpm=this.ampmTextRef.current.value;
        }
        else
        {
            hours = moment('2019/12/12 ' + this.props.syncTime).format('hh');
            minutes = moment('2019/12/12 ' + this.props.syncTime).minutes();
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            amOrpm = moment('2019/12/12 ' + this.props.syncTime).format('A');
        }
        
        return (
            <React.Fragment>
                <div className="commander-status-row hoverclass" id="syncTimeDiv">
                    <h6 className="float-left hoverclass">Set Sync Time</h6>
                    <div className="float-right hoverclass">
                        <button type="button" className="time-set-save-button hoverclass" onClick={this.saveSyncTime}>Save</button>
                    </div>
                </div>
                <div className="Set-time-timer  hoverclass">
                    <div className="time-set-container m-r-10 hoverclass">
                        <div className="input-group hoverclass">
                            <span className="time-set-input hoverclass"> <input key={`${Math.floor((Math.random() * 1000))}-min`} type="text" name="HoursText" ref={this.hoursTextRef}
                                className="form-control input-number hoverclass" defaultValue={hours} min="1" max="12" readOnly /></span>

                            <span className="input-group-btn hoverclass">
                                <button type="button" className="btn btn-default btn-number plus-btn hoverclass" ref={this.hoursUpBtnRef} data-type="plus"
                                    data-field="HoursText" name="HoursUpBtn">
                                    <i className="fa fa-caret-up hoverclass" aria-hidden="true" data-name="HoursUpBtn" onClick={this.setSyncTime}></i>
                                </button>
                                <div className="time-btn-divider-line hoverclass"></div>
                                <button type="button" className="btn btn-default btn-number minus-btn hoverclass" ref={this.hoursDownBtnRef} disabled="disabled"
                                    data-type="minus" data-field="HoursText" name="HoursDownBtn">
                                    <i className="fa fa-caret-down hoverclass" aria-hidden="true" onClick={this.setSyncTime}></i>
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="time-set-container m-r-10 hoverclass">
                        <div className="input-group hoverclass">
                            <span className="time-set-input hoverclass"> <input key={`${Math.floor((Math.random() * 1000))}-min`} type="text" name="MinutesText" ref={this.minutesTextRef}
                                className="form-control input-number hoverclass" defaultValue={minutes} min="0" max="59" readOnly />
                            </span>

                            <span className="input-group-btn hoverclass">
                                <button type="button" className="btn btn-default btn-number plus-btn hoverclass" ref={this.minutesUpBtnRef} data-type="plus"
                                    data-field="MinutesText" name="MinutesUpBtn" >
                                    <i className="fa fa-caret-up hoverclass" aria-hidden="true" onClick={this.setSyncTime}></i>
                                </button>
                                <div className="time-btn-divider-line hoverclass"></div>
                                <button type="button" className="btn btn-default btn-number minus-btn hoverclass" ref={this.minutesDownBtnRef} disabled="disabled"
                                    data-type="minus" data-field="MinutesText" name="MinutesDownBtn" >
                                    <i className="fa fa-caret-down hoverclass" aria-hidden="true" onClick={this.setSyncTime}></i>
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="time-set-container hoverclass">
                        <div className="input-group hoverclass">
                            <span className="time-set-input hoverclass">
                                <input key={`${Math.floor((Math.random() * 1000))}-min`} type="text" name="AmPmText" ref={this.ampmTextRef}
                                    className="form-control input-number myWidth hoverclass" defaultValue={amOrpm} readOnly /></span>

                            <span className="input-group-btn hoverclass">
                                <button type="button" className="btn btn-default btn-number plus-btn hoverclass" ref={this.amUpBtnRef} data-type="plus"
                                    data-field="AmText" name="AmUpBtn">
                                    <i className="fa fa-caret-up hoverclass" aria-hidden="true" onClick={this.setSyncTime}></i>
                                </button>
                                <div className="time-btn-divider-line hoverclass"></div>
                                <button type="button" className="btn btn-default btn-number minus-btn hoverclass" ref={this.pmDownBtnRef}
                                    data-type="minus" data-field="PmText" name="PmDownBtn">
                                    <i className="fa fa-caret-down hoverclass" aria-hidden="true" onClick={this.setSyncTime}></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default SetSyncTime;