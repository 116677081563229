import React, { Component } from 'react';

class Handle404Error extends Component {
    constructor(props) {
        super(props);
        this.redirectPageBtnClick = this.redirectPageBtnClick.bind(this);
    }
    redirectPageBtnClick() {
        if (sessionStorage.getItem('SignedInConfirmation') == null) {
            this.props.history.push('/');
        } else {
            this.props.history.push('/dashboard');
        }
    }
    render() {
        return (
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>:(</h1>
                    </div>
                    <h2>Page not found</h2>
                    <p>The page you are looking for might had its name changed or is temporarily unavailable.</p>
                    <a href="JavaScript:void(0);" onClick={this.redirectPageBtnClick}>home page</a>
                </div>
            </div>
        )
    }
}
export default Handle404Error;