import React, { Component } from 'react';
import $ from 'jquery';

class LoyalityReportTable extends Component {
    constructor() {
        super();
        this.divToDisplay = this.divToDisplay.bind(this);
        this.state = {
            tableobjectdata: undefined,
            divToDisplay: "station",
            progDivToHide: true
        }
    }
    componentDidMount() {
        this.setState({
            tableobjectdata: this.props.tableobjectdata
        })
        $('.progDivToHide').hide();
    }
    arraySort(key) { }
    divToDisplay(e, divObj) {
        console.log(e.target);
        $(".tabbtn").removeClass("selectStyle");
        $(e.target).addClass("selectStyle");
        // this.setState({
        //     divToDisplay:divObj
        // })
        this.setState({
            progDivToHide: false
        })
        $(".divToHide").hide();
        $(`div .${divObj}`).show();
    }
    render() {
        const statusText = this.props.statusText;
        const noDataCss = {
            'paddingTop': '20%',
            'textAlign': 'center'
        }

        const tabs = this.state.tableobjectdata === undefined ? <tr></tr> :
            <tr className="mr-2">
                {this.state.tableobjectdata.totals !== undefined && <button className="stationbtn btn btn-primary-sm w-25 tabbtn selectStyle" onClick={(e) => this.divToDisplay(e, "station")}>STATION TOTALS</button>
                }
                {this.state.tableobjectdata.program !== undefined &&
                    this.state.tableobjectdata.program.length > 0 &&
                    this.state.tableobjectdata.program.map((btn, i) =>
                        <button className="btn btn-primary-sm w-25 tabbtn" onClick={(e) => this.divToDisplay(e, "discount" + i)}>{btn.name} DISCOUNT TOTALS</button>
                    )
                }
            </tr>;

        const stationData = this.state.tableobjectdata === undefined ? <tr></tr> :
            <div className="station divToHide">
                <tr className="table-footer-row table-body-content">
                    <td className="textAlignLeft">Description</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">Amount / Percentage</td>
                </tr>
                <tr>
                    <td className="textAlignLeft">TOTAL CUSTOMERS</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.sales.count}</td>
                </tr>
                <tr className="table-row-bg">
                    <td className="textAlignLeft">TOTAL LOYALITY CUSTOMERS</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.loyalty.count}</td>
                </tr>
                <tr>
                    <td className="textAlignLeft">SALES (Before Ticket Disc)</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.loyalty.amount}</td>
                </tr>
                <tr className="table-row-bg">
                    <td className="textAlignLeft">TOTAL PPG DISCOUNT</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.loyalty.ppgDiscount}</td>
                </tr>
                <tr>
                    <td className="textAlignLeft">TOTAL TICKET DISCOUNT</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.loyalty.ticketDiscount}</td>
                </tr>
                <tr className="table-row-bg">
                    <td className="textAlignLeft">TOTAL LINE ITEM DISCOUNT</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.loyalty.lineItemDiscount}</td>
                </tr>
                <tr>
                    <td className="textAlignLeft">TOTAL LOYALITY DISCOUNT</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.loyalty.lineItemTaxCredit}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td className="textAlignLeft">%LOYALITY CUSTOMERS</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.percent.loyaltyTransactions}</td>
                </tr>
                <tr className="table-row-bg">
                    <td className="textAlignLeft">%SALES ON LOYALITY</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.percent.loyaltySales}</td>
                </tr>
                <tr>
                    <td className="textAlignLeft">%SALES W/OUT LOYALITY</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.percent.nonLoyaltySales}</td>
                </tr>
                <tr className="table-footer-row table-body-content">
                    <td className="textAlignLeft">LOYALITY TRANSACTIONS INSIDE</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td className="textAlignLeft">TOTAL CUSTOMERS</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.inSide.count}</td>
                </tr>
                <tr className="table-row-bg">
                    <td className="textAlignLeft">SALES (Before Ticket Disc)</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.inSide.amount}</td>
                </tr>
                <tr>
                    <td className="textAlignLeft">TOTAL PPG DISCOUNT</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.inSide.ppgDiscount}</td>
                </tr>
                <tr className="table-row-bg">
                    <td className="textAlignLeft">TOTAL TICKET DISCOUNT</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.inSide.ticketDiscount}</td>
                </tr>
                <tr>
                    <td className="textAlignLeft">TOTAL LINE ITEM DISCOUNT</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.inSide.lineItemDiscount}</td>
                </tr>
                <tr className="table-footer-row table-body-content">
                    <td className="textAlignLeft">LOYALITY TRANSACTIONS OUTSIDE</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td className="textAlignLeft">TOTAL CUSTOMERS</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.outSide.count}</td>
                </tr>
                <tr className="table-row-bg">
                    <td className="textAlignLeft">SALES (Before Ticket Disc)</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.outSide.amount}</td>
                </tr>
                <tr>
                    <td className="textAlignLeft">TOTAL PPG DISCOUNT</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.outSide.ppgDiscount}</td>
                </tr>
                <tr className="table-row-bg">
                    <td className="textAlignLeft">TOTAL TICKET DISCOUNT</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.outSide.ticketDiscount}</td>
                </tr>
                <tr>
                    <td className="textAlignLeft">TOTAL LINE ITEM DISCOUNT</td>
                    <td></td>
                    <td></td>
                    <td className="textAlignRight">{this.state.tableobjectdata.totals.outSide.lineItemDiscount}</td>
                </tr>
            </div>;

        const programData = this.state.tableobjectdata === undefined ? <tr></tr> :
            this.state.tableobjectdata.program !== undefined && this.state.tableobjectdata.program.length > 0 &&
            this.state.tableobjectdata.program.map((prog, i) =>
                <div className={`discount${i} divToHide progDivToHide`} hidden={this.state.progDivToHide}>
                    <tr className="table-footer-row table-body-content">
                        <td className="textAlignLeft">Description</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">Amount / Percentage</td>
                    </tr>
                    <tr className="table-row-bg">
                        <td className="textAlignLeft">TOTAL PPG DISCOUNT</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.total.ppgDiscount}</td>
                    </tr>
                    <tr>
                        <td className="textAlignLeft">TOTAL TICKET DISCOUNT</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.total.ticketDiscount}</td>
                    </tr>
                    <tr className="table-row-bg">
                        <td className="textAlignLeft">TOTAL LINE ITEM DISCOUNT</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.total.lineItemDiscount}</td>
                    </tr>
                    <tr>
                        <td className="textAlignLeft">TOTAL LOYALITY DISCOUNT</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.total.lineItemTaxCredit}</td>
                    </tr>
                    <tr className="table-footer-row table-body-content">
                        <td className="textAlignLeft">LOYALITY TRANSACTIONS INSIDE</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>

                    <tr className="table-row-bg">
                        <td className="textAlignLeft">SALES (Before Ticket Disc)</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.inSide.amount}</td>
                    </tr>
                    <tr>
                        <td className="textAlignLeft">TOTAL PPG DISCOUNT</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.inSide.ppgDiscount}</td>
                    </tr>
                    <tr className="table-row-bg">
                        <td className="textAlignLeft">TOTAL TICKET DISCOUNT</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.inSide.ticketDiscount}</td>
                    </tr>
                    <tr>
                        <td className="textAlignLeft">TOTAL LINE ITEM DISCOUNT</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.inSide.lineItemDiscount}</td>
                    </tr>
                    <tr className="table-row-bg">
                        <td className="textAlignLeft">TOTAL DISCOUNT</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.inSide.lineItemDiscount}</td>
                    </tr>
                    <tr className="table-footer-row table-body-content">
                        <td className="textAlignLeft">LOYALITY TRANSACTIONS OUTSIDE</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className="table-row-bg">
                        <td className="textAlignLeft">SALES (Before Ticket Disc)</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.outSide.amount}</td>
                    </tr>
                    <tr>
                        <td className="textAlignLeft">TOTAL PPG DISCOUNT</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.outSide.ppgDiscount}</td>
                    </tr>
                    <tr className="table-row-bg">
                        <td className="textAlignLeft">TOTAL TICKET DISCOUNT</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.outSide.ticketDiscount}</td>
                    </tr>
                    <tr>
                        <td className="textAlignLeft">TOTAL LINE ITEM DISCOUNT</td>
                        <td></td>
                        <td></td>
                        <td className="textAlignRight">{prog.outSide.lineItemDiscount}</td>
                    </tr>
                </div>
            )


        return (
            <table id="printLoyalityTable" className="table">

                <tbody className="table-body-content-inner">
                    {this.state.tableobjectdata !== undefined ? <div>
                        {tabs}
                        {stationData}
                        {programData}
                    </div> : <div style={noDataCss}><h4>{statusText}</h4></div>}
                </tbody>
            </table>
        )
    }
}
export default LoyalityReportTable;