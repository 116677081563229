import React, { Component } from 'react';
import moment from 'moment';
import $ from 'jquery';

class UpcFilterTransetModal extends Component {
    constructor(props) {
        super(props);
        this.loadUpcFilterData = this.loadUpcFilterData.bind(this);
        this.closeUpcFilterTranset = this.closeUpcFilterTranset.bind(this);
        this.state = {
            errors: {},
            UPCDepartments: []
        }
    }
    componentDidMount() {
        window.filterDateChange = function (date) {

        }
    }
    clearFilterData() {
        document.getElementById("upcDepartment").value = "";
        document.getElementById("upc").value = "";
        document.getElementById("upcfromDate").value = "";
        document.getElementById("upctoDate").value = "";
    }
    clearDate() {
        //var formattedDate = moment(new Date(), 'MM/DD/YYYY').format('YYYY-MM-DD');
        //window.filterDatedatepicker.datepicker('setDate',formattedDate);
    }
    loadUpcFilterData() {
        if (document.getElementById("upcfromDate").value === "" && document.getElementById("upctoDate").value === "") {
            this.setState({
                errors: {
                    "upcfromDate": "From Date is required.",
                    "upctoDate": "To Date is required."
                }
            })
        } else if (document.getElementById("upcfromDate").value === "") {
            this.setState({
                errors: { "upcfromDate": "From Date is required." }
            })
        } else if (document.getElementById("upctoDate").value === "") {
            this.setState({
                errors: { "upctoDate": "To Date is required." }
            })
        } else if (document.getElementById("upcfromDate").value !== "" && document.getElementById("upctoDate").value !== "") {
            this.setState({
                errors: {}
            })
            var UpcfilterObject = {};
            UpcfilterObject.upc = document.getElementById("upc").value.replace(/\s\s+/g, ' ').trim();
            UpcfilterObject.fromdate = document.getElementById("upcfromDate").value;
            UpcfilterObject.todate = document.getElementById("upctoDate").value;
            UpcfilterObject.department = document.getElementById("upcDepartment").value;
            this.props.clickUpcFilterData(UpcfilterObject);
            document.getElementById("UpcFiltertranset").style.width = "0%";
        }
    }

    closeUpcFilterTranset() {
        this.setState({
            errors: {}
        })
        document.getElementById("UpcFiltertranset").style.width = "0%";
    }

    clearFromFilterDate() {
        $("#upcfromDate").val("");
    }
    clearToFilterDate() {
        $("#upctoDate").val("");
    }

    render() {
        return (
            <div id="UpcFiltertranset" className="overlay">
                <a href="javascript:void(0)" className="closebtn" onClick={this.closeUpcFilterTranset} style={{ paddingTop: "0px" }}><i className="fa fa-times"
                    aria-hidden="true"></i></a>
                <div className="overlay-content-transet">
                    <div className="setting-model-iframe">
                        <div className="add-store-body-content">
                            <div className="issue-model-heading" style={{ paddingTop: "12px", marginBottom: '12px' }}>
                                <h4><span><img src="./assets/images/obg-store=blak.png" /></span>Filter UPC Report</h4>
                            </div>

                            <div className="add-store-form-content">
                                <div className="row">
                                    <div className="col-md-12">
                                        <form>
                                            {/* <div className="form-group dropdown-address has-search">
                                                <label htmlFor="City">Trans Type Criteria</label>
                                                <select className="form-control dropdown-list-address">
                                                    <option>Show all</option>
                                                    <option>Option 2</option>
                                                    <option>Option 3</option>
                                                </select>
                                            </div>*/}
                                            <div className="form-group dropdown-address has-search">
                                                <label htmlFor="City">Department</label>
                                                <select className="form-control dropdown-list-address stateDropdown" id="upcDepartment">
                                                    <option value="">Select</option>
                                                </select>
                                            </div>
                                            <div className="form-group has-search">
                                                <label htmlFor="user ID">UPC or Description</label>
                                                <input type="text" id="upc" className="form-control bridge-input" placeholder="Enter UPC or Description" name="upc" />
                                            </div>
                                            <div className="form-group has-search filter-date-clear">
                                                <label htmlFor="Password">From Date</label>
                                                <input type="text" id="upcfromDate" className="form-control bridge-input" placeholder="yyyy-mm-dd" autoComplete="off" name="upcfromDate" readOnly />
                                                <i className="fa fa-times filter-date-clear-icon" onClick={this.clearFromFilterDate} aria-hidden="true"></i>
                                                {this.state.errors.upcfromDate && (
                                                    <p style={{ color: "red" }}>{this.state.errors.upcfromDate}</p>
                                                )}
                                            </div>
                                            <div className="form-group has-search filter-date-clear">
                                                <label htmlFor="Password">To Date</label>
                                                <input type="text" id="upctoDate" className="form-control bridge-input" placeholder="yyyy-mm-dd" autoComplete="off" name="upctoDate" readOnly />
                                                <i className="fa fa-times filter-date-clear-icon" onClick={this.clearToFilterDate} aria-hidden="true"></i>
                                                {this.state.errors.upctoDate && (
                                                    <p style={{ color: "red" }}>{this.state.errors.upctoDate}</p>
                                                )}
                                            </div>
                                            <button className="btn btn-primary submit-button" type="button" role="button" style={{ marginTop: "18px" }} onClick={this.clearFilterData}>Clear</button>&nbsp;
                                            <button className="btn btn-primary submit-button" type="button" role="button" style={{ marginTop: "18px", marginRight: "5px" }} onClick={this.loadUpcFilterData}>Filter</button>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default UpcFilterTransetModal;