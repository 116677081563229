import React, { Component } from 'react';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { prefixUrl, stagingSES, poolData } from '../urlandheaderdata';
import AWS from 'aws-sdk';
import $ from 'jquery';
import InputMask from "react-input-mask";

import { NotificationManager } from 'react-notifications';
import createNotification from '../services/AlertService';

class UserRegistration extends Component {
    constructor() {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };
        super();
        this.newUser = {
            firstName: "",
            lastName: "",
            storeName: "",
            email: "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
            userID: ""
        };
        this.state = {
            loadSpin: false,
            errors: {}
        }
        this.firstNameRef = React.createRef();
        this.lastNameRef = React.createRef();
        this.storeNameRef = React.createRef();
        this.emailRef = React.createRef();
        this.phoneNumberRef = React.createRef();
        this.passwordRef = React.createRef();
        this.confirmPasswordRef = React.createRef();
        this.changeHandler = this.changeHandler.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.sentSignupDetails = this.sentSignupDetails.bind(this);
    }

    clearStoreData() {
        this.firstNameRef.current.value = "";
        this.lastNameRef.current.value = "";
        this.storeNameRef.current.value = "";
        this.emailRef.current.value = "";
        this.phoneNumberRef.current.value = "";
        this.passwordRef.current.value = "";
        this.confirmPasswordRef.current.value = "";
        this.newUser.firstName = "";
        this.newUser.lastName = "";
        this.newUser.storeName = "";
        this.newUser.email = "";
        this.newUser.phoneNumber = "";
        this.newUser.password = "";
        this.newUser.confirmPassword = "";
        this.newUser.userID = "";
    }
    changeHandler($event) {

        this.setState({
            errors: { ...this.state.errors, [$event.target.name]: undefined }
        })
        this.newUser[$event.target.name] = $event.target.value;

    }
    enableOrDisableControls(booleanvalue) {
        this.firstNameRef.current.disabled = booleanvalue;
        this.lastNameRef.current.disabled = booleanvalue;
        this.storeNameRef.current.disabled = booleanvalue;
        this.emailRef.current.disabled = booleanvalue;
        this.phoneNumberRef.current.disabled = booleanvalue;
        this.passwordRef.current.disabled = booleanvalue;
        this.confirmPasswordRef.current.disabled = booleanvalue;
    }
    saveUser($event) {
        if ((this.newUser.email === null || this.newUser.email === "" || this.newUser.email === "null") &&
            (this.newUser.password === null || this.newUser.password === "" || this.newUser.password === "null") &&
            (this.newUser.storeName === null || this.newUser.storeName === "" || this.newUser.storeName === "null") &&
            (this.newUser.phoneNumber === null || this.newUser.phoneNumber === "" || this.newUser.phoneNumber === "null") &&
            (this.newUser.confirmPassword === null || this.newUser.confirmPassword === "" || this.newUser.confirmPassword === "null") &&
            (this.newUser.firstName === null || this.newUser.firstName === "" || this.newUser.firstName === "null") &&
            (this.newUser.lastName === null || this.newUser.lastName === "" || this.newUser.lastName === "null")
        ) {
            this.setState({
                errors: {
                    "email": "Email is required.",
                    "password": "Password is required.",
                    "storeName": "Business Name is required.",
                    "phoneNumber": "Phone Number is required.",
                    "confirmPassword": "Confirm Password is required.",
                    "firstName": "First Name is required.",
                    "lastName": "Last Name is required."
                }
            })
        }
        else if (this.newUser.email === null || this.newUser.email === "" || this.newUser.email === "null") {
            this.setState({
                errors: { "email": "Email is required." }
            })
        }
        else if (this.newUser.password === null || this.newUser.password === "" || this.newUser.password === "null") {
            this.setState({
                errors: { "password": "Password is required." }
            })
        }
        else if (this.newUser.storeName === null || this.newUser.storeName === "" || this.newUser.storeName === "null") {
            this.setState({
                errors: { "storeName": "Business Name is required." }
            })
        }
        else if (this.newUser.phoneNumber === null || this.newUser.phoneNumber === "" || this.newUser.phoneNumber === "null") {
            this.setState({
                errors: { "phoneNumber": "Phone Number is required." }
            })
        }
        else if (this.newUser.confirmPassword === null || this.newUser.confirmPassword === "" || this.newUser.confirmPassword === "null") {
            this.setState({
                errors: { "confirmPassword": "Confirm Password is required." }
            })
        }
        else if (this.newUser.firstName === null || this.newUser.firstName === "" || this.newUser.firstName === "null") {
            this.setState({
                errors: { "firstName": "First Name is required." }
            })
        }
        else if (this.newUser.lastName === null || this.newUser.lastName === "" || this.newUser.lastName === "null") {
            this.setState({
                errors: { "lastName": "Last Name is required." }
            })
        }
        else if (!/\S+@\S+\.\S+/.test(this.newUser.email)) {
            this.setState({
                errors: { "email": "Email is invalid." }
            })
        }
        else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()~])[A-Za-z\d@$!%*?&#^()~]{8,}$/.test(this.newUser.password)) {
            this.setState({
                errors: { "password": "Password must have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character." }
            })
        }
        else if (this.newUser.password != this.newUser.confirmPassword) {
            this.setState({
                errors: { "confirmPassword": "confirm Password is invalid" }
            })
        }
        else if (this.newUser.email != null && this.newUser.password != null) {
            $('#LoginLink').removeAttr("href");
            $('#LoginLink').removeAttr("data-toggle");
            //this.userRegistrationWithAPI();
            this.setState({
                errors: {},
                loadSpin: true
            });
            this.enableOrDisableControls(true);

            var userPool = new CognitoUserPool(poolData);
            var bussinessName = {
                Name: 'given_name',
                Value: this.newUser.storeName
            };
            var firstName = {
                Name: 'name',
                Value: this.newUser.firstName
            };
            var lastName = {
                Name: 'family_name',
                Value: this.newUser.lastName
            };
            var phnNumber = this.formatPhoneNumber(this.newUser.phoneNumber);
            var dataPhoneNumber = {
                Name: 'phone_number',
                Value: phnNumber
            };
            var attributeList = [];
            var attributeBussinessName = new CognitoUserAttribute(bussinessName);
            var attributePhoneNumber = new CognitoUserAttribute(dataPhoneNumber);
            var attributeFirstName = new CognitoUserAttribute(firstName);
            var attributeLastName = new CognitoUserAttribute(lastName);
            attributeList.push(attributeBussinessName);
            attributeList.push(attributePhoneNumber);
            attributeList.push(attributeFirstName);
            attributeList.push(attributeLastName);
            var that = this;
            userPool.signUp(this.newUser.email, this.newUser.password, attributeList, null, function (err, result) {
                if (err) {
                    console.log(err);
                    createNotification('error', err.message);
                    //alert(err.message);
                    that.setState({
                        // errors: { "email": err },
                        loadSpin: false
                    });
                    that.enableOrDisableControls(false);
                    that.clearStoreData();
                    $('#LoginLink').attr("href", "#login-form");
                    $('#LoginLink').attr("data-toggle", "tab");
                    return;
                }

                that.setState({
                    loadSpin: false
                });
                createNotification('success', "SignUp process completed and activation mail sent successfully!");
                //alert("SignUp process completed and activation mail sent successfully!");
                that.sentSignupDetails(that.newUser);
                that.clearStoreData();
                that.enableOrDisableControls(false);
                $('#LoginLink').attr("href", "#login-form");
                $('#LoginLink').attr("data-toggle", "tab");
                $('#LoginLink').addClass('active');
                $('#SignUpLink').removeClass('active');
                $('#login-form').addClass('active');
                $('#registration-form').removeClass('active');
                $('#registration-form').removeClass('show');
                console.log(result);
                const cognitoUser = result.user;
                console.log('user name is ' + cognitoUser.getUsername());
            });
        }

        $event.preventDefault();
    }

    sentSignupDetails(user) {
        AWS.config.update({
            region: 'us-east-1',
            accessKeyId: stagingSES.AccessKey,
            secretAccessKey: stagingSES.SecretKey,
        });
        var params = {
            Destination: { /* required */
                // CcAddresses: [
                //     'anusha.k@objectsourceinc.com',
                //     /* more items */
                // ],
                ToAddresses: [
                    stagingSES.ToAddress,
                    /* more items */
                ]
            },
            Message: { /* required */
                Body: { /* required */
                    Html: {
                        Charset: "UTF-8",
                        Data: "<html>" +
                            "<body>" +
                            "<div>" +
                            "<p>User Details</p>" +
                            `<p><b>First Name:</b>  ${user.firstName} <br /><b>Last Name:</b>  ${user.lastName}<br /><b>Business Name:</b>  ${user.storeName}<br /><b>Email:</b>  ${user.email}<br /><b>Phone Number:</b>  ${user.phoneNumber}</p>` +
                            "<p>Thanks & Regards,<br/>Object Source Inc.</p>" +
                            "</div>" +
                            "</body>" +
                            "</html>"
                    },
                },
                Subject: {
                    Charset: 'UTF-8',
                    Data: user.storeName + ' registered with My C-Store Reports'
                }
            },
            Source: stagingSES.FromAddress, /* required */
        };
        // Create the promise and SES service object
        var sendPromise = new AWS.SES({ apiVersion: '2010-12-01' }).sendEmail(params).promise();

        // Handle promise's fulfilled/rejected states
        sendPromise.then(
            function (data) {
                console.log(data.MessageId);
            }
        ).catch(
            function (err) {
                console.log(err, err.stack);
            }
        );
    }
    userRegistrationWithAPI() {
        let url = prefixUrl + `UserRegistration`;
        /*url for new server www.mycstorereports.com*/
        //let url=`https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/UserRegistration`;
        this.setState({
            errors: {},
            loadSpin: true
        });
        this.enableOrDisableControls(true);
        axios.post(url, this.newUser)
            .then(Response => {
                if (Response.status === 200 && Response.data !== undefined) {
                    this.setState({
                        loadSpin: false
                    });
                    createNotification('success', "SignUp process completed successfully!");
                    //alert("SignUp process completed successfully!");
                    this.clearStoreData();
                    this.enableOrDisableControls(false);
                    console.log(Response.data);
                }
            })
            .catch(error => {
                if (error.response.status === 404 && error.response.data.email) {
                    this.setState({
                        errors: { "email": "Given email is already existed!" },
                        loadSpin: false
                    });
                    this.enableOrDisableControls(false);
                }
                else if (error.response.status === 404 && error.response.data.storeName) {
                    this.setState({
                        errors: { "storeName": "Given Business Name is already existed!" },
                        loadSpin: false
                    });
                    this.enableOrDisableControls(false);
                }
                else {
                    this.setState({
                        loadSpin: false
                    });
                    console.log(error);
                    this.clearStoreData();
                    this.enableOrDisableControls(false);
                }
            });
    }

    formatPhoneNumber(number) {
        var formattedNumber = number.replace(' ', '');
        var formattedNumber1 = formattedNumber.replace("(", "");
        var formattedNumber2 = formattedNumber1.replace(") ", "");
        var formattedNumber3 = formattedNumber2.replace("-", "");
        return formattedNumber3;
    }

    render() {
        const loadSpinner = this.state.loadSpin ? <div className="loginLoadSpin"><Loader type="ThreeDots" color="#0069D9" height={50} width={70} /></div> :
            <button className="btn btn-primary login-submit-button" type="submit" role="button">Sign up</button>;
        return (
            <div id="registration-form" className="login-forn-content tab-pane fade"><br />
                <h2>Hi there! Welcome to Object Source Inc</h2>
                <form onSubmit={this.saveUser} autoComplete="off">
                    <div className="form-group login-search">
                        <span className="fa fa-user form-control-feedback"></span>
                        <input type="text" className="form-control bridge-input" id="firstname" name="firstName" placeholder="First Name" ref={this.firstNameRef} onChange={this.changeHandler} autoComplete="off" />
                        {this.state.errors.firstName && (
                            <p style={{ color: "red" }}>{this.state.errors.firstName}</p>
                        )}
                    </div>
                    <div className="form-group login-search">
                        <span className="fa fa-user form-control-feedback"></span>
                        <input type="text" className="form-control bridge-input" id="lastname" name="lastName" placeholder="Last Name" ref={this.lastNameRef} onChange={this.changeHandler} autoComplete="off" />
                        {this.state.errors.lastName && (
                            <p style={{ color: "red" }}>{this.state.errors.lastName}</p>
                        )}
                    </div>
                    <div className="form-group login-search">
                        <span className="fa fa-user form-control-feedback"></span>
                        <input type="text" className="form-control bridge-input" id="name" name="storeName" placeholder="Business Name" ref={this.storeNameRef} onChange={this.changeHandler} autoComplete="off" />
                        {this.state.errors.storeName && (
                            <p style={{ color: "red" }}>{this.state.errors.storeName}</p>
                        )}
                    </div>
                    <div className="form-group login-search">
                        <span className="fa fa-envelope form-control-feedback"></span>
                        <input type="email" className="form-control bridge-input" id="email" name="email" placeholder="Email ID" ref={this.emailRef} onChange={this.changeHandler} autoComplete="off" />
                        {this.state.errors.email && (
                            <p style={{ color: "red" }}>{this.state.errors.email}</p>
                        )}
                    </div>
                    <div className="form-group login-search">
                        <span className="fa fa-phone form-control-feedback"></span>
                        <InputMask mask="+1 (999) 999-9999" placeholder="Phone number" className="form-control bridge-input" id="phoneNumber" name="phoneNumber" ref={this.phoneNumberRef} onChange={this.changeHandler} autoComplete="off" />
                        {/* <input type="text" className="form-control bridge-input" id="phoneNumber" name="phoneNumber" placeholder="Phone number" ref={this.phoneNumberRef} onChange={this.changeHandler} autoComplete="off" /> */}
                        {this.state.errors.phoneNumber && (
                            <p style={{ color: "red" }}>{this.state.errors.phoneNumber}</p>
                        )}
                    </div>
                    <div className="form-group login-search">
                        <span className="fa fa-key form-control-feedback"></span>
                        <input type="password" className="form-control bridge-input" id="SignUpPwd" name="password" placeholder="Password" ref={this.passwordRef} onChange={this.changeHandler} autoComplete="off" />
                        <span toggle="#SignUpPwd" className="fa fa-lg fa-eye-slash field-icon toggle-password"></span>
                        {this.state.errors.password && (
                            <p style={{ color: "red" }}>{this.state.errors.password}</p>
                        )}
                    </div>
                    <div className="form-group login-search">
                        <span className="fa fa-key form-control-feedback"></span>
                        <input type="password" className="form-control bridge-input" id="confirmPassword" name="confirmPassword" placeholder="Confirm Password" ref={this.confirmPasswordRef} onChange={this.changeHandler} autoComplete="off" />
                        {this.state.errors.confirmPassword && (
                            <p style={{ color: "red" }}>{this.state.errors.confirmPassword}</p>
                        )}
                    </div>
                    {loadSpinner}
                </form>
                <div className="Login-form-footer text-center curser-pointer">
                    <p className="login-footer-terms">Read our Privacy policy</p>
                </div>
            </div>
        );
    }
}
export default UserRegistration;