import React, { Component } from 'react';
import SetSyncTime from './setsynctime';
import axios from 'axios';
import moment from 'moment';
import { setInterval } from 'timers';
import $ from 'jquery';
import { prefixUrl } from '../urlandheaderdata';
import ReactTooltip from 'react-tooltip';

class Tooltip_sidemenu extends Component {
  constructor() {
    super();
    this.state = {
      commanderStatus: "",
      commanderIcon: "",
      bridgeStatus: "",
      bridgeIcon: "",
      store: {}
    }
    this.phoneref = React.createRef();
    this.syncTimeChange = this.syncTimeChange.bind(this);
    this.syncTimeSpinnerOn = this.syncTimeSpinnerOn.bind(this);
    this.syncTimeSpinnerOff = this.syncTimeSpinnerOff.bind(this);
    this.hideSyncTimeComponent = this.hideSyncTimeComponent.bind(this);
    this.findCurrentStorePhoneNumber = this.findCurrentStorePhoneNumber.bind(this);
  }
  componentDidMount() {
    this.commanderStatus();
    setInterval(this.commanderStatus, 30000);
  }
  syncTimeChange(store) {
    this.props.syncTimeChange(store);
  }
  syncTimeSpinnerOn() {
    this.props.syncTimeSpinnerOn();
  }
  syncTimeSpinnerOff() {
    this.props.syncTimeSpinnerOff();
  }
  hideSyncTimeComponent() {
    $(".sidebar-menu-tooltip").hide();
  }
  findCurrentStorePhoneNumber() {
    //ReactTooltip.show(this.phoneref.current);
    //alert(this.props.currentStore.phoneNumber);
  }
  commanderStatus = () => {
    let url = prefixUrl + `CommanderStatus?userID=${this.props.currentStore.userID}&storeID=${this.props.currentStore.storeID}`;
    /*url for new server www.mycstorereports.com*/
    //let url=`https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/CommanderStatus?userID=${this.props.currentStore.userID}&storeID=${this.props.currentStore.storeID}`;
    axios.get(url)
      .then(Response => {
        this.setState({
          store: Response.data
        })
        this.syncTimeChange(Response.data);
        if (this.state.store.status !== null && this.state.store.status !== null) {
          var currentdate = moment().format('YYYY/MM/DD HH:mm');
          var commanderTime = moment.utc(this.state.store.status.commanderTime).local().format('YYYY/MM/DD HH:mm');
          var bridgeStatusTime = moment.utc(this.state.store.status.appUpdateTime).local().format('YYYY/MM/DD HH:mm');
          var commanderStatusDuration = moment.utc(currentdate).diff(moment.utc(commanderTime), "minutes");
          var bridgeStatusduration = moment.utc(currentdate).diff(moment.utc(bridgeStatusTime), "minutes");
          if (commanderStatusDuration <= 5) {
            this.setState({
              commanderStatus: "Connected",
              commanderIcon: "fa fa-circle commander-status-green-icon hoverclass",
              bridgeStatus: "Connected",
              bridgeIcon: "fa fa-circle commander-status-green-icon hoverclass"
            }
            );
            this.props.storeStatusRefs[this.props.currentStore.storeID].current.className = this.state.bridgeIcon;
            this.props.currentStore["commanderStatus"] = this.state.commanderStatus;
            this.props.currentStore["bridgeStatus"] = this.state.bridgeStatus;
            this.props.updateStatusStoreList();
          }
          else if (bridgeStatusduration <= 5) {
            this.setState({
              commanderStatus: "Disconnected",
              commanderIcon: "fa fa-circle commander-status-red-icon hoverclass",
              bridgeStatus: "Connected",
              bridgeIcon: "fa fa-circle commander-status-green-icon hoverclass"
            });
            this.props.storeStatusRefs[this.props.currentStore.storeID].current.className = this.state.commanderIcon;
            this.props.currentStore["commanderStatus"] = this.state.commanderStatus;
            this.props.currentStore["bridgeStatus"] = this.state.bridgeStatus;
            this.props.updateStatusStoreList();
          }
          else {
            this.setState({
              commanderStatus: "Disconnected",
              commanderIcon: "fa fa-circle commander-status-red-icon hoverclass",
              bridgeStatus: "Disconnected",
              bridgeIcon: "fa fa-circle commander-status-red-icon hoverclass"
            });
            this.props.storeStatusRefs[this.props.currentStore.storeID].current.className = this.state.bridgeIcon;
            this.props.currentStore["commanderStatus"] = this.state.commanderStatus;
            this.props.currentStore["bridgeStatus"] = this.state.bridgeStatus;
            this.props.updateStatusStoreList();
          }
        } else {
          //console.log(this.state.store.status);
          this.setState({
            commanderStatus: "Not Setup",
            commanderIcon: "fa fa-circle commander-status-gray-icon hoverclass",
            bridgeStatus: "Not Setup",
            bridgeIcon: "fa fa-circle commander-status-gray-icon hoverclass"
          });
          this.props.storeStatusRefs[this.props.currentStore.storeID].current.className = this.state.bridgeIcon;
          this.props.currentStore["commanderStatus"] = this.state.commanderStatus;
          this.props.currentStore["bridgeStatus"] = this.state.bridgeStatus;
        }
      })
      .catch(error => {
        console.log(error);
      })
  }
  formatPhoneNumber(number) {
    var arr = number.split('');
    var res = '+1 (' + arr[0] + arr[1] + arr[2] + ') ' + arr[3] + arr[4] + arr[5] + '-' + arr[6] + arr[7] + arr[8] + arr[9];
    return res;
  }

  render() {
    let isSyncTimeUpdate = true;
    if ($("#syncTimeDiv").is(":visible")) {
      isSyncTimeUpdate = false;
    }
    var currentdate = moment().format('YYYY/MM/DD');
    var Time = this.props.currentStore.scheduletimeHours + ":" + this.props.currentStore.scheduletimeMinutes + ":" + "00";
    const syncTime = moment.utc(currentdate + " " + Time).local().format('hh:mm A');
    return (
      <div className={`sidebar-menu-tooltip store${this.props.tooltipIndex} hoverclass`} style={{ display: 'none' }}>
        <div className="text-content hoverclass">
          <div className="tooltip-content-top-row hoverclass">
            <div className="hoverclass">
              <h6 className="hoverclass">{this.props.currentStore.name}</h6>
            </div>
            <div className="store-address hoverclass">
              <div className="store-address-col float-left hoverclass">
                <p className="hoverclass">
                  <span className="hoverclass">
                    <i className="fa fa-map-marker address-icon hoverclass"></i>
                  </span> {this.props.currentStore.address.address !== null && this.props.currentStore.address.address + ','}
                  {this.props.currentStore.address.city !== null && this.props.currentStore.address.city + ','}
                  {this.props.currentStore.address.state !== null && this.props.currentStore.address.state + ','}
                  {this.props.currentStore.address.zip !== null && this.props.currentStore.address.zip}
                </p>
              </div>
              <div className="store-contact-col float-right hoverclass">
                {this.props.currentStore.emailID !== null ? <a className="fa fa-envelope store-contact-icon m-r-10 hoverclass"
                  href={`mailto:${this.props.currentStore.emailID}`}></a> :
                  <i className="fa fa-envelope nostore-contact-icon m-r-10 hoverclass"></i>}
                {this.props.currentStore.phoneNumber !== null ?
                  <i className="fa fa-phone store-contact-icon hoverclass" id="phoneOntooltip" ref={this.phoneref} data-for="tooltipForsideMenu" data-tip={this.formatPhoneNumber(this.props.currentStore.phoneNumber)}></i>
                  :
                  <i className="fa fa-phone nostore-contact-icon hoverclass"></i>}
                <ReactTooltip class="formatphoneTip" type="info" id="tooltipForsideMenu" />
              </div>
            </div>
          </div>
          <div className="tooltip-content-body hoverclass">
            <div className="commander-status-row hoverclass">
              <h6 className="float-left hoverclass">Commander Status</h6>
              <div className="commander-status-info float-right hoverclass">
                <p className="hoverclass">
                  <span className="hoverclass">
                    <i className={this.state.commanderIcon}></i>
                  </span> {this.state.commanderStatus}
                </p>
              </div>
            </div>
            <div className="commander-status-row hoverclass">
              <h6 className="float-left hoverclass">Bridge Status {this.props.currentStore.bridgeVersion !== null && "(V " + this.props.currentStore.bridgeVersion + ")"}</h6>
              <div className="commander-status-info float-right hoverclass">
                <p className="hoverclass">
                  <span className="hoverclass">
                    <i className={this.state.bridgeIcon}></i>
                  </span> {this.state.bridgeStatus}
                </p>
              </div>
            </div>
            <div className="set-synk-time-section hoverclass">
              <SetSyncTime syncTimeChange={this.syncTimeChange} userID={this.props.currentStore.userID} storeID={this.props.currentStore.storeID} syncTime={syncTime} syncTimeSpinnerOn={this.syncTimeSpinnerOn} syncTimeSpinnerOff={this.syncTimeSpinnerOff} hideSyncTimeComponent={this.hideSyncTimeComponent} isSyncTimeUpdate={isSyncTimeUpdate} />
            </div>
          </div>
        </div>
        <i className="tooltip-arrow hoverclass"></i>
      </div>
    )
  }
}
export default Tooltip_sidemenu;