import React, { Component } from 'react';
import axios from 'axios';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { prefixUrl, poolData } from '../urlandheaderdata';
import Cookies from 'js-cookie';
import {NotificationManager} from 'react-notifications';
import createNotification from '../services/AlertService';

class SettingsModel extends Component {
    constructor() {
        super();
        this.state = {
            errors: {}
        }
        this.updateUser = {
            oldpassword: "",
            password: "",
            reenterpassword: ""
        }
        this.emailRef = React.createRef();
        this.oldpasswordRef = React.createRef();
        this.passwordRef = React.createRef();
        this.reenterpasswordRef = React.createRef();
        this.changeHandler = this.changeHandler.bind(this);
        this.UpdateChanges = this.UpdateChanges.bind(this);
        this.closeSetting = this.closeSetting.bind(this);

    }
    componentDidMount() {
        var userPool = new CognitoUserPool(poolData);
        var currentUser = userPool.getCurrentUser();
        if (currentUser !== null) {
            currentUser.getSession(function (err, session) {
                if (err) {
                    console.log(err);
                    return;
                }
                //console.log('session validity: ' + session.isValid());
            });
            var that = this;
            currentUser.getUserAttributes(function (err, result) {
                if (err) {
                    console.log(err);
                    return;
                }
                if (result) {
                    var useremail = result.find(x => x.Name == "email").getValue();
                    that.emailRef.current.value = useremail;
                }
            });
        }

        // var user = JSON.parse(localStorage.getItem('userInfo'));
        // this.emailRef.current.value = user.email;
    }
    clearControlsData() {
        this.oldpasswordRef.current.value = "";
        this.passwordRef.current.value = "";
        this.reenterpasswordRef.current.value = "";
        this.updateUser.oldpassword = "";
        this.updateUser.password = "";
        this.updateUser.reenterpassword = "";
    }
    enableOrDisableControls(booleanvalue) {
        this.oldpasswordRef.current.disabled = booleanvalue;
        this.passwordRef.current.disabled = booleanvalue;
        this.reenterpasswordRef.current.disabled = booleanvalue;
    }
    closeSetting() {
        document.getElementById("mySetting").style.width = "0%";
        this.setState({
            errors: {}
        })
        this.clearControlsData();
    }
    changeHandler($event) {
        this.setState({
            errors: { ...this.state.errors, [$event.target.name]: undefined }
        })
        this.updateUser[$event.target.name] = $event.target.value;
    }
    UpdateChanges($event) {
        if ((this.updateUser.oldpassword === null || this.updateUser.oldpassword === "" || this.updateUser.oldpassword === "null") &&
            (this.updateUser.password === null || this.updateUser.password === "" || this.updateUser.password === "null") &&
            (this.updateUser.reenterpassword === null || this.updateUser.reenterpassword === "" || this.updateUser.reenterpassword === "null")) {
            this.setState({
                errors: {
                    "oldpassword": "Old password is required.",
                    "password": "New password is required.",
                    "reenterpassword": "Re-enter new password is required."
                }
            })
        }
        else if (this.updateUser.oldpassword === null || this.updateUser.oldpassword === "" || this.updateUser.oldpassword === "null") {
            this.setState({
                errors: { "oldpassword": "oldpassword is required." }
            })
        }
        else if (this.updateUser.password === null || this.updateUser.password === "" || this.updateUser.password === "null") {
            this.setState({
                errors: { "password": "Password is required." }
            })
        }
        else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()~])[A-Za-z\d@$!%*?&#^()~]{8,}$/.test(this.updateUser.password)) {
            this.setState({
                errors: { "password": "Password must have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character." }
            })
        }
        else if (this.updateUser.reenterpassword === null || this.updateUser.reenterpassword === "" || this.updateUser.reenterpassword === "null") {
            this.setState({
                errors: { "reenterpassword": "Re-enter new password is required." }
            })
        }
        else if (this.updateUser.password !== this.updateUser.reenterpassword) {
            this.setState({
                errors: { "reenterpassword": "Re-enter new password is invalid" }
            })
        }
        else if (this.updateUser.oldpassword === this.updateUser.password) {
            this.setState({
                errors: { "password": "Old password and New password should not be same" }
            })
        }
        else if (this.updateUser.password !== null) {
            //UpdatePassword();
            var userPool = new CognitoUserPool(poolData);
            let cognitoUser = userPool.getCurrentUser();
            if (cognitoUser != null) {
                this.props.syncTimeSpinnerOn();
                this.setState({
                    errors: {}
                });
                this.enableOrDisableControls(true);
                var that = this;
                cognitoUser.getSession(function (err, session) {
                    if (err) {
                        createNotification('error',err.message);
                        //alert(err.message);
                        return;
                    }
                    console.log('session validity: ' + session.isValid());
                });
                cognitoUser.changePassword(this.updateUser.oldpassword, this.updateUser.password, function (err, result) {
                    if (err) {
                        console.log(err);
                        createNotification('error',err.message);
                        //alert(err.message);
                        that.clearControlsData();
                        that.props.syncTimeSpinnerOff();
                        that.enableOrDisableControls(false);
                        $event.preventDefault();
                        return;
                    }

                    that.props.syncTimeSpinnerOff();
                    if (Cookies.get('rememberuser')) {
                        // set password to cookies when user update a password.
                        Cookies.set('userpassword', that.updateUser.password, { expires: 15 });
                    }
                    that.clearControlsData();
                    that.enableOrDisableControls(false);
                    console.log('call result: ' + result);
                    createNotification('success',"password updated successfully!");
                    //alert("password updated successfully!");
                });
            }
        }
        $event.preventDefault();
    }

    UpdatePassword() {
        this.props.syncTimeSpinnerOn();
        this.setState({
            errors: {}
        })
        let url = prefixUrl + `UpdatePassword`;
        /*url for new server www.mycstorereports.com*/
        //let url=`https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/UpdatePassword`;
        this.updateUser.email = this.emailRef.current.value;
        this.enableOrDisableControls(true);
        axios.post(url, this.updateUser)
            .then(
                Response => {
                    if (Response.status === 200 && Response.data !== undefined) {
                        this.props.syncTimeSpinnerOff();
                        this.clearControlsData();
                        this.enableOrDisableControls(false);
                    }
                }
            )
            .catch(
                error => {
                    if (error.response.status === 404) {
                        this.setState({
                            errors: { "oldpassword": "Wrong Password" }
                        })
                    }
                    else if (error.response.status === 204) {
                        createNotification('warning',"User not found");
                        //alert("user not found");
                    } else {
                        this.clearControlsData();
                        console.log(error);
                    }
                    this.props.syncTimeSpinnerOff();
                    this.enableOrDisableControls(false);
                }
            )
    }
    render() {
        return (
            <div id="mySetting" className="overlay">
                <a href="javascript:void(0)" className="closebtn" onClick={this.closeSetting} style={{ paddingTop: "0px" }}><i className="fa fa-times"
                    aria-hidden="true"></i></a>
                <div className="overlay-content-transet">
                    <div className="setting-model-iframe">
                        <div class="container-fluid setting-model-body-content">
                            <div class="issue-model-heading">
                                <h4><i class="fa fa-cog m-r-10"></i>Settings</h4>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <form onSubmit={this.UpdateChanges}>
                                        <div class="form-group has-search email-update-form">
                                            <label class="f-w-600">Email ID</label>
                                            <input type="text" class="form-control bridge-input" ref={this.emailRef} id="SettingsUpdateEmail" name="email" placeholder="Update email ID" readOnly />
                                        </div>

                                        <div class="form-group has-search">
                                            <label class="f-w-600">Update Password</label>
                                            <input type="password" class="form-control bridge-input" ref={this.oldpasswordRef} id="SettinsUpdatePassword" name="oldpassword" placeholder="Old password" onChange={this.changeHandler} />
                                            {this.state.errors.oldpassword && (
                                                <p style={{ color: "red" }}>{this.state.errors.oldpassword}</p>
                                            )}
                                        </div>
                                        <div class="form-group has-search">
                                            <input type="password" class="form-control bridge-input" ref={this.passwordRef} id="SettingsNewPassword" name="password" placeholder="New password" onChange={this.changeHandler} />
                                            {this.state.errors.password && (
                                                <p style={{ color: "red" }}>{this.state.errors.password}</p>
                                            )}
                                        </div>
                                        <div class="form-group has-search">
                                            <input type="password" class="form-control bridge-input" ref={this.reenterpasswordRef} id="SettingsReenterNewPassword" name="reenterpassword" placeholder="Re-enter new password" onChange={this.changeHandler} />
                                            {this.state.errors.reenterpassword && (
                                                <p style={{ color: "red" }}>{this.state.errors.reenterpassword}</p>
                                            )}
                                        </div>
                                        <button type="submit" class="btn btn-primary submit-button">Update Changes</button>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SettingsModel;