import React, { Component } from 'react';
import _ from 'lodash';
import $ from 'jquery';

class UpcReportTable extends Component {
    constructor() {
        super();
        this.state = {
            tableobjectdata: undefined,
        }
        this.holdPrevDeptEvent = null;
        this.showDepartmentData = this.showDepartmentData.bind(this);
    }
    componentDidMount() {
        this.setState({
            tableobjectdata: this.props.tableobjectdata
        });
        $(document).ready(function () {
            $('.hideDeptData').toggle();
        });
    }
    showDepartmentData(tr) {
        if (this.holdPrevDeptEvent !== null && this.holdPrevDeptEvent !== tr) {
            $(this.holdPrevDeptEvent.parentNode.children[1]).slideUp(0);
            this.holdPrevDeptEvent.children[0].children[1].className = "caretclass fa fa-caret-right";
        }
        $(tr.parentNode.children[1]).toggle();
        if ($(tr.parentNode.children[1]).is(':visible')) {
            tr.children[0].children[1].className = "caretclass fa fa-caret-down";
        } else {
            tr.children[0].children[1].className = "caretclass fa fa-caret-right";
        }
        this.holdPrevDeptEvent = tr;
        tr.scrollIntoView(true);
    }

    arraySort(array) {
        let sortedarray = _.sortBy(array, function (x) {
            return parseFloat(x['total']);
        }).reverse();
        return sortedarray;
    }

    render() {
        const statusText = this.props.statusText;
        const noDataCss = {
            'paddingTop': '20%',
            'textAlign': 'center'
        }
        const displayHeaders = this.props.tabletype.headers !== undefined && this.props.tabletype.headers.length > 0 && this.props.tabletype.headers.map((header, i) =>
            i === 0 ?
                <th scope="col" className={header.TextAlign}>
                    {header.DisplayText}
                    <span>&nbsp;</span>
                </th>
                :
                header.DisplayText === "" ? <th scope="col"></th> :
                    <th scope="col" className={header.TextAlign}>
                        {header.DisplayText}
                        <span>&nbsp;</span>
                    </th>
        );

        let totalsum = 0;
        let totalquantity = 0;
        let individualTotalSum = 0;
        let individualQunatitySum = 0;
        const tabledata = this.state.tableobjectdata !== undefined && this.state.tableobjectdata.length > 0 &&
            this.state.tableobjectdata.map((deptgrparray, i) =>
                <table className="table">
                    <tr className="selectStyle upcDepartment" onClick={(e) => this.showDepartmentData(e.currentTarget)}>
                        <td className="textAlignCenter"><b>{deptgrparray[0].item1.replace("&amp;", "&")}</b>  <i className="deptCaret" className="caretclass fa fa-caret-right"></i>
                            {/* <p id={"upctabledepartment" + i} >
                                <b>
                                    <span className="plusminus" style={{ "float": "right" }}>></span>
                                </b>
                            </p> */}
                        </td>
                    </tr>
                    <tbody className="hideDeptData">
                        {
                            individualTotalSum = 0,
                            individualQunatitySum = 0,
                            deptgrparray.map((periodgrparray, i) =>
                                periodgrparray.item3.map((finalarray, i) => {
                                    individualTotalSum = individualTotalSum + parseFloat(finalarray.total)
                                    individualQunatitySum = individualQunatitySum + parseFloat(finalarray.quantity)
                                }
                                )
                            ),
                            totalsum = totalsum + individualTotalSum,
                            totalquantity = totalquantity + individualQunatitySum,
                            deptgrparray.map((periodgrparray, i) =>
                                this.arraySort(periodgrparray.item3).map((finalarray, i) =>
                                    <tr className={(i % 2 === 0) ? "table-row-bg" : ""}>
                                        <td className="textAlignRight">{periodgrparray.item2}</td>
                                        <td className="textAlignRight">{finalarray.plu}</td>
                                        <td className="textAlignLeft">{finalarray.description.replace("&amp;", "&")}</td>
                                        <td className="textAlignRight">{finalarray.quantity}</td>
                                        <td className="textAlignRight">{finalarray.total}</td>
                                    </tr>
                                )
                            )
                        }
                        <tr className="table-footer-row">
                            <td td className="textAlignRight"><b>SUM</b></td>
                            <td></td>
                            <td></td>
                            <td td className="textAlignRight">{individualQunatitySum.toFixed(2)}</td>
                            <td td className="textAlignRight">{individualTotalSum.toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table >
            );
        const tablefooter = this.state.tableobjectdata !== undefined && this.state.tableobjectdata.length > 0 &&
            <tr className="table-footer-row">
                <td className="textAlignRight"><b>TOTAL</b></td>
                <td></td>
                <td></td>
                <td className="textAlignRight">{totalquantity.toFixed(2)}</td>
                <td className="textAlignRight">{totalsum.toFixed(2)}</td>
            </tr>
        return (
            <table id="printUpcTable" className="table">
                <thead className="body-content-table">
                    <tr className="table-row-top-border">
                        {displayHeaders}
                    </tr>
                </thead>
                <tbody className="table-body-content-inner">
                    {tabledata !== undefined && tabledata.length > 0 ?
                        <div>
                            {tabledata}
                        </div>
                        : <div style={noDataCss}><h4>{statusText}</h4></div>}
                </tbody>
                {tabledata !== undefined && tabledata.length > 0 && tablefooter}
            </table >
        )
    }
}
export default UpcReportTable;