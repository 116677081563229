import React, { Component } from 'react';

class Table2 extends Component {
  constructor() {
    super();
    this.state = {
      tableobjectdata: undefined
    }
  }
  componentDidMount() {
    this.setState({
      tableobjectdata: this.props.tableobjectdata
    })
  }
  arraySort(key) { }
  render() {
    const statusText = "No Data";
    const noDataCss = {
      'paddingTop': '20%',
      'textAlign': 'center'
    }
    const displayHeaders = this.props.tabletype.headers.map(header =>
      header.DisplayText == "" ? <th scope="col"></th> :
        <th scope="col">
          {header.DisplayText}
          <span onClick={() => this.arraySort(header.ColumnName)}>&nbsp;<i className="fa fa-caret-down"></i></span>
        </th>
    );

    return (

      <table className="table">
        <thead className="body-content-table">
          <tr className="table-row-top-border">
            {displayHeaders}
          </tr>
        </thead>
        <tbody class="table-body-content-inner">
          <tr>
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr class="table-row-bg">
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr class="table-row-bg">
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr class="table-row-bg">
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr class="table-row-bg">
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr class="table-row-bg">
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr class="table-row-bg">
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
          <tr>
            <td>56</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Mark</td>
            <td>Otto</td>
            <td>@mdo</td>
            <td>Otto</td>
            <td>@mdo</td>
          </tr>
        </tbody>
        <tbody class="table-body-content">
          <tr class="table-footer-row row1-gen-dept">
            <td>GEN Dept.</td>
            <td></td>
            <td></td>
            <td>0.00</td>
            <td></td>
            <td>0.00</td>
            <td>0.00</td>
            <td></td>
            <td>0.00</td>
          </tr>
          <tr class="table-footer-row row2-gen-dept">
            <td>Other Dept.</td>
            <td></td>
            <td></td>
            <td>1345.87</td>
            <td></td>
            <td>5985.90</td>
            <td>0.00</td>
            <td></td>
            <td>7895.05</td>
          </tr>
          <tr class="table-footer-total-count">
            <td>Total</td>
            <td></td>
            <td></td>
            <td>1564.35</td>
            <td>100%</td>
            <td>6548.98</td>
            <td>0.00</td>
            <td></td>
            <td>7895.05</td>
          </tr>
        </tbody>
      </table>
    )
  }
}
export default Table2;