import Address from "./components/AddressComponent";
import React from 'react';

// export const prefixUrl = "https://xqp95aptb6.execute-api.us-east-1.amazonaws.com/Prod/v1/";

// export const stagingSES = {
//     AccessKey: 'AKIARYOOLDOKGHAAKR2K',
//     SecretKey: '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',
//     ToAddress: 'contact@objectsourceinc.com',
//     FromAddress: 'info@objectsourceinc.com'

// }

// export const poolData = {
//     UserPoolId: 'us-east-1_BHqUiCUCq',
//     ClientId: '2tk1ude0609mnsk69ls4tbjg24'
// }

export const prefixUrl = "https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/";

export const poolData = {
    UserPoolId: 'us-east-1_alQq1zbCI',
    ClientId: '2119cdndiim6ka3n7a8mq4ie0s'
}
export const stagingSES = {
    AccessKey: 'AKIAX5KAPZIJBFDYTPGI',
    SecretKey: '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',
    ToAddress: 'contact@objectsourceinc.com',
    FromAddress: 'contact@objectsourceinc.com'

}
export const addresses = {};
addresses[100001] = <Address line1="RT 82 Shell" line2="854 ROUTE 82" line3="HOPEWELL JUNCTION, NY 12533" />;
addresses[100002] = <Address line1="Noxon Sunoco" line2="349 Noxon Rd" line3="Poughkeepsie, NY 12603" />;
addresses[10003] = <Address line1="Glenham Gulf" line2="538 NY-52" line3="Beacon, NY 12508" />;
addresses["default"] = <Address line1="STACYS MARKET" line2="6821 Bussiness 50 N" line3="Jefforson City, NO 65109" />;


export const stores = [
    "Hamkingston Store",
    "Kwik Run Store",
    "Romana Store",
    "Shalamia Store",
    "Rantikola STA store",
    "Maenigala Store",
    "Kwik Run Store",
    "Romana Store",
    "Shalamia Store",
    "Rantikola STA store",
    "Maenigala Store"
];
export const departments = [
    {
        dept: "Department Report",
        reportName: "Department",
        tableToDisplay: "table1",
        icon: "fa fa-building dropdown-icon",
        // url:"https://xqp95aptb6.execute-api.us-east-1.amazonaws.com/Prod/v1/Department/Departments?userID=10001&page=&storeID=",
        url: prefixUrl + "Departments?userID=",
        /*url for new server www.mycstorereports.com*/
        //url:"https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/Departments?userID=",
        headers: [
            { DisplayText: 'Department', ColumnName: 'sysId', TextAlign: 'textAlignRight' },
            { DisplayText: 'Description', ColumnName: 'name', TextAlign: 'textAlignLeft' },
            { DisplayText: 'Sales', ColumnName: 'netSalesCount', TextAlign: 'textAlignRight' },
            { DisplayText: 'Items', ColumnName: 'netSalesItemCount', TextAlign: 'textAlignRight' },
            { DisplayText: '% Of Sales', ColumnName: 'percentOfSales', TextAlign: 'textAlignRight' },
            { DisplayText: 'Gross', ColumnName: 'grossSales', TextAlign: 'textAlignRight' },
            { DisplayText: 'Refunds', ColumnName: 'refundAmount', TextAlign: 'textAlignRight' },
            { DisplayText: 'Discounts', ColumnName: 'discountsTotalAmount', TextAlign: 'textAlignRight' },
            { DisplayText: 'Net Sales', ColumnName: 'netSalesAmount', TextAlign: 'textAlignRight' }
        ],
        footers: [
            {
                RowText: "Department NEG Dept.",
                FooterKey: "negative",
                RowValue: [
                    { ColumnName: '', TextAlign: '' },
                    { ColumnName: '', TextAlign: '' },
                    { ColumnName: 'itemCount', TextAlign: 'textAlignRight' },
                    { ColumnName: '', TextAlign: '' },
                    { ColumnName: 'grossSales', TextAlign: 'textAlignRight' },
                    { ColumnName: 'refunds', TextAlign: 'textAlignRight' },
                    { ColumnName: 'discounts', TextAlign: 'textAlignRight' },
                    { ColumnName: 'netSales', TextAlign: 'textAlignRight' }
                ]

            },
            {
                RowText: "Department Other Dept.",
                FooterKey: "other",
                RowValue: [
                    { ColumnName: '', TextAlign: '' },
                    { ColumnName: '', TextAlign: '' },
                    { ColumnName: 'itemCount', TextAlign: 'textAlignRight' },
                    { ColumnName: '', TextAlign: '' },
                    { ColumnName: 'grossSales', TextAlign: 'textAlignRight' },
                    { ColumnName: 'refunds', TextAlign: 'textAlignRight' },
                    { ColumnName: 'discounts', TextAlign: 'textAlignRight' },
                    { ColumnName: 'netSales', TextAlign: 'textAlignRight' }
                ]

            },
            {
                RowText: "Without Fuel Deposit (9999)",
                FooterKey: "total",
                RowValue: [
                    { ColumnName: '', TextAlign: '' },
                    { ColumnName: '', TextAlign: '' },
                    { ColumnName: 'itemCount', TextAlign: 'textAlignRight' },
                    { ColumnName: 'percentOfSales', TextAlign: 'textAlignRight' },
                    { ColumnName: 'grossSales', TextAlign: 'textAlignRight' },
                    { ColumnName: 'refunds', TextAlign: 'textAlignRight' },
                    { ColumnName: 'discounts', TextAlign: 'textAlignRight' },
                    { ColumnName: 'netSales', TextAlign: 'textAlignRight' }
                ]

            },
            {
                RowText: "Deparment Total",
                FooterKey: "total",
                RowValue: [
                    { ColumnName: '', TextAlign: '' },
                    { ColumnName: '', TextAlign: '' },
                    { ColumnName: 'itemCount', TextAlign: 'textAlignRight' },
                    { ColumnName: 'percentOfSales', TextAlign: 'textAlignRight' },
                    { ColumnName: 'grossSales', TextAlign: 'textAlignRight' },
                    { ColumnName: 'refunds', TextAlign: 'textAlignRight' },
                    { ColumnName: 'discounts', TextAlign: 'textAlignRight' },
                    { ColumnName: 'netSales', TextAlign: 'textAlignRight' }
                ]

            }
        ],
        data: [],
        key: "departmentInfo"
    },
    {
        dept: "Network Product Report",
        reportName: "NetworkProductReports",
        tableToDisplay: "table1",
        icon: "fa fa-product-hunt dropdown-icon",
        url: prefixUrl + "NetworkProduct?userID=",
        /*url for new server www.mycstorereports.com*/
        //url:"https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/NetworkProduct?userID=",
        headers: [
            { DisplayText: 'Product ID', ColumnName: 'sysID', TextAlign: 'textAlignRight' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: 'Description', ColumnName: 'name', TextAlign: 'textAlignLeft' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: 'Quantity', ColumnName: 'volume', TextAlign: 'textAlignRight' },
            { DisplayText: 'CNT', ColumnName: 'netSalesCount', TextAlign: 'textAlignRight' },
            { DisplayText: 'Total$', ColumnName: 'netSalesAmount', TextAlign: 'textAlignRight' }
        ],
        footers: [
            {
                RowText: "Total",
                FooterKey: "total",
                RowValue: [
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: 'NetworkProdTotal', TextAlign: 'textAlignRight' }
                ]

            }
        ],
        data: [],
        key: "networkProductInfo"
    },
    {
        dept: "Network card Report",
        reportName: "NetworkCardReports",
        tableToDisplay: "table1",
        icon: "fa fa-microchip dropdown-icon",
        url: prefixUrl + "NetworkCard?userID=",
        /*url for new server www.mycstorereports.com*/
        //url:"https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/NetworkCard?userID=",
        headers: [
            { DisplayText: 'Name', ColumnName: 'cardName', TextAlign: 'textAlignLeft' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: 'CNT', ColumnName: 'cardChargesCount', TextAlign: 'textAlignRight' },
            { DisplayText: 'Sales', ColumnName: 'cardChargesAmount', TextAlign: 'textAlignRight' },
            { DisplayText: 'CNT', ColumnName: 'cardCorrectionsCount', TextAlign: 'textAlignRight' },
            { DisplayText: 'Refunds', ColumnName: 'cardCorrectionsAmount', TextAlign: 'textAlignRight' }
        ],
        footers: [
            {
                RowText: "NEG Dept.",
                FooterKey: "total",
                RowValue: [
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: 'cardChargesCount', TextAlign: 'textAlignRight' },
                    { ColumnName: 'cardChargesAmount', TextAlign: 'textAlignRight' },
                    { ColumnName: 'cardCorrectionsCount', TextAlign: 'textAlignRight' },
                    { ColumnName: 'cardCorrectionsAmount', TextAlign: 'textAlignRight' },

                ]

            }
        ],
        data: [],
        key: "cardInfo"
    },
    {
        dept: "Tank Report",
        reportName: "TankReports",
        tableToDisplay: "table1",
        icon: "fa fa-cube dropdown-icon",
        url: prefixUrl + "TankReports?userID=",
        /*url for new server www.mycstorereports.com*/
        //url:"https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/TankReports?userID=",
        headers: [
            { DisplayText: 'Name', ColumnName: 'name', TextAlign: 'textAlignLeft' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: 'Volume', ColumnName: 'volume', TextAlign: 'textAlignRight' },
            { DisplayText: 'Dollar', ColumnName: 'amount', TextAlign: 'textAlignRight' },
        ],
        data: [],
        footers: [
            {
                RowText: "NEG Dept.",
                FooterKey: "total",
                RowValue: [
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: "", TextAlign: '' },
                    { ColumnName: 'volume', TextAlign: 'textAlignRight' },
                    { ColumnName: 'amount', TextAlign: 'textAlignRight' },
                ]

            }
        ],
        key: "fuelInfos"
    },
    {
        dept: "Tier/PR Report",
        reportName: "TierReports",
        tableToDisplay: "TierReportTable",
        icon: "fa fa-database dropdown-icon",
        url: prefixUrl + "TierReports?userID=",
        /*url for new server www.mycstorereports.com*/
        //url:"https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/TierReports?userID=",
        headers: [
            { DisplayText: 'Name', ColumnName: 'name', TextAlign: 'textAlignLeft' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: 'Volume', ColumnName: 'volume', TextAlign: 'textAlignRight' },
            { DisplayText: 'Amount', ColumnName: 'amount', TextAlign: 'textAlignRight' },
        ],
        footers: [],
        data: [],
        key: "tierProductInfos[0].productInfos"
    },
    {
        dept: "POP Discount Report",
        reportName: "POPDiscountReport",
        tableToDisplay: "PopReportTable",
        icon: "fa fa-percent dropdown-icon",
        url: prefixUrl + "POPDiscountReport?userID=",
        /*url for new server www.mycstorereports.com*/
        //url:"https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/POPDiscountReport?userID=",
        headers: [
            { DisplayText: 'Product DISC Type', ColumnName: 'type', TextAlign: 'textAlignLeft' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: 'Volume', ColumnName: 'volume', TextAlign: 'textAlignRight' },
            { DisplayText: 'Disc. Amt', ColumnName: 'amount', TextAlign: 'textAlignRight' },
        ],
        footers: [],
        data: []
    },
    {
        dept: "FP Dispenser Report",
        reportName: "FPDespenserReports",
        tableToDisplay: "FpReportTable",
        icon: "fa fa-rocket dropdown-icon",
        url: prefixUrl + "FPDespenserReports?userID=",
        /*url for new server www.mycstorereports.com*/
        //url:"https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/FPDespenserReports?userID=",
        headers: [
            { DisplayText: 'Name', ColumnName: 'name', TextAlign: 'textAlignLeft' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: 'Volume', ColumnName: 'volume', TextAlign: 'textAlignRight' },
            { DisplayText: 'Dollars', ColumnName: 'amount', TextAlign: 'textAlignRight' },
        ],
        footers: [],
        data: []
    },
    {
        dept: "Loyalty Report",
        reportName: "LoyaltyReports",
        tableToDisplay: "LoyalityReportTable",
        icon: "fa fa-gift dropdown-icon",
        url: prefixUrl + "LoyaltyReports?userID=",
        /*url for new server www.mycstorereports.com*/
        //url:"https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/LoyaltyReports?userID=",
        headers: [
            { DisplayText: 'col1', ColumnName: 'col1' },
            { DisplayText: 'col2', ColumnName: 'col2' },
            { DisplayText: 'col3', ColumnName: 'col3' },
            { DisplayText: 'col4', ColumnName: 'col4' }
        ],
        footers: [],
        data: []
    },
    {
        dept: "Tank Reconciliation",
        reportName: "TankReconciliation",
        tableToDisplay: "TankReconciliationReportTable",
        icon: "fa fa-recycle dropdown-icon",
        url: prefixUrl + "TankReconciliation?userID=",
        /*url for new server www.mycstorereports.com*/
        // url:"https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/TankReconciliation?userID=",
        headers: [
            { DisplayText: 'Tank', ColumnName: 'sysID', TextAlign: 'textAlignLeft' },
            { DisplayText: "", ColumnName: "", TextAlign: '' },
            { DisplayText: 'Date', ColumnName: 'date', TextAlign: 'textAlignLeft' },
            //{DisplayText:"",ColumnName:""},
            { DisplayText: 'Time', ColumnName: 'date', TextAlign: 'textAlignLeft' },
            { DisplayText: 'Volume', ColumnName: 'volume', TextAlign: 'textAlignRight' },
            { DisplayText: 'Total', ColumnName: 'total', TextAlign: 'textAlignRight' },
        ],
        footers: [],
        data: []
    },
    {
        "dept": "Transet Report",
        reportName: "TransetReport",
        tableToDisplay: "table1",
        icon: "fa fa-file-text dropdown-icon",
        "url": prefixUrl + "Transet/GetTranset",
        /*url for new server www.mycstorereports.com*/
        //"url":"https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/Transet/GetTranset",
        "headers": [
            // {DisplayText:'StoreId',ColumnName:'storeID'},
            { DisplayText: 'Ticket Number', ColumnName: 'ticketSeqNum', TextAlign: 'textAlignRight' },
            { DisplayText: 'Trans Date', ColumnName: 'processedDate', TextAlign: 'textAlignLeft' },
            // {DisplayText:'Register',ColumnName:'register'},
            { DisplayText: 'Cashier', ColumnName: 'cashierName', TextAlign: 'textAlignLeft' },
            // {DisplayText:'Duration',ColumnName:'duration'},
            { DisplayText: 'Shifts', ColumnName: 'periodSeqNum', TextAlign: 'textAlignRight' },
            { DisplayText: 'Card', ColumnName: 'mop', TextAlign: 'textAlignLeft' },
            { DisplayText: 'Amount', ColumnName: 'totalWithTax', TextAlign: 'textAlignRight' },
            { DisplayText: 'Signature', ColumnName: 'Signature', TextAlign: 'textAlignLeft' },
        ],
        data: [],
        footers: [
            { RowText: "Transet Pagination" }
        ],
    },
    {
        "dept": "UPC Report",
        reportName: "TransetReport",
        tableToDisplay: "upcReportTable",
        icon: "fa fa-file-text dropdown-icon",
        "url": prefixUrl + "Transet/GetTranset",
        "headers": [
            { DisplayText: 'Date', ColumnName: 'processedDate', TextAlign: 'textAlignRight' },
            { DisplayText: 'UPC', ColumnName: 'plu', TextAlign: 'textAlignRight' },
            { DisplayText: 'Description', ColumnName: 'description', TextAlign: 'textAlignLeft' },
            { DisplayText: 'Quantity', ColumnName: 'quantity', TextAlign: 'textAlignRight' },
            { DisplayText: 'Total', ColumnName: 'total', TextAlign: 'textAlignRight' },
        ],
        data: [],
        footers: []
    }

]

