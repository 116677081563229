import React, { Component } from 'react';
import $ from 'jquery';
import moment from 'moment';
import _ from 'lodash';
import Loader from 'react-loader-spinner';

class DateList extends Component {
  constructor(props) {
    super(props);
    this.showShift = this.showShift.bind(this);
    this.DateShiftClick = this.DateShiftClick.bind(this);
    this.datebarSelect = this.datebarSelect.bind(this);
    this.dateLeftArrowClick = this.dateLeftArrowClick.bind(this);
    this.dateRightArrowClick = this.dateRightArrowClick.bind(this);
    this.loadDateList = this.loadDateList.bind(this);
    this.state = {
      dateObject: undefined,
      position: 0,
      hoverDate: undefined,
      selectedDate: undefined
    }
  }
  showShift(e, dateObj, shiftid) {
    if (e.target.tagName === "DIV") {
      //console.log($(e.target).attr('id'))
      //console.log(dateObj);
      if ($(".page-wrapper").hasClass("toggled")) {
        $(".date-dropdown-list-content").css("left", 280 + $(e.target).position().left - this.state.position);
      } else {
        $(".date-dropdown-list-content").css("left", 60 + $(e.target).position().left - this.state.position);
      }
      //$(".date-dropdown-list-content").css("left", 280+ $(e.target).position().left-this.state.position);
      $(".date-dropdown-list-content").show();
      //console.log($(shiftid));
      //$("#"+shiftid).show();
      this.setState({
        dateObject: dateObj,
        hoverDate: $(e.target).attr('id')
      })
    }

  }
  componentDidMount() {
    $(".date-dropdown-list-content").hide();
    $(".shiftBtn").removeClass("selectStyle");
    $(document).mouseover(function (e) {
      if (!($(e.target).hasClass("dateDropdown"))) {
        $(".date-dropdown-list-content").hide();
      }
    });
  }
  DateShiftClick(e, item) {
    $(".date-inline").removeClass("date-selected");
    $("#" + this.state.hoverDate).addClass("date-selected");
    $(".shiftBtn").removeClass("selectStyle");
    this.setState({
      selectedDate: this.state.hoverDate
    })
    //$(e.target).addClass("selectStyle");
    this.props.syncTimeSpinnerOn();
    setTimeout(() => {
      this.props.DateShiftClick(item);
    }, 1000);
  }

  datebarSelect(e, dateObj) {
    this.setState({
      selectedDate: $(e.target).attr('id')
    })
    //this.props.datebarSelect(dateObj.date);
    $(".date-inline").removeClass("date-selected");
    $(e.target).addClass("date-selected");
    this.props.onDateBarDateSelect(dateObj);
  }

  dateLeftArrowClick() {
    // var view = $("#tslshow");
    // var move = "100px";
    // var sliderLimit = -1200;
    // var currentPosition = parseInt(view.css("left"));
    // if (currentPosition < 0) view.stop(false, true).animate({ left: "+=" + move }, { duration: 400 })

    // if(this.state.position!==0){
    //   this.setState({
    //     position:this.state.position-100
    //   })
    // }
    this.props.DateListMoveLeft();

  }

  componentDidUpdate() {
    // if(this.state.selectedDate!==undefined){
    //   $(".date-inline").removeClass("date-selected");
    // $("#"+this.state.selectedDate).addClass("date-selected");
    // }
    if (this.props.disableCheckDate === moment().format("YYYY-MM-DD")) {
      $("#rightArrow").css("background-color", "grey");
    } else {
      $("#rightArrow").css("background-color", "#2D6BBF");
    }
  }

  dateRightArrowClick() {
    // var view = $("#tslshow");
    // var move = "100px";
    // var sliderLimit = -1200;

    // var currentPosition = parseInt(view.css("right"));
    // //alert(currentPosition);
    // //  if (currentPosition >= sliderLimit) view.stop(false, true).animate({ left: "-=" + move }, { duration: 400 })
    // if (true) view.stop(false, true).animate({ left: "-=" + move }, { duration: 400 })

    // this.setState({
    //   position:this.state.position+100
    // })
    this.props.DateListMoveRight();
  }

  loadDateList() {
    //console.log(this.props.datebarSelectDate);
    const datelist = this.props.dateListObjectData !== undefined && this.props.dateListObjectData.length > 0 && this.props.dateListObjectData.map((dateobj, indx) =>
      dateobj.count === 0
        ?
        this.props.datebarSelectDate && this.props.datebarSelectDate === dateobj.date
          ?
          moment(dateobj.date).format("DD-MM-YYYY") === moment(moment()).format("DD-MM-YYYY")
            ?
            <div id={moment(dateobj.date).format("MMM-DD")} className="date-inline date-selected" onClick={(e) => this.datebarSelect(e, dateobj.date)}>
              {"Today "}
              <span></span>
            </div>
            :
            <div id={moment(dateobj.date).format("MMM-DD")} className="date-inline date-selected" onClick={(e) => this.datebarSelect(e, dateobj.date)}>
              {moment(dateobj.date).format("DD MMM") + " "}
              <span></span>
            </div>
          :
          moment(dateobj.date).format("DD-MM-YYYY") === moment(moment()).format("DD-MM-YYYY")
            ?
            <div id={moment(dateobj.date).format("MMM-DD")} className="date-inline" onClick={(e) => this.datebarSelect(e, dateobj.date)}>
              {"Today "}
              <span></span>
            </div>
            :
            <div id={moment(dateobj.date).format("MMM-DD")} className="date-inline" onClick={(e) => this.datebarSelect(e, dateobj.date)}>
              {moment(dateobj.date).format("DD MMM") + " "}
              <span></span>
            </div>
        :
        this.props.datebarSelectDate && this.props.datebarSelectDate === dateobj.date
          ?
          moment(dateobj.date).format("DD-MM-YYYY") === moment(moment()).format("DD-MM-YYYY")
            ?
            <React.Fragment>
              {/* <div className="date-inline date-notify-dropdown dateDropdown" onClick={(e) => this.showShift(e, dateobj, "shift" + indx)} onClick={(e)=>this.datebarSelect(e,dateobj)}> */}
              <div id={moment(dateobj.date).format("MMM-DD")} className="date-inline date-selected date-notify-dropdown dateDropdown" onMouseOver={(e) => this.showShift(e, dateobj, "shift" + indx)} onClick={(e) => this.datebarSelect(e, dateobj.date)}>
                {"Today "}
                <span className="date-notify-count dateDropdown">{dateobj.count}</span>
                {/* <ShiftComponent shiftid={"shift"+indx} shifts={shifts} /> */}
              </div>
            </React.Fragment>
            :
            <React.Fragment>
              {/* <div className="date-inline date-notify-dropdown dateDropdown" onClick={(e) => this.showShift(e, dateobj, "shift" + indx)} onClick={(e)=>this.datebarSelect(e,dateobj)}> */}
              <div id={moment(dateobj.date).format("MMM-DD")} className="date-inline date-selected date-notify-dropdown dateDropdown" onMouseOver={(e) => this.showShift(e, dateobj, "shift" + indx)} onClick={(e) => this.datebarSelect(e, dateobj.date)}>
                {moment(dateobj.date).format("DD MMM") + " "}
                <span className="date-notify-count dateDropdown">{dateobj.count}</span>
                {/* <ShiftComponent shiftid={"shift"+indx} shifts={shifts} /> */}
              </div>
            </React.Fragment>
          :
          moment(dateobj.date).format("DD-MM-YYYY") === moment(moment()).format("DD-MM-YYYY")
            ?
            <React.Fragment>
              {/* <div className="date-inline date-notify-dropdown dateDropdown" onClick={(e) => this.showShift(e, dateobj, "shift" + indx)} onClick={(e)=>this.datebarSelect(e,dateobj)}> */}
              <div id={moment(dateobj.date).format("MMM-DD")} className="date-inline date-notify-dropdown dateDropdown" onMouseOver={(e) => this.showShift(e, dateobj, "shift" + indx)} onClick={(e) => this.datebarSelect(e, dateobj.date)}>
                {"Today "}
                <span className="date-notify-count dateDropdown">{dateobj.count}</span>
                {/* <ShiftComponent shiftid={"shift"+indx} shifts={shifts} /> */}
              </div>
            </React.Fragment>
            :
            <React.Fragment>
              {/* <div className="date-inline date-notify-dropdown dateDropdown" onClick={(e) => this.showShift(e, dateobj, "shift" + indx)} onClick={(e)=>this.datebarSelect(e,dateobj)}> */}
              <div id={moment(dateobj.date).format("MMM-DD")} className="date-inline date-notify-dropdown dateDropdown" onMouseOver={(e) => this.showShift(e, dateobj, "shift" + indx)} onClick={(e) => this.datebarSelect(e, dateobj.date)}>
                {moment(dateobj.date).format("DD MMM") + " "}
                <span className="date-notify-count dateDropdown">{dateobj.count}</span>
                {/* <ShiftComponent shiftid={"shift"+indx} shifts={shifts} /> */}
              </div>
            </React.Fragment>
    );
    return datelist;
  }

  render() {
    const shifts = this.state.dateObject !== undefined && this.state.dateObject.items !== undefined && this.state.dateObject.items.length > 0
      && this.state.dateObject.items.map((item, i) =>
        this.props.reportNameForDateBar === "TransetReport" ?
          item && item.length > 0 &&
          <li className="dateDropdown" onClick={(e) => this.DateShiftClick(e, item)} style={{ cursor: "pointer" }}>Shift {item[0].periodSeqNum}</li>
          :
          <li className="dateDropdown" onClick={(e) => this.DateShiftClick(e, item)} style={{ cursor: "pointer" }}>Shift {item.periodSeqNum}</li>
      );

    const datelist = this.loadDateList();

    const dateAndShiftButtons = this.props.dateListObjectData !== undefined && this.props.dateListObjectData.length > 0 &&
      this.props.dateListObjectData.map(dateobj =>
        this.props.reportNameForDateBar === "TransetReport" ?
          <div className="dateAndShiftButtonsDiv">
            <button className="btn btn-primary-sm dateBtn" disabled>{moment(dateobj.date).format("DD MMM YYYY")}</button>
            {/* {
                  dateobj.items!==undefined && dateobj.items.length>0 && dateobj.items.map((value,index)=>
                      <button className="btn btn-primary-sm shiftBtn" onClick={(e) => this.DateShiftClick(e,value)}>
                      transet: {value[0].periodSeqNum}
                    </button>
                  )
                } */}
          </div>
          :
          <div className="dateAndShiftButtonsDiv">
            <button className="btn btn-primary-sm dateBtn" disabled>{moment(dateobj.date).format("DD MMM YYYY")}</button>
            {dateobj.items !== undefined && dateobj.items.length > 0 && dateobj.items.slice(0, 8).map((item, i) =>
              i === 0 ?
                <button className="btn btn-primary-sm shiftBtn selectStyle" onClick={(e) => this.DateShiftClick(e, item)}>Shift - {item.periodSeqNum}</button>
                :
                <button className="btn btn-primary-sm shiftBtn" onClick={(e) => this.DateShiftClick(e, item)}>Shift - {item.periodSeqNum}</button>
            )}
          </div>
      );
    return (
      <div>
        <div className="container-fluid horizontal-calender-section">
          {/* this is for date buttons instead of datebar */}
          {/* {dateAndShiftButtons} */}

          {/* date bar functionality */}
          <div className="calender-date-slider dateDropdown">
            <div id="rightArrow" onClick={this.dateRightArrowClick}><i className="fa fa-caret-right" aria-hidden="true"></i></div>
            {this.props.datebarLoader ? <div id="datebarLoader"><div id="datebarLoaderInnerDiv"><Loader type="ThreeDots" color="#0069D9" height={35} width={70} /></div></div> :
              <div id="viewContainer" className="dateDropdown">

                <div id="tslshow" className="dateDropdown">
                  {/* {this.props.datebarLoader?<div className="date-inline"><Loader type="ThreeDots" color="#0069D9" height={80} width={80}/></div>:datelist} */}
                  {datelist}
                </div>
              </div>
            }


            <div id="leftArrow" onClick={this.dateLeftArrowClick}><i className="fa fa-caret-left" aria-hidden="true"></i></div>
          </div>

          {/* this is for showing date and period no instead of datebar before implemented date bar functionality */}
          {/* <div className="calender-date-slider">
          {this.props.period !== "" ? <span>
            <span className="dateListPeriodSpan"><label className="dateListPeriodLabel"><h5><b>Period: </b></h5></label><span className="dateListPeriodText">{this.props.period}</span></span>
            <span className="dateListPeriodSpan"><label className="dateListPeriodLabel"><h5><b>Period Seq No: </b></h5></label><span className="dateListPeriodText">{this.props.periodSeqNum}</span></span>
          </span> : <span></span>
          }
        </div> */}
          <div className="datelistcarousal datecalender">
            <button id="datepicker" className="form-control hide-style" onClick={this.props.clearTransetFilterFields}><i className="fa fa-calendar" aria-hidden="true"></i></button>
            <i className="tooltip-arrow"></i>
          </div>
        </div>
        {/* commented this temporarily this is for shifts of date bar */}
        <div className="date-dropdown-list-content dateDropdown">
          {shifts}
        </div>
      </div>
    )
  }
}

function ShiftComponent(props) {
  return (
    <div id={props.shiftid} className="date-dropdown-list-content dateDropdown">
      {props.shifts}
    </div>
  )
}
export default DateList;