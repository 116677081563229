import React, { Component } from 'react';
import _ from 'lodash';
import $ from 'jquery';

class TierReportTable extends Component {
  constructor() {
    super();
    this.state = {
      tableobjectdata: undefined,
      sortKey: "",
      temp: ""
    }
  }
  componentDidMount() {
    this.setState({
      tableobjectdata: this.props.tableobjectdata
    })
    if (this.props.tableobjectdata &&
      this.props.tableobjectdata.tierProductInfos &&
      this.props.tableobjectdata.tierProductInfos.length > 0) {
      setTimeout(() => {
        this.arraySort("nothing to send", this.props.tabletype.headers[0].ColumnName);
        $("#firstCol").removeAttr("class");
        $("#firstCol").attr("class", "caretclass fa fa-caret-down");
      }, 1000)
    }
  }
  arraySort(e, key) {
    //$(".caretclass").removeAttr("class");
    $(".caretclass").attr("class", "caretclass fa fa-caret-right");
    if (this.state.sortKey !== key) {
      this.setState({
        sortKey: key
      })
      if (this.state.tableobjectdata &&
        this.state.tableobjectdata.tierProductInfos &&
        this.state.tableobjectdata.tierProductInfos.length > 0
      ) {
        $(e.target).removeAttr("class");
        $(e.target).attr("class", "caretclass fa fa-caret-down");
        this.state.tableobjectdata.tierProductInfos.map(tierProduct => {
          if (tierProduct.productInfos && tierProduct.productInfos.length > 0) {
            tierProduct.productInfos = _.sortBy(tierProduct.productInfos, function (x) {
              if (!isNaN(x[key]))
                return parseInt(x[key]);

              return x[key];
            });
            this.setState({
              temp: "a"
            })
            // this.setState({
            //   tableobjectdata: sortedArry
            // });
          }
        })
      }
    } else {
      this.setState({
        sortKey: ""
      });
      if (this.state.tableobjectdata &&
        this.state.tableobjectdata.tierProductInfos &&
        this.state.tableobjectdata.tierProductInfos.length > 0
      ) {
        $(e.target).removeAttr("class");
        $(e.target).attr("class", "caretclass fa fa-caret-up");
        this.state.tableobjectdata.tierProductInfos.map(tierProduct => {
          if (tierProduct.productInfos && tierProduct.productInfos.length > 0) {
            tierProduct.productInfos = _.sortBy(tierProduct.productInfos, function (x) {
              if (!isNaN(x[key]))
                return parseInt(x[key]);

              return x[key];
            }).reverse();
            // this.setState({
            //   tabledata: sortedArry
            // });
          }
        });
      }
    }
  }
  render() {
    const statusText = this.props.statusText;
    const noDataCss = {
      'paddingTop': '20%',
      'textAlign': 'center'
    }
    const displayHeaders = this.props.tabletype.headers.map((header, i) =>
      i === 0 ?
        <th scope="col" className={header.TextAlign}>
          {header.DisplayText}
          <span>&nbsp;<i id="firstCol" onClick={(e) => this.arraySort(e, header.ColumnName)} className="caretclass fa fa-caret-right"></i></span>
        </th>
        :
        header.DisplayText === "" ? <th scope="col"></th> :
          <th scope="col" className={header.TextAlign}>
            {header.DisplayText}
            <span>&nbsp;<i onClick={(e) => this.arraySort(e, header.ColumnName)} className="caretclass fa fa-caret-right"></i></span>
          </th>
    );

    const tabledata = this.state.tableobjectdata === undefined ? undefined :
      this.state.tableobjectdata.tierProductInfos &&
      this.state.tableobjectdata.tierProductInfos.length > 0 &&
      this.state.tableobjectdata.tierProductInfos.map((tierProduct, index) =>
        <div>
          <tr>
            <td className="textAlignLeft"><b>Tier #{index + 1}-{tierProduct.name}</b></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {
            tierProduct.productInfos &&
            tierProduct.productInfos.length > 0 &&
            tierProduct.productInfos.map((productInfo, i) =>
              <tr className={(i % 2 === 0) ? "table-row-bg" : ""}>
                <td className="textAlignLeft">{productInfo.name}</td>
                <td></td>
                <td></td>
                <td></td>
                <td className="textAlignRight">{productInfo.volume}</td>
                <td className="textAlignRight">{productInfo.amount}</td>
              </tr>
            )
          }
          <tr className="table-footer-row table-body-content">
            <td className="textAlignLeft">Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td className="textAlignRight">{this.state.tableobjectdata.total.volume}</td>
            <td className="textAlignRight">{this.state.tableobjectdata.total.amount}</td>
          </tr>
        </div>
      )

    return (

      <table id="printTierPRTable" className="table">
        <thead className="body-content-table">
          <tr className="table-row-top-border">
            {displayHeaders}
          </tr>
        </thead>
        <tbody className="table-body-content-inner">
          {tabledata !== undefined && tabledata.length > 0 ? tabledata : <div style={noDataCss}><h4>{statusText}</h4></div>}
        </tbody>
      </table>
    )
  }
}
export default TierReportTable;