import React, { Component } from 'react';
import axios from 'axios';
import { prefixUrl } from '../urlandheaderdata';
class AddStore extends Component {
    constructor() {
        super();
        this.newstore = {
            name: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            emailID: "",
            phoneNumber: "",
            userID: "",
        }
        this.state = {
            errors: {}
        }
        this.closeAddstore = this.closeAddstore.bind(this);
        this.saveStore = this.saveStore.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.buildPostData = this.buildPostData.bind(this);
        this.addStore = React.createRef();
        this.nameRef = React.createRef();
        this.addressRef = React.createRef();
        this.cityRef = React.createRef();
        this.stateRef = React.createRef();
        this.zipRef = React.createRef();
        this.emailIDRef = React.createRef();
        this.phoneNumberRef = React.createRef();
    }
    clearStoreData() {
        this.nameRef.current.value = "";
        this.addressRef.current.value = "";
        this.cityRef.current.value = "";
        this.stateRef.current.value = "select";
        this.zipRef.current.value = "";
        this.emailIDRef.current.value = "";
        this.phoneNumberRef.current.value = "";
        this.newstore.name = "";
        this.newstore.address = "";
        this.newstore.city = "";
        this.newstore.state = "";
        this.newstore.zip = "";
        this.newstore.emailID = "";
        this.newstore.phoneNumber = "";
        this.newstore.userID = ""
    }
    closeAddstore() {
        this.addStore.current.style.width = "0%";
        this.setState({
            errors: {}
        })
        this.clearStoreData();
    }
    changeHandler($event) {
        this.setState({
            errors: { ...this.state.errors, [$event.target.name]: undefined }
        })
        this.newstore[$event.target.name] = $event.target.value
    }
    buildPostData() {
        let postData = {};
        postData.userID = this.props.userID;
        this.address = {
            address: this.newstore.address,
            city: this.newstore.city,
            state: this.newstore.state,
            zip: this.newstore.zip
        }
        postData.address = this.address;
        postData.name = this.newstore.name;
        postData.EmailID = this.newstore.emailID;
        postData.PhoneNumber = this.newstore.phoneNumber;
        return postData;
    }
    saveStore($event) {
        console.log(this.newstore);
        console.log(this.buildPostData());
        if ((this.newstore.name === null || this.newstore.name === "" || this.newstore.name === "null") &&
            (this.newstore.address === null || this.newstore.address === "" || this.newstore.address === "null") &&
            (this.newstore.city === null || this.newstore.city === "" || this.newstore.city === "null") &&
            (this.newstore.state === null || this.newstore.state === "" || this.newstore.state === "null") &&
            (this.newstore.zip === null || this.newstore.zip === "" || this.newstore.zip === "null")
        ) {
            this.setState({
                errors: {
                    "name": "Name is required.",
                    "address": "Address is required.",
                    "city": "City is required.",
                    "state": "State is required.",
                    "zip": "Zip is required."
                }
            })
        }
        else if (this.newstore.name === null || this.newstore.name === "" || this.newstore.name === "null") {
            this.setState({
                errors: { "name": "Name is required." }
            })
        }
        else if (this.newstore.address === null || this.newstore.address === "" || this.newstore.address === "null") {
            this.setState({
                errors: { "address": "Address is required." }
            })
        }
        else if (this.newstore.city === null || this.newstore.city === "" || this.newstore.city === "null") {
            this.setState({
                errors: { "city": "City is required." }
            })
        }
        else if (this.newstore.state === null || this.newstore.state === "" || this.newstore.state === "null" || this.newstore.state === "select") {
            this.setState({
                errors: { "state": "State is required." }
            })
        }
        else if (this.newstore.zip === null || this.newstore.zip === "" || this.newstore.zip === "null") {
            this.setState({
                errors: { "zip": "Zip is required." }
            })
        }
        else if (this.newstore.name !== null && this.newstore.address !== null && this.newstore.city !== null && this.newstore.state !== null && this.newstore.zip != null) {
            console.log(this.newstore);
            this.setState({
                errors: {}
            })
            this.props.syncTimeSpinnerOn();
            let url = prefixUrl + `AddNewStore`;
            /*url for new server www.mycstorereports.com*/
            //let url=`https://xi0cyhp3j3.execute-api.us-east-1.amazonaws.com/Prod/v1/AddNewStore`;
            axios.post(url, this.buildPostData())
                .then(
                    Response => {
                        if (Response.status === 200 && Response.data !== undefined) {
                            this.props.syncTimeSpinnerOff();
                            this.clearStoreData();
                            this.props.addnewStoreToMenu(Response.data);
                            this.addStore.current.style.width = "0%";
                        }
                    }
                )
                .catch(
                    error => {
                        console.log(error);
                        if (error.response.status === 404 && error.response.data.storename) {
                            this.setState({
                                errors: { "name": "Given Store Name is already existed!" }
                            })
                            this.props.syncTimeSpinnerOff();
                        }
                        else {
                            this.props.syncTimeSpinnerOff();
                            this.clearStoreData();
                            console.log(error);
                        }
                    }
                )
        }
        $event.preventDefault();
    }
    render() {
        let states =
            [
                "AK",
                "AL",
                "AR",
                "AS",
                "AZ",
                "CA",
                "CO",
                "CT",
                "DC",
                "DE",
                "FL",
                "GA",
                "GU",
                "HI",
                "IA",
                "ID",
                "IL",
                "IN",
                "KS",
                "KY",
                "LA",
                "MA",
                "MD",
                "ME",
                "MI",
                "MN",
                "MO",
                "MS",
                "MT",
                "NC",
                "ND",
                "NE",
                "NH",
                "NJ",
                "NM",
                "NV",
                "NY",
                "OH",
                "OK",
                "OR",
                "PA",
                "PR",
                "RI",
                "SC",
                "SD",
                "TN",
                "TX",
                "UT",
                "VA",
                "VI",
                "VT",
                "WA",
                "WI",
                "WV",
                "WY"
            ]

        return (
            <div id="add-store" className="overlay" ref={this.addStore}>
                <a href="javascript:void(0)" className="closebtn" onClick={this.closeAddstore}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                </a>
                <div className="overlay-content-transet">
                    <div className="setting-model-iframe">
                        <div className="add-store-body-content">
                            <div className="issue-model-heading" style={{ paddingTop: "12px", marginBottom: '12px' }}>
                                <h4>
                                    <span>
                                        <img src="./assets/images/obg-store=blak.png" />
                                    </span> Add New Store
                                </h4>
                            </div>
                            <div className="add-store-form-content">
                                <div className="row">
                                    <div className="col-md-12">
                                        <form onSubmit={this.saveStore}>
                                            <div className="form-group has-search">
                                                <label for="user ID">Store name</label>
                                                <input type="text" className="form-control bridge-input" placeholder="Type here" id="name" name="name" ref={this.nameRef} onChange={this.changeHandler} autoComplete="off" />
                                                {this.state.errors.name && (
                                                    <p style={{ color: "red" }}>{this.state.errors.name}</p>
                                                )}
                                            </div>
                                            <div className="form-group has-search">
                                                <label for="Password">Address</label>
                                                <input type="text" className="form-control bridge-input" placeholder="Type here" id="address" name="address" ref={this.addressRef} onChange={this.changeHandler} autoComplete="off" />
                                                {this.state.errors.address && (
                                                    <p style={{ color: "red" }}>{this.state.errors.address}</p>
                                                )}
                                            </div>
                                            <div className="form-group has-search">
                                                <label for="City">City</label>
                                                <input type="text" className="form-control bridge-input" placeholder="Type here" id="city" name="city" ref={this.cityRef} onChange={this.changeHandler} autoComplete="off" />
                                                {this.state.errors.city && (
                                                    <p style={{ color: "red" }}>{this.state.errors.city}</p>
                                                )}
                                            </div>
                                            <div className="row">
                                                <div className="col-6 dropdown-address has-search">
                                                    <label for="State">State</label>
                                                    <select className="form-control dropdown-list-address stateDropdown" id="state" name="state" ref={this.stateRef} onChange={this.changeHandler}>
                                                        <option value="select">select</option>
                                                        {states.map(state => <option>{state}</option>)}
                                                    </select>
                                                    {this.state.errors.state && (
                                                        <p style={{ color: "red" }}>{this.state.errors.state}</p>
                                                    )}
                                                </div>
                                                <div className="col-6 dropdown-address has-search">
                                                    <label for="Zip">Zip</label>
                                                    <input type="text" className="form-control dropdown-list-address" placeholder="Type here" id="zip" name="zip" ref={this.zipRef} onChange={this.changeHandler} autoComplete="off" />
                                                    {this.state.errors.zip && (
                                                        <p style={{ color: "red" }}>{this.state.errors.zip}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group has-search m-t-15">
                                                <label for="user ID">Email ID (Optional)</label>
                                                <input type="text" className="form-control bridge-input" placeholder="Type here" id="emailID" name="emailID" ref={this.emailIDRef} onChange={this.changeHandler} autoComplete="off" />
                                                {this.state.errors.emailID && (
                                                    <p style={{ color: "red" }}>{this.state.errors.emailID}</p>
                                                )}
                                            </div>
                                            <div className="form-group has-search">
                                                <label for="Password">Phone number (Optional)</label>
                                                <input type="text" className="form-control bridge-input" placeholder="Type here" id="phoneNumber" name="phoneNumber" ref={this.phoneNumberRef} onChange={this.changeHandler} autoComplete="off" />
                                            </div>
                                            <button className="btn btn-primary submit-button" type="submit" role="button" style={{ marginTop: "18px", marginRight: "5px" }}>Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default AddStore;
