import React from 'react';

function Address(props) {
    return (
        <div className="modalheaddata">
            <div>WELCOME TO OUR STORE</div>
            <div>{props.line1}</div>
            <div>{props.line2}</div>
            <div>{props.line3}</div>
        </div>
    )
}
export default Address;