import React, { Component } from 'react';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import {NotificationManager} from 'react-notifications';
import createNotification from '../services/AlertService';

class StoreIssues extends Component {
    constructor() {
        super()
        this.showStorePhoneNUmber = this.showStorePhoneNUmber.bind(this);
        this.showStoreGmail = this.showStoreGmail.bind(this);
    }
    closeStoreissue() {
        $(".store-issue-button").removeClass('selected');
        document.getElementById("myIssue").style.width = "0%";
    }
    showStoreGmail(storeEmail) {
        createNotification('info',storeEmail);
        //alert(storeEmail);

    }
    showStorePhoneNUmber(storePhoneNumber) {
        createNotification('info',storePhoneNumber);
        //alert(storePhoneNumber);
    }
    formatPhoneNumber(number){
        var arr=number.split('');
        var res='+1 ('+arr[0]+arr[1]+arr[2]+') '+arr[3]+arr[4]+arr[5]+'-'+arr[6]+arr[7]+arr[8]+arr[9];
        return res;
      }
    render() {
        const statusStores = this.props.storesList !== undefined && this.props.storesList.length > 0
            && this.props.storesList.map((store, i) => {
                if (store.commanderStatus === "Disconnected" || store.bridgeStatus === "Disconnected") {
                    return (
                        <tr className={(i % 2 != 0) ? "table-row-bg" : ""}>
                            <td>{store.name}</td>
                            <td className="storeIssuesTd">
                                {store.commanderStatus === "Disconnected" ? <i class=" text-center negative-incation fa fa-minus"></i>
                                    :
                                    <i class=" text-center positive-incation fa fa-minus"></i>
                                }
                            </td>
                            <td className="storeIssuesTd">
                                {store.bridgeStatus === "Disconnected" ? <i class=" text-center negative-incation fa fa-minus"></i>
                                    :
                                    <i class=" text-center positive-incation fa fa-minus"></i>
                                }
                            </td>
                            <td className="storeIssuesTd">
                                {store.emailID !== null ? <a style={{ display: "inline-table" }} className="fa fa-envelope store-contact-icon m-r-10"
                                    href={`mailto:${store.emailID}`}></a>
                                    :
                                    <i className="fa fa-envelope nostore-contact-icon m-r-10"></i>
                                }
                                {store.phoneNumber !== null ? <i className="fa fa-phone store-contact-icon " data-for="tooltipForStoreIssue" data-tip={this.formatPhoneNumber(store.phoneNumber)}></i>
                                    :
                                    <i className="fa fa-phone nostore-contact-icon hoverclass"></i>
                                }
                                <ReactTooltip type="info" class="formatphoneTip" id="tooltipForStoreIssue"/>
                            </td>
                        </tr>
                    )
                }
            }
            )
        return (
            <div id="myIssue" className="overlay">
                <a href="javascript:void(0)" className="closebtn" onClick={this.closeStoreissue}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                </a>
                <div className="overlay-content-store-issue">
                    <div className="store-issue-model-iframe">
                        <div class="container-fluid">
                            <div class="issue-model-heading">
                                <h4><span><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span> Stores with issues</h4>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="Table-card">
                                        <div class="table-content">
                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr class="table-row-top-border">
                                                            <th scope="col">Store</th>
                                                            <th scope="col" className="storeIssuesTd">Commander</th>
                                                            <th scope="col" className="storeIssuesTd">Bridge</th>
                                                            <th scope="col" className="storeIssuesTd">Contact</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody class="table-body-content-inner">
                                                        {statusStores}
                                                        {/* <tr>
                                                        <td>Romana Store</td>
                                                        <td class="text-center positive-incation"><i class="fa fa-minus"></i></td>
                                                        <td class="text-center negative-incation"><i class="fa fa-minus"></i></td>
                                                        <td class="contact-icon"><i class="fa fa-envelope m-r-10"></i>
                                                            <i class="fa fa-phone"></i></td>
                                                    </tr>
                                                    <tr class="table-row-bg">
                                                        <td>Ronachimana KL Store</td>
                                                        <td class="text-center positive-incation"><i class="fa fa-minus"></i></td>
                                                        <td class="text-center negative-incation"><i class="fa fa-minus"></i></td>
                                                        <td class="contact-icon"><i class="fa fa-envelope m-r-10"></i>
                                                            <i class="fa fa-phone"></i></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kolamapola Store</td>
                                                        <td class="text-center negative-incation"><i class="fa fa-minus"></i></td>
                                                        <td class="text-center positive-incation"><i class="fa fa-minus"></i></td>
                                                        <td class="contact-icon"><i class="fa fa-envelope m-r-10"></i>
                                                            <i class="fa fa-phone"></i></td>
                                                    </tr>
                                                    <tr class="table-row-bg">
                                                        <td>Dhramajak isa store</td>
                                                        <td class="text-center negative-incation"><i class="fa fa-minus"></i></td>
                                                        <td class="text-center positive-incation"><i class="fa fa-minus"></i></td>
                                                        <td class="contact-icon"><i class="fa fa-envelope m-r-10"></i>
                                                            <i class="fa fa-phone"></i></td>
                                                    </tr>
                                                    <tr> 
                                                        <td>Rumanikila Store</td>
                                                        <td class="text-center negative-incation"><i class="fa fa-minus"></i></td>
                                                        <td class="text-center positive-incation"><i class="fa fa-minus"></i></td>
                                                        <td class="contact-icon"><i class="fa fa-envelope m-r-10"></i>
                                                            <i class="fa fa-phone"></i></td>
                                                    </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default StoreIssues;