import React, { Component } from 'react';
import SetSyncTime from './setsynctime';
import moment from 'moment';
import { tsImportEqualsDeclaration } from '@babel/types';
import _ from 'lodash';
import $ from 'jquery';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { poolData } from '../urlandheaderdata';

class HeaderNavSection extends Component {
  constructor(props) {
    super(props);
    this.syncTimeChange = this.syncTimeChange.bind(this);
    this.syncTimeSpinnerOn = this.syncTimeSpinnerOn.bind(this);
    this.syncTimeSpinnerOff = this.syncTimeSpinnerOff.bind(this);
    this.openCloseSyncDiv = this.openCloseSyncDiv.bind(this);
    this.hideSyncTimeComponent = this.hideSyncTimeComponent.bind(this);
    this.state = {
      syncTime: ""
    }
    this.user = {}
  }
  componentDidMount() {
    var that = this;
    $(document).mouseover(function (e) {
      if (!($(e.target).hasClass("hoverclass"))) {
        that.hideSyncTimeComponent();
        //$(".set-time-header-button").hide();
      }
    });

    var userPool = new CognitoUserPool(poolData);
    var currentUser = userPool.getCurrentUser();
    if (currentUser !== null) {
      currentUser.getSession(function (err, session) {
        if (err) {
          console.log(err);
          return;
        }
        //console.log('session validity: ' + session.isValid());
      });
      var that = this;
      currentUser.getUserAttributes(function (err, result) {
        if (err) {
          console.log(err);
          return;
        }
        if (result) {
          that.user.firstName = result.find(x => x.Name == "name").getValue();
          that.user.lastName = result.find(x => x.Name == "family_name").getValue();
        }
      });
    }


    //this.user = JSON.parse(localStorage.getItem('userInfo'));
  }

  syncTimeChange(store) {
    this.props.syncTimeChange(store);
  }
  syncTimeSpinnerOn() {
    this.props.syncTimeSpinnerOn();
  }
  syncTimeSpinnerOff() {
    this.props.syncTimeSpinnerOff();
  }
  openStoreissue() {
    $(".store-issue-button").addClass('selected');
    document.getElementById("myIssue").style.width = "100%";
  }
  openSetting() {
    document.getElementById("mySetting").style.width = "100%";
  }
  openCloseSyncDiv(syncTime) {
    console.log("hi...");
    console.log(syncTime);

    // $(".header-set-time-btn").toggleClass('is-active').next(".set-time-header-button").stop().slideToggle(100);
    $(".header-set-time-btn").toggleClass('is-active').next(".set-time-header-button").stop().slideToggle(100, () => {
      this.setState({
        syncTime: syncTime
      })
    });

  }
  hideSyncTimeComponent(syncTime) {
    $(".header-set-time-btn").removeClass('is-active');
    $(".header-set-time-btn").next(".set-time-header-button").stop().hide();
    this.setState({
      syncTime: syncTime
    })
  }
  render() {
    let isSyncTimeUpdate = true;

    let syncTime = "";
    let store = _.first(_.filter(this.props.storesList, (store) => store.storeID === this.props.storeID));
    if (store) {
      var currentdate = moment().format('YYYY/MM/DD');
      var Time = store.scheduletimeHours + ":" + store.scheduletimeMinutes + ":" + "00";
      syncTime = moment.utc(currentdate + " " + Time).local().format('hh:mm A');
      if ($("#syncTimeDivFromHeaderNav").is(":visible")) {
        isSyncTimeUpdate = false;
      }
    }
    if (this.props.storesList !== undefined && this.props.storesList.length > 0) {
      var storeStatusCount = 0;
      this.props.storesList.forEach(item => {
        if (item.commanderStatus === "Disconnected" || item.bridgeStatus === "Disconnected") {
          storeStatusCount += 1;
        }
      });
    }
    return (
      <div className="header-nav-section hoverclass">
        <div className="header-nav-section-child hoverclass">
          <div className="Curve-design hoverclass">
            <img className="hoverclass" src="assets/images/obj-design.png" alt="" />
            <h6 style={{ fontSize: "0.92rem" }} id="headerBussinessName">{this.props.storeName}</h6>
            {/* <span className="brand-logo-curved hoverclass" style={{color: "white",fontWeight: "bold", fontSize:"large"}}>{this.props.storeName}</span> */}
            {/* <img className="brand-logo-curved hoverclass" src="assets/images/obj-logo.png" alt="User picture" /> */}
          </div>
          <div className="store-set-time-address hoverclass">
            {/* <h6><img className="store-icon" src="assets/images/obg-store=blak.png"/>
                          <span><b>Hamkingston Store</b></span></h6> */}

            <div className="dropdown time-button-section hoverclass">
              <a className="header-set-time-btn hoverclass" onClick={() => this.openCloseSyncDiv(syncTime)}>
                <span className="hoverclass"><i className="fa fa-clock-o hoverclass" aria-hidden="true"></i></span>
                <span className="sync-date hoverclass"> {syncTime}</span>
              </a>
              {/* setsynctime Component   */}
              <div className="set-time-header-button is-hidden hoverclass">
                <div className="set-synk-time-section top-header-time-set hoverclass" id="syncTimeDivFromHeaderNav">
                  <SetSyncTime syncTimeChange={this.syncTimeChange} userID={this.props.userID} storeID={this.props.storeID} syncTime={syncTime} syncTimeSpinnerOn={this.syncTimeSpinnerOn} syncTimeSpinnerOff={this.syncTimeSpinnerOff} hideSyncTimeComponent={this.hideSyncTimeComponent} isSyncTimeUpdate={isSyncTimeUpdate} />
                </div>
              </div>
            </div>
            <div className="header-store-address hoverclass">
              <p id="ellipsis">
                <span><i className="fa fa-map-marker hoverclass" aria-hidden="true"></i></span>&nbsp;
                {store !== undefined && store.name + "-"}{this.props.storeAddress}
              </p>
            </div>
          </div>
          <div className="navbar-menu-items float-right hoverclass">
            <ul className="nav mobile-nav-hidden hoverclass">
              {storeStatusCount !== undefined && storeStatusCount > 0 ?
                <li className="nav-item store-issue-button store-issue-button-style">
                  <a className="nav-link" onClick={this.openStoreissue}>Store issues<span className="Store-issues-count">{storeStatusCount}</span>
                  </a>
                </li>
                :
                <li></li>
              }
              <li className="nav-item right-border hoverclass">
                <a className="nav-link"> <i className="fa fa-user m-r-10 profile-icon"></i>
                  {this.user && this.user.firstName && this.user.lastName ? this.user.firstName + " " + this.user.lastName : ""}
                  {/* User */}
                </a>
              </li>
              <li className="nav-item right-border f-s-15 p-t-2">
                <a className="nav-link" onClick={this.openSetting}> <i className="fa fa-cog"></i>
                </a> </li>
              <li className="nav-item f-s-15 p-t-2">
                <a href="javascript:void(0)" onClick={this.props.logOut} className="nav-link"> <i className="fa fa-power-off"></i>
                </a> </li>
              {/* <!-- <li className="nav-item dropdown nav--user online">
                            <a className="nav-link" data-toggle="dropdown" aria-expanded="false">
                              <img src="assets/images/user-pic.png" alt="" className="rounded-circle"/>
                              <span className="dropdown-menu-title">Suraj kumar</span> <i className="fa fa-angle-down"></i>
                            </a>                
                          </li> --> */}
            </ul>
            <div className="dropdown float-right m-r-10">
              <i className="fa fa-bars mobile-toggle-dropdown" id="menu1" data-toggle="dropdown"></i>
              <ul className="dropdown-menu mobile-dropdown-list" role="menu" aria-labelledby="menu1">
                {storeStatusCount !== undefined && storeStatusCount > 0 &&
                  <li href="javascript:void(0)"><a className="nav-link" onClick={this.openStoreissue}>Store issues<span
                    className="Store-issues-count">{storeStatusCount}</span>
                  </a></li>}
                <li href="javascript:void(0)"><a className="nav-link"> <i className="fa fa-user m-r-10 profile-icon"></i>
                  {this.user && this.user.firstName && this.user.lastName ? this.user.firstName + " " + this.user.lastName : ""}
                </a></li>
                <li href="javascript:void(0)"><a className="nav-link" onClick={this.openSetting}> <i className="fa fa-cog m-r-10"></i>Setting
                              </a> </li>
                <li href="javascript:void(0)"><a href="javascript:void(0)" onClick={this.props.logOut} className="nav-link"> <i className="fa fa-power-off m-r-10"></i>Logout
                              </a> </li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
export default HeaderNavSection;